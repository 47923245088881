// Lodash/fp
import get from "lodash/fp/get";

// Project
import { APPOINTMENT_ENDPOINT } from "../../Constants";

/**
 * Create an appointment
 * @param {*} appointment 
 */
export const createAppointment = async (appointment, token) => {
    // MK.  todo: Add error handling
    // MK.  todo: Manage slot conflict in case the slot is double booked   
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/create-appointment`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not create appointments for ${get("user_uuid")(appointment)}`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}


/**
 * Create an appointment based on appointment request
 * @param {*} appointment 
 */
export const createAppointmentByRequest = async (appointment, token) => {
    // MK.  todo: Add error handling
    // MK.  todo: Manage slot conflict in case the slot is double booked   
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/create-appointment-by-request`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not create appointments for ${get("user_uuid")(appointment)}`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}


/**
 * Get free slots
 * @param {uuid} search_user_uuid 
 * @param {{ search_window, slot_duration}} options 
 */
export const getFreeAppointmentSlots = async (search_user_uuid, options = {}, token) => {

    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/get-appointment-slots`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                search_user_uuid,
                ...options,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not get appointments for ${search_user_uuid}`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Get user daily schedule
 * @param {uuid} search_user_uuid 
 * @param {tstzrange} search_window 
 */
export const getUserDailySchedule = async (search_user_uuid, search_window, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/get-user-schedule`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                search_user_uuid,
                search_window,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not get schedule for ${search_user_uuid}`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Get user schedule
 * @param {uuid} search_user_uuid 
 */
export const getUserSchedule = async (search_user_uuid, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/get-schedule`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                search_user_uuid,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not get weekly schedule for ${search_user_uuid}`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}


/**
 * Create an appointment
 * @param {*} appointment_request
 */
export const createAppointmentRequest = async (appointment_request, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/create-appointment-request`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment_request)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not create appointment request`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Fetch Appointment Requests filtered on speciality
 * @param {string} speciality
 */
export const getAppointmentRequests = async (speciality, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/get-appointment-requests`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                speciality,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not get appointment requests`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}
/**
 * Create an appointment reply
 * @param {*} appointment_reply
 */
export const createAppointmentReply = async (appointment_reply, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/create-appointment-reply`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment_reply)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not create appointment reply`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Delete appointment advertisements
 * @param {*} appointment_advertisement
 */
export const deleteAppointmentAdvertisements = async (appointment_advertisement, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/delete-appointment-advertisements`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment_advertisement)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not delete appointment advertisements`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Accept appointment advertisements by Provider
 * @param {*} appointment_advertisement
 */
export const acceptAppointmentAdvertisementByProvider = async (appointment_advertisement, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/accept-appointment-by-provider`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment_advertisement)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not accept appointment advertisements by provider`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Accept appointment advertisements by Patient
 * @param {*} appointment_advertisement
 */
export const acceptAppointmentAdvertisementByPatient = async (appointment_advertisement, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/accept-appointment-advertisement-by-patient`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment_advertisement)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not accept appointment advertisements by patient`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Decline appointment advertisements by Patient
 * @param {*} appointment_advertisement
 */
export const declineAppointmentAdvertisementByPatient = async (appointment_advertisement, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/decline-appointment-advertisement-by-patient`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment_advertisement)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not decline appointment advertisements by patient`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Accept appointment reply by Patient
 * @param {*} appointment_advertisement
 */
export const acceptAppointmentReplyByPatient = async (appointment_advertisement, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/accept-appointment-reply-by-patient`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment_advertisement)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not accept appointment reply by patient`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Decline appointment reply by Patient
 * @param {*} appointment_advertisement
 */
export const declineAppointmentReplyByPatient = async (appointment_advertisement, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/decline-appointment-reply-by-patient`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(appointment_advertisement)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not decline appointment reply by patient`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

/**
 * Fetch Appointment Replies filtered on request_uuid
 * @param {string} request_uuid
 */
export const getAppointmentReplies = async (request_uuid, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/get-appointment-replies`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                request_uuid,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not get appointment requests`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}
/**
 * Get free slots by state
 * @param {string} state 
 * @param {{ insured_only, search_window, slot_duration}} options 
 */
export const getFreeAppointmentSlotsByState = async (state, options={}, token) => {
   
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/get-appointments-by-state`,
        {
             method: "POST",
             headers: {
                 "Content-Type": "application/json",
                 "Authorization": token ? `Bearer ${token}` : "",
             },
             body: JSON.stringify({
                 state,
                 ...options,
             })
        }
    );
 
    const body = await response.json();
 
    if (get("status")(body) != 200) {
        console.log(`Could not get appointments for state ${state}`);
        console.log(get("message")(body));
    }
 
    const data = get("data")(body);
 
    return data;
 }

export const confirmAppointment = async ({appointment_uuid, given_provider_uuid="", given_start_time="", given_clinic_uuid="", token, given_network_client_uuid}) => { 
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/confirm-appointment`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                appointment_uuid,
                given_provider_uuid,
                given_start_time,
                given_clinic_uuid,
                given_network_client_uuid,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not confirm appointments against appointment_uuid: ${appointment_uuid}`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

export const getAppointmentRequestByUUID = async (uuid, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/get-appointment-request-by-uuid`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                uuid,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not get appointment requests`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}


export const appointmentRequestDirectReply = async (uuid, reply, isBusiness, token) => {
    const response = await fetch(
        `${APPOINTMENT_ENDPOINT}/appointment-request-direct-reply`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                uuid,
                reply,
                isBusiness,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not send reply to appointment request`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}
export default {
    createAppointment,
    createAppointmentByRequest,
    getFreeAppointmentSlots,
    getUserDailySchedule,
    getUserSchedule,
    getFreeAppointmentSlotsByState,
    createAppointmentRequest,
    getAppointmentRequests,
    createAppointmentReply,
    deleteAppointmentAdvertisements,
    acceptAppointmentAdvertisementByProvider,
    acceptAppointmentAdvertisementByPatient,
    declineAppointmentAdvertisementByPatient,
    acceptAppointmentReplyByPatient,
    declineAppointmentReplyByPatient,
    getAppointmentReplies,
    confirmAppointment,
    getAppointmentRequestByUUID,
    appointmentRequestDirectReply
}