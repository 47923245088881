import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Block from "./Block";

// Lodash/fp
import isUndefined from "lodash/fp/isUndefined";
// Lodash
import _map from "lodash/map";
import _slice from "lodash/slice";
export const tos = [
  'Terms of Use for Users',
  'The following terms and conditions and any addendum (the “Agreement”) govern the use of the AMK Technologies of Ohio, LLC’s (“AMK Technologies”) dba OneClinic platform which enables telemedicine services and associated features (“Service”) including, without limitation, those found on the websites “www.amktechnologies.net” or subdomains  such as “www.clinic.amktechnologies.net”  (the “Site”) or applications embeded in AMK Technologies’ clients websites or mobile/desktop applications or affiliate websites (taken together, the “Service”).  User consents to these Terms by their indication below.',
  'PLATFORM SERVICES:  The Services (“Service’) offered by AMK Technologies through this platform are subject to your acceptance without modification of all of the terms and conditions contained herein and all other addendum, operating rules, policies and procedures that may be published from time to time by AMK Technologies. BY USING OR ACCESSING ANY PART OF THE SERVICE, YOU CONFIRM THAT YOU HAVE FULLY READ, UNDERSTOOD AND AGREED TO ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN; IF YOU DO NOT AGREE, DO NOT USE OR ACCESS THE SERVICE.',
  'DEFINITIONS:  As used in this Agreement “user”, “you” or “your” refers to individual potentially or actually seeking medical services by licensed medical Providers in the state in which medical services are authorized.   ',
  '“AMK Technologies” refers to AMK Technologies of Ohio, LLC (107 South Main Street, Suite 301, Mount Vernon, Ohio 43050) and its affiliates and subsidiaries. ',
  'REGISTRATION:  When you register you must be of legal age and provide correct information.  It is your obligation to protect your password.   ',
  'FEES: You agree to pay the fees to your medical provider in full before the visit.  No insurance company will be charged using this platform.',
  'NOT MEDICAL ADVICE:  The platform is not medical advice or treatment. Any information on the website amktechnologies.net and subdomains shall NOT be considered advice or treatment.  Only specific advice or treatment that you receive from the health care provider through telemedicine shall be considered authorized advice or treatment. AMK Technologies only provides the platform for the telemedicine technology.  The health care services are provided by independent health care providers who utilize the AMK Technologies platform.  Each health care provider is responsible for their scope of work and the professional advice that they provide through this platform. AMK Technologies only provides the platform that enables you to have access for services through the platform.',
  'Deactivation: You may deactivate your account anytime you want by emailing at accounts@amktechnologies.net.',
  'Limited Rights to Access: You have limited, non-exclusive rights to access the services to use it for yourself or any minor of whom you are a parent or a legal guardian for personal non-commercial use.  ',
  'Prohibitions:',
  'At all times, you shall not: ',
  '- impersonate anyone',
  '- misrepresent affiliation or relation with a person or entity.',
  '- violate any state or federal national or international law with the use of this website.',
  '- reverse engineer or translate any software or piece of the website.',
  '- distribute malicious virus or harmful code through the website.',
  '- use inappropriate language with the health care provider.',
  '- use a public computer to access the website.',
  'Reservation to change Terms: Amk Technologies reserves the right, at its sole discretion, to modify or replace any of the terms or conditions of this Agreement at any time by publishing the updated Agreement on the Site or via the Service (“Modification”). Upon Modification, the updated Agreement shall immediately supersede the original Agreement and automatically take effect. If you disagree with any relevant amendment, you should immediately stop using the Service and cancel your  account, if you have one. Your continued use of the Service following the Modification constitutes acceptance of those changes.',
  'PLATFORM FOR SERVICES ONLY:  AMK Technologies only provides the platform that enables you to have access for services through the platform.  The health care services are provided by independent health care providers who utilize the AMK Technologies platform.  Each health care provider is responsible for their scope of work and the professional advice that they provide through this platform. ',
  'DISCLAIMER ON MEDICAL SERVICES:  All health care providers that provide services thorough this website are licensed in the state/s in which they are providing services.  You must use only the providers in your state.  The medical providers have medical degrees and are licensed by medical boards of the states in which they provide services.  Health care providers on their own maintain their appropriate licenses and comply with state and federal law governing the practice of medicine with their scope of practice.  Sexual intimacy with the health care providers is not appropriate and should be reported to the appropriate board or agency that licenses the provider.  AMK Technology shall not be liable for misconduct or anything wrong done on by health care provider.',
  'PROTECTED MEDICAL INFORMATION: AMK Technologies does not request, obtain, collect, or store your medical information other than whether or not a payment has been received. The receipt of payment information is stored but is not identifiable to particular treatment, care, services or treatment.  To the extent necessary to be able to verify payment information, you agreed to the AMK Technologies maintaining records of payment information.  Patient visit notes are stored by health care providers in their electronic medical records.  AMK Technologies uses third party processors (such as Square, Gpay, or ApplePay) to process the payments on behalf of the medical provider.  This information is not stored.',
  'REGISTRATION:  You may use the service without registration. You hereby acknowledge that when you complete the registration procedures or otherwise use the Service in such a manner as permitted by AMK Technologies, you represent and warrant that you meet all the requirements under this Agreement and are in full compliance with applicable laws. Please keep your account and password information private. You undertake to notify AMK Technologies at (www.amktechnologies.net) immediately if your password or account is used without authorization or in case of any other security incident, and except for otherwise stipulated by laws, you agree and acknowledge that AMK Technologies will not be liable for any direct or indirect loss or damages caused under such circumstance. Unless allowed under applicable laws or judicial rulings and expressly approved by AMK Technologies, your account name and password may not be assigned, bestowed or inherited in any manner. You agree to immediately notify AMK Technologies of any unauthorized use of your password or account or any other breach of security. You are responsible for (and we will hold you responsible for) any activities that occur under your account.',
  'DISCONTINUATION OF SERVICE/TERMINATION:  AMK Technologies may change, suspend or discontinue any aspect of the Service at any time. AMK Technologies may also impose limits on certain features and services or restrict User’s access to parts or all of the Service without notice. You agree that AMK Technologies will not be liable to you or any third-party as a result of its termination of your access to the Service. Once your account is terminated, you acknowledge and agree that we may permanently delete your account and all the data associated with it. ',
  'Further, AMK Technologies may terminate User’s access to all or any part of the Service at any time, with or without cause, effective upon notice thereof to User. AMK Technologies may immediately suspend the Service in the event User breaches this Agreement or otherwise takes an action that AMK Technologies determines (in its discretion) may harm AMK Technologies or the Service. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, limitations of liability and dispute resolution provisions.',
  'You may terminate your access to the Service by contacting us through https://oneclinic.io to delete your account. If you are a User using the Service without a registered account and you want to terminate this agreement, you should stop accessing the Service, and this Agreement will no longer apply to you. ',
  'REPRESENTATIONS OF USER. You represent and warrant that if you are an individual, you are at least 18 years old. You are responsible for obtaining and maintaining any equipment or ancillary services needed to connect to and access the Service, including, without limitation, modems, hardware, software, and long distance or local telephone service. You shall be responsible for ensuring that such equipment or ancillary services are compatible with the Service.',
  'Except as otherwise specified in this Agreement, User agrees that all content and materials delivered via the Service by AMK Technologies as part of the Service on the Site or elsewhere (collectively, “Content”) may be protected by copyrights, trademarks, service marks, patents, trade secrets or other proprietary rights and laws. Except as expressly authorized by the AMK Technologies in writing, You agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works from such platform, materials or content whether expressly protected or not. ',
  'Use of Content for any purpose not expressly permitted in this Agreement is prohibited. Any rights not expressly granted herein are reserved. ',
  'RESTRICTIONS ON USE OF SERVICE PLATFORM.',
  'You shall not submit any information to AMK Technologies that is false, misleading or inaccurate.',
  'User shall not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Service or any Content, or in any way reproduce or circumvent the navigational structure, security controls or presentation of the Service or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Service. AMK Technologies reserves the right to bar any such activity.',
  'You shall not attempt to modify, translate, reverse engineer, decompile, disassemble, create derivative works based on, sublicense or distribute any software program or applications providing the Service. ',
  'You shall not attempt to gain unauthorized access to any portion or feature of the Service, or any other systems or networks connected to the Service or to any AMK Technology server, or to any of the services offered on or through the Service, by hacking, password “mining”, or any other illegitimate means.',
  'You shall not probe, scan or test the vulnerability of the Service or any network connected to the Service, nor breach the security or authentication measures on the Service or any network connected to the Service. User shall not reverse look-up, trace or seek to trace any information of any other user or visitor to the Service, or any other client of AMK Technologies, not owned by You, to its source, or exploit the Service or any service or information made available or offered by or through the Service, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than User’s own information, as provided for by the Service.',
  'You shall not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Service or AMK Technologies systems or networks, or any systems or networks connected to the Service or to AMK Technologies.',
  'You shall not use any device, software or routine to interfere or attempt to interfere with the proper working of the Service or any transaction being conducted on the Service, or with any other person’s use of the Service.',
  'You shall not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal User sends to AMK Technologies on or through the Service. You shall not, in connection with the Service, pretend (e.g., through impersonation) that User is any other individual or entity.',
  'You shall not use the Service for any purpose that is unlawful or prohibited by this Agreement, or which infringes the rights of AMK Technologies or the medical providers.  ',
  'ACCESS RIGHTS:   You have limited, non-exclusive rights to access the website to use it for yourself or any minor of whom you are a parent or a legal guardian for personal use.  ',
  '-Do not impersonate anyone',
  '-Do not misrepresent affiliation or relation with a person or entity.',
  '-Do not violate any state or federal national or international law with the use of this website.',
  '-Do not reverse engineer or translate any software or piece of the website.',
  '-Do not distribute malicious virus or harmful code through the website.',
  '-Use appropriate language with the health care provider.',
  '- We recommend that you do not use a public computer to access the website.',
  'THIRD PARTY SITES:  The Service may permit Users to link to other websites or resources on the Internet, and other websites or resources may contain links to the Site or the Service. These other websites are not under AMK Technologies control, and User acknowledges that AMK Technologies is not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources. The inclusion of any such link does not imply endorsement by AMK Technologies. User further acknowledge and agrees that AMK Technologies shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods, information, or services available on or through any such website or resource. AMK Technologies may delete any link using its reasonable discretion. ',
  'INDEMNIFICATION: User is responsible for all of its activity in connection with the Service. User shall defend, indemnify, and hold harmless AMK Technologies, its affiliates and subsidiaries, and each of its employees, contractors, directors, suppliers and representatives from all liabilities, claims, expenses, and damages (whether direct, indirect, incidental, consequential or otherwise), including reasonable attorneys’ fees, that arise from or in connection with (i) your use or misuse of the Service, (ii) your access to any part of the Service, (iii) your Content and/or links, (iv) your dispute(s) with any third party(ies), (v) your violation of this Agreement, or (vi) your violation of any applicable law.',
  'DISCLAIMER.  LIMITATION OF LIABILITY.',
  'YOU AGREE THAT AMK TECHNOLOGIES IS NOT PROVIDING MEDICAL ADVICE AND WILL BRING NO ACTION AGAINST AMK TECHNOLOGIES FOR MEDICAL MAL PRACTICE.',
  'Disclaimer of Warranties. THE SERVICE IS PROVIDED ON AN “AS IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. AMK Technologies MAKES NO WARRANTY THAT (I) THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (II) THE RESULTS OF USING THE SERVICE WILL MEET USER’S REQUIREMENTS. In the event that some jurisdictions do not allow the disclaimer of implied warranties, the foregoing disclaimers may not apply to you insofar as they relate to implied warranties.',
  'Limitations of Liabilities. IN NO EVENT SHALL AMK Technologies, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PAYMENT PROCESSING PARTNERS, VENDORS OR SUPPLIERS BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL THEORY WITH RESPECT TO THE SERVICE (OR ANY CONTENT OR INFORMATION AVAILABLE THROUGH THE SERVICE): (I) ANY FAILURE TO PERFORM ITS OBLIGATIONS HEREUNDER WHERE SUCH FAILURE RESULTS FROM ANY CAUSE BEYOND AMK TECHNOLOGIES’ REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION, MECHANICAL, ELECTRONIC OR COMMUNICATIONS FAILURE OR DEGRADATION (INCLUDING "LINE-NOISE" INTERFERENCE), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), (III) FOR ANY ERRORS OR OMISSIONS IN ANY CONTENT OR INFORMATION OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF USER’S USE OF ANY CONTENT OR INFORMATION POSTED, EMAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE AT OR THROUGH THE SERVICE, OR (IV) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) $500.00 (U.S.) what does this mean?.',
  'IN NO EVENT WILL AMK TECHNOLOGIES’ AGGREGATE LIABILITY (INCLUDING, BUT NOT LIMITED TO, LIABILITY FOR NEGLIGENCE, STRICT LIABILITY, BREACH OF CONTRACT, MISREPRESENTATION AND OTHER CONTRACT OR TORT CLAIMS) ARISING FROM OR RELATED TO THIS AGREEMENT, EXCEED THE AMOUNT OF FEES RECEIVED BY AMK TECHNOLOGIES FEES FOR SERVICES FOR PROVIDING THE PLATFORM TO  THE SERVICE PROVIDER ASSOCIATED WITH THE USER OF THIS AGREEMENT DURING THE 12 MONTHS PRECEDING THE DATE ON WHICH SUCH LIABILITY AROSE, LESS AGGREGATE DAMAGES PREVIOUSLY PAID BY USER UNDER THIS AGREEMENT. NO ACTION, REGARDLESS OF FORM, ARISING OUT OF ANY ACTS OR OMISSIONS RELATING TO THIS AGREEMENT MAY BE BROUGHT BY USER MORE THAN ONE YEAR AFTER THE OCCURRENCE OF SUCH ACT OR OMISSION.',
  'NO INDIRECT DAMAGES. IN NO EVENT WILL AMK TECHNOLOGIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL, DAMAGES (INCLUDING WITHOUT LIMITATION, LIABILITIES RELATED TO A LOSS OF USE, PROFITS, GOODWILL OR SAVINGS OR A LOSS OR DAMAGE TO ANY SYSTEMS, RECORDS OR DATA, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED IN ADVANCE OR AWARE OF THE POSSIBILITY OF ANY SUCH LOSS OR DAMAGE.',
  'THE FOREGOING LIMITATIONS SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.',
  'DATA AND PRIVACY: ',
  'AMK Technologies takes a variety of reasonable physical, electronic and administrative measures to protect your information and prevent your information and communication contents stored during the use of Service from being divulged, damaged or lost.',
  'Please keep your account and password information confidential and protected. AMK Technologies will not be held liable for lost or stolen data or for deletion or storage failure of relevant data on the Service for reasons that are attributable to you.',
  'AMK Technologies may use your data after anonymization for internal business purposes, including without limitation, to help us improve the content and functionality of the Service, to better understand our Users, to improve the Service, to protect against, identify or address wrongdoing, to enforce our Terms of Use, to provide you with customer service, and to generally manage the Service.',
  'AMK Technologies may use your data to contact you in the future for our marketing and advertising purposes, including without limitation, to inform you about services or events AMK Technologies believe might be of interest to you, to develop promotional or marketing materials and provide those materials to you, and to display content and advertising on or off the Service that AMK Technologies believe might be of interest to you. ',
  'In an ongoing effort to understand and serve our Users better, AMK Technologies often conducts research on User demographics, interests and behavior based on data that AMK Technology has collected.  THIS DATA DOES NOT INCLUDE PERSONAL HEALTH INFORMATION. This research is typically conducted on an aggregate basis only that does not identify you. AMK Technologies may share aggregated non-identifiable data with third parties for a variety of reasons, including, without limitation, to improve the Service and identify User needs. ',
  'Please refer to the AMK Technologies’ Privacy Policy for more detail on privacy terms and AMK Technologies’ use of data. You are obligated to comply with all applicable laws and regulations with respect to privacy related issues in your use of the Service.',
  'The User represents and warrants User’s use of the Service will comply with all applicable laws and regulations (including with respect to privacy, cyber security and data protection).',
  'MISCELLANEOUS:  No Waiver.  The failure of either User or AMK Technologies to exercise in any respect any of their rights provided herein shall not be deemed a waiver of those rights or any other rights hereunder. ',
  'SEVERABILITY: If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and enforceable. ',
  'ASSIGNMENT: AMK Technologies may transfer, assign or delegate this Agreement and its rights and obligations hereunder without consent.',
  'GOVERNING LAW:  THIS Agreement shall be governed by and construed in accordance with the laws of the state of Ohio.   Jurisdiction of disputes shall be Knox County, Ohio or the Southern District Court for Ohio.',
  'ENTIRE AGREEMENT:  This Agreement shall constitute the entire agreement between you and AMK Technologies and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement. ',
  'NO AGENCY: No agency, partnership, joint venture, or employment relationship is created as a result of this Agreement and User does not have any authority of any kind to bind AMK Technologies in any respect whatsoever.',
  'NOTICES:  We appreciate your feedback and suggestions. If you are using the Service through www.amktechnologies.net , you may contact us via email at info@amktechnologies.net , or by mail at the following address:',
  'AMK Technologies of Ohio, LLC', 
  'Address: 107 South Main Street, Suite 301, Mount Vernon, Ohio 43050',  
]
export default ( { height, onAccept, initialValue, showAccept=true }) => {
  const [checked, setChecked] = useState(initialValue || false);

  const handleChangedCheckbox = () => {
    if (!checked) {
      setChecked(true);
      onAccept(true, tos);
    }
    else {
      setChecked(false);
      onAccept(false, tos);
    }
  }
  const styles = {
    text: isUndefined(height) ? { padding: '0 2em' } : {
      overflow: "auto",
      maxHeight: height,
      padding: "0 4em 0 0",
    },
  }

    return (

        <React.Fragment>

        <Block size="h2" />
        
        <Typography variant="h5" style={{textAlign: 'center'}}>
          {tos[0]}
        </Typography>

        <Block size="h2" />

        <div style={ styles.text }>
          { _map(_slice(tos, 1, tos.length), (item, index) => {
            return (
              <div key={index}>
                <Typography>
                  {item}
                </Typography>
                <Block size="h1"/>
              </div>
            );
          })

          }
        </div>

        <Block size="h1"/>

        { showAccept && 
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChangedCheckbox}
                name="checkedB"
                color="primary"
              />
            }
            label="I Accept the Terms & Conditions"
          />
        }
          </React.Fragment>   
            )

}