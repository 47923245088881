import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";


import '@fontsource/fjalla-one';
import '@fontsource/oswald';

import TermsAndConditions from "../TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy";

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "'Noto Sans HK'",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: "#009be5",
        position: "fixed",
        bottom: 0,
        width: "100%"
    }
}));

export default function Footer() {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [showTOS, setShowTOS] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const onCloseDialog = () => {
        setOpenDialog(false);
        setShowTOS(false);
        setShowPrivacyPolicy(false);
    }
    return (
        <>
        {/* <CssBaseline /> */}
        <Container className={classes.root} disableGutters maxWidth={false}>
            <Grid container spacing={2} style={{paddingLeft: "2em", paddingRight: "2em"}} justify="space-between">
                <Grid item>
                    <Typography variant="subtitle2" color="textSecondary" style={{color: "white"}}>
                        &copy;&nbsp;OneClinic
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={1} justify="flex-end">
                        <Grid item>
                            <Link style={{ cursor: "pointer", color: "white", fontSize: "12px" }} onClick={() => { window.open("https://oneclinic.io", "_blank") }}>About Us</Link>
                        </Grid>
                        <Grid item>
                            <Divider orientation='vertical' />
                        </Grid>
                        <Grid item>
                            <Link style={{ cursor: "pointer", color: "white", fontSize: "12px" }} onClick={() => { setOpenDialog(true); setShowTOS(true) }}>Terms</Link>
                        </Grid>
                        <Grid item>
                            <Divider orientation='vertical' />
                        </Grid>
                        <Grid item>
                            <Link style={{ cursor: "pointer", color: "white", fontSize: "12px"}} onClick={() => { setOpenDialog(true); setShowPrivacyPolicy(true) }}>Privacy Policy</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={onCloseDialog}>
                <div style={{ margin: '10px', minWidth: '100px', minHeight: '100px' }}>
                    {showTOS &&
                        <TermsAndConditions showAccept={false} />
                    }
                    {showPrivacyPolicy &&
                        <PrivacyPolicy showAccept={false} />
                    }
                    <Button size='small' color='primary' variant='outlined'
                        style={{ float: 'right', marginBottom: '10px' }}
                        onClick={onCloseDialog}
                    >
                        Close
                    </Button>
                </div>
            </Dialog>
        </Container>
        </>
    );
}