import React, { useState, useEffect, } from 'react';
//styles
import { withStyles } from '@material-ui/styles';
// dialog
import { 
  Typography, TextField, IconButton, 
  Tooltip, Avatar, Dialog, DialogTitle, 
  DialogContent, DialogActions, ListItemText 
} from '@material-ui/core';
import Linkify from "react-linkify";
// icons
import {
  Send as ChatIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
// lodash
import _get from 'lodash/get';
import _first from 'lodash/first';
import _isEmpty from 'lodash/isEmpty';
import _toUpper from 'lodash/toUpper';
import _map from "lodash/map";
import _includes from "lodash/includes";

const styles = {
  dialogPaper: {
    width: '320px',
    height: '420px',
  },
};

const mobileUserAgentRegexp = /Android.+Mobile|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
const isMobile = typeof navigator !== 'undefined' && mobileUserAgentRegexp.test(navigator.userAgent);

const ChatDialog = (props) => {

  const { 
    translucent=false,
    user={
      name: "Not Given",
    }, 
    classes, 
    messages,
    onSend=()=>{console.log("onSend() function not defined")},
    onCloseDialog=()=>{console.log("onCloseDailog not defined")},
  } = props;

  const [state, setState] = useState({
    messageTextField: '',
    randomBgColor: `#${Math.random().toString(16).substr(2, 4)}00`,
  });

  useEffect(() => {

  }, []);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <React.Fragment>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        style={{opacity: translucent?'0.9':'1'}}
        onClose={() => {
          onCloseDialog();
        }}
        open={props.showDialog || false}>

        {/* Title */}
        <DialogTitle style={{ paddingBottom: '6px' }}>
          <Tooltip title="Close">
            <IconButton style={{ float: 'right', padding: '4px' }}
              onClick={() => {
                onCloseDialog();
              }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <div style={{ display: 'flex' }}>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Avatar key='user-avatar' 
                style={{ 
                  height: '50px', 
                  width: '50px', 
                  backgroundColor: state.randomBgColor 
                }}
              >
                {_toUpper(_first(_get(user, 'name')))}
              </Avatar>
            </div>
            <div style={{ display: 'inline-flex', marginLeft: '6px', alignItems: 'center' }}>
              <ListItemText primary={`${_get(user, 'name')}`}/>
            </div>
          </div>

        </DialogTitle>

        {/* Content */}
        {/* display: 'flex' and felxDirection: 'reverse-column' are used to set scroll position to the bottom */}
        <DialogContent dividers style={{ display: 'flex', flexDirection: 'column-reverse' }}>
          {_map(messages, (msg, index) => {
            // ignore auto generated system messages
            if(!_includes(_get(msg, 'user.name'), 'SYSTEM')){
              return (
                <div key={index}>
                  <Linkify componentDecorator={componentDecorator}>
                    <Typography key={index} variant="body2" style={{ margin: '6px 0px' }}>{`${_get(msg, 'user.name', '')}: ${_get(msg, 'message')}`}</Typography>
                  </Linkify>
                </div>
              );
            }
          })}
        </DialogContent>

        {/* Actions */}
        <DialogActions style={{ justifyContent: 'center' }}>
          <form style={{ display: 'inherit', width: '95%' }} onSubmit={(e) => {
            e.preventDefault();
            onSend(state.messageTextField);
            setState({
              ...state,
              messageTextField: '',
            });
          }}>
            <TextField
              autoFocus={!isMobile}
              fullWidth
              value={state.messageTextField}
              onChange={(e) => {
                setState({
                  ...state,
                  messageTextField: e.target.value,
                })
              }}
            />
            <Tooltip title="Send">
              <IconButton style={{ marginLeft: '2px'}} type='submit'>
                <ChatIcon color="primary"/>
              </IconButton>
            </Tooltip>
          </form>
        </DialogActions>


      </Dialog>
    </React.Fragment>
  );
}
export default withStyles(styles)(ChatDialog);