import React from 'react';
import ReactDOM from 'react-dom';
import ProviderSearchLandingPage from './ProviderSearchLandingPage';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });


ReactDOM.render(
    <ThemeProvider theme={theme}>
        <ProviderSearchLandingPage/> 
    </ThemeProvider>,
document.getElementById('root'));