import fetch from "node-fetch";
import _get from "lodash/get";
import _toString from "lodash/toString";
import _slice from "lodash/slice";
import camelCase from "lodash/camelCase";
import _size from "lodash/size";
import _join from "lodash/join";
import _replace from "lodash/replace";

// Project
import { QUEUE_ENDPOINT } from "../../Constants";


const createQueueUser = async (user) => {
    try{
        const response = await fetch(
            `${QUEUE_ENDPOINT}/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user,
                }),
            }
        );
    
        const body = await response.json();
    
        if (_get(body, 'status') != 200) {
            return null
        }
        const queueUser = _get(body, 'data');
        return queueUser;
    } catch(e){
        console.error(e);
        return null;
    }
}

const getQueueUser = async (uuid) => {
    try{
        const response = await fetch(
            `${QUEUE_ENDPOINT}/get`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uuid,
                }),
            }
        );
    
        const body = await response.json();
    
        if (_get(body, 'status') != 200) {
            return null
        }
        const queueUser = _get(body, 'data');
        return queueUser;
    } catch(e){
        console.error(e);
        return null;
    }
}

const getClinicUserByCode = async (network_id, clinic_id, code) => {
    try{
        const response = await fetch(
            `${QUEUE_ENDPOINT}/get-clinic-user-by-code`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    channel: _toString(network_id),
                    npi: _toString(clinic_id),
                    code: _toString(code),
                }),
            }
        );
    
        const body = await response.json();
    
        if (_get(body, 'status') != 200) {
            return null
        }
        const queueUser = _get(body, 'data');
        return queueUser;
    } catch(e){
        console.error(e);
        return null;
    }
}

const getLastNDigits = (phone_number, n=4) => {
    let num = _toString(phone_number);
    num = _replace(num, /\D/g, '');
    const length = _size(num)
    const lastN = _join(_slice(num, length - n, length + 1), '');
    return lastN;
}

const getClinicUserByPhone = async (network_id, clinic_id, phone) => {
    const _phone = getLastNDigits(phone);
    try{
        
        const response = await fetch(
            `${QUEUE_ENDPOINT}/get-clinic-user-by-phone`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    channel: _toString(network_id),
                    npi: _toString(clinic_id),
                    phone: _toString(_phone),
                }),
            }
        );
    
        const body = await response.json();
    
        if (_get(body, 'status') != 200) {
            return null
        }
        const queueUser = _get(body, 'data');
        return queueUser;
    } catch(e){
        console.error(e);
        return null;
    }
}

const updateStatus = async (uuid, status) => {
    try{
        const response = await fetch(
            `${QUEUE_ENDPOINT}/update-status`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uuid,
                    status,
                }),
            }
        );
    
        const body = await response.json();
    
        if (_get(body, 'status') != 200) {
            return null
        }
        const queueUser = _get(body, 'data');
        return queueUser;
    } catch(e){
        console.error(e);
        return null;
    }
}

export default {
    createQueueUser,
    getQueueUser,
    getClinicUserByCode,
    getClinicUserByPhone,
    updateStatus,
}