import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmailVerification from "../EmailVerification";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import green from "@material-ui/core/colors/green"
import blue from "@material-ui/core/colors/blue";

// lodash
import _get from "lodash/get";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _includes from "lodash/includes";
import _toLower from "lodash/toLower";

import PatientConsent from "../../Patient/PatientConsent";
import { tos as tosContent } from "../TermsAndConditions";
import { consent as consentContent } from "../ConsentForm";
import { privacy as privacyContent } from "../PrivacyPolicy";


const initialState = {
    name: '',
    email: '',
    phone_number: '',
    loadEmailVerification: false,
    error: null,
    hasEmailLoaded: false,
    deliveryOption: null,
    selectedService: '',
    showConsentError: false,
    patientConsentGiven: false,
    hasCovidRisk: null,
}
class ClinicQueueForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleChange(e){
        this.setState({
            [e.target.name]: e.target.value,
        })
    }
    resetForm(){
        const { resetFormAfterSuccess } = this.props;
        if(resetFormAfterSuccess){
            this.setState({
                ...initialState,
            })
            return;
        }
        // otherwise keep the user info
        this.setState({
            ...initialState,
            name: this.state.name,
            email: this.state.email,
            phone_number: this.state.phone_number,
            deliveryOption: this.state.deliveryOption,
            selectedService: this.state.selectedService,
        })
    }
    componentDidUpdate(prevProps){
        if(prevProps.selectedService != this.props.selectedService){
            this.setState({
                selectedService: this.props.selectedService
            })
        }
    }
    onChangeConsentCheck(e){
        this.setState({
            patientConsentGiven: e.target.checked,
        })
        this.setState({
          showConsentError: !e.target.checked,
        })
    }
    render() {
        const {
            openDialog=false,
            handleClose=()=>{console.log('handleClose is not defined')},
            onDataSubmit=(data)=>{console.log(data)},
            onVerificationSuccess=()=>{console.log('onVerificationComplete is not defined')},
            showPharmacyChoice=false,
            pharmacy,
            isMobile=false,
            clinicServices=[],
            showServices=false,
            resetFormAfterSuccess=false,
        } = this.props;
        // console.log('Services', clinicServices);
        const {loadEmailVerification, error, hasEmailLoaded, patientConsentGiven, showConsentError, selectedService, hasCovidRisk} = this.state;

        return (
            <React.Fragment>
                <Dialog fullScreen={isMobile} open={openDialog} onClose={()=>{
                    handleClose();
                    this.resetForm();
                }}>
                    {!loadEmailVerification &&
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        if(patientConsentGiven){
                            const agreementDetails = {
                                date: new Date().toString(),
                                name: _get(this.state, 'name'),
                                agreed: patientConsentGiven,
                                termsOfService: tosContent.join("\\n"),
                                consent: consentContent.join("\\n"),
                                privacyPolicy: privacyContent.join("\\n"),
                            };
                            const data = {
                                name: this.state.name,
                                email: this.state.email,
                                phone_number: this.state.phone_number,
                                deliveryOption: this.state.deliveryOption?this.state.deliveryOption:'',
                                pharmacy: this.state.deliveryOption?_get(pharmacy, 'name'):'',
                                pharmacyAddress: this.state.deliveryOption?_get(pharmacy, 'address'):'',
                                serviceType: this.state.selectedService,
                                agreementDetails,
                                hasCovidRisk: this.state.hasCovidRisk=='yes'?true:false,
                            }
                            onDataSubmit(data, ()=>{
                                this.setState({
                                    loadEmailVerification: true,
                                })
                            });
                        } else {
                            this.setState({
                                showConsentError: true,
                            })
                        }

                        
                        // handleClose();
                    }}>
                        <DialogTitle>Contact Information</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter your contact information
                            </DialogContentText>
                            <TextField
                                style={{margin: '0.5em 0'}}
                                required
                                autoFocus
                                variant="outlined"
                                // margin="dense"
                                name="name"
                                label="Name"
                                value={this.state.name || ''}
                                placeholder="First Last"
                                onChange={this.handleChange.bind(this)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                style={{margin: '0.5em 0'}}
                                required
                                variant="outlined"
                                // margin="dense"
                                name="email"
                                label="Email"
                                placeholder="user@gmail.com"
                                value={this.state.email|| ''}
                                type="email"
                                onChange={this.handleChange.bind(this)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                style={{margin: '0.5em 0 1em 0'}}
                                required
                                variant="outlined"
                                // margin="dense"
                                name="phone_number"
                                label="Phone Number"
                                value={this.state.phone_number || ''}
                                onChange={this.handleChange.bind(this)}
                                placeholder="+1 123 567890"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                            { showServices &&
                                <FormControl variant="outlined" required fullWidth style={{marginBottom: '1em'}}>
                                    <InputLabel shrink htmlFor='service'>Service</InputLabel>
                                    <Select
                                        native
                                        input={<OutlinedInput notched label='Service' />}
                                        required
                                        name='selectedService'
                                        value={_get(this.state, 'selectedService', '')}
                                        onChange={(e) => {
                                            // console.log(e.target.value)
                                            this.setState({
                                                selectedService: e.target.value,
                                            });
                                        }}
                                    >
                                        <option key="empty" value=''></option>
                                        {_map(clinicServices, (item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                        }
                                        <option key="other" value="Other">Other</option>

                                    </Select>
                                </FormControl>
                            }
                            {_includes(_toLower(this.state.selectedService), 'covid') &&
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{ lineHeight: '1.25', }} focused={false}>
                                        Reason for COVID test?
                                    </FormLabel>
                                    <RadioGroup name="hasCovidRisk" value={hasCovidRisk} onChange={this.handleChange.bind(this)}>
                                        <FormControlLabel value="yes" control={<Radio required />} style={{alignItems: 'flex-start'}} label={
                                            <div style={{marginTop: '0.5em'}}>
                                                <Typography>
                                                    I have had <b>Covid symptoms</b> (cough, shortness of breath, fever, chills, muscle aches, body aches, vomiting, diarrhea, loss of taste or smell) or suspect <b>close contact</b> with a person with COVID in the last 14 days?
                                                </Typography>
                                                <Typography variant="body2" style={{ marginTop: '0.5em', }}>
                                                    <b>&#9432;</b>&nbsp;Medically necessary Covid testing is provided at <b>no cost</b>. You will need a valid government issued <b>ID</b> and your <b>insurance card</b>.  If you don't have insurance then an ID and no insurance affidavit is required.
                                                </Typography>
                                            </div>
                                        } />
                                        <FormControlLabel value="no" control={<Radio required />}  style={{alignItems: 'flex-start', marginTop: '0.5em'}} label={
                                            <div style={{marginTop: '0.5em'}}>
                                                <Typography>
                                                    I need testing for travel or routine testing for work.
                                                </Typography>
                                                <Typography variant="body2" style={{ marginTop: '0.5em', }}>
                                                <b>&#9432;</b>&nbsp;Testing for travel or work <b>requires cash payment</b>. {(_includes(clinicServices, selectedService)?(selectedService == 'Covid-PCR'?<b>$135 ({selectedService})</b>:selectedService == 'Covid-Rapid'?<b>$75 ({selectedService})</b>:''):'')}
                                                </Typography>
                                            </div>
                                        } />
                                    </RadioGroup>
                                </FormControl>
                            }
                            { showPharmacyChoice &&
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{ lineHeight: '1.6', }}><span style={{color: green[500]}}>{_get(pharmacy, 'name')}</span> delivers in your area. Would you like free medication delivery? </FormLabel>
                                    <p style={{color:"rgba(0, 0, 0, 0.54)", fontSize: '0.98em'}}>Delivery services are provided by an independent pharmacy provider. See 
                                        <a style={{color:"rgba(0, 0, 0, 0.54)", margin: "0 0.2em -0.2em 0.2em",}} target="_blank" href="https://oneclinic.io/terms.html">terms and conditions</a> 
                                        for details.
                                    </p>
                                    <RadioGroup name="deliveryOption" value={this.state.deliveryOption} onChange={this.handleChange.bind(this)}>
                                        <FormControlLabel value="Free Delivery" control={<Radio required/>} label="Yes, please deliver medications for free." />
                                        <FormControlLabel value='' control={<Radio required/>} label="No, choose the pharmacy later." />
                                    </RadioGroup>
                                </FormControl>
                            }
                            <PatientConsent
                                patientConsentGiven={patientConsentGiven}
                                onChangeConsentCheck={this.onChangeConsentCheck.bind(this)}
                                showConsentError={showConsentError}
                                smallFont={false}
                            />
                            { this.state.error &&
                                <p style={{color: 'red', textAlign: 'center'}}>{error}</p>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button color="default" onClick={handleClose} color="primary">
                                Close
                            </Button>
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                    
                    }
                    {loadEmailVerification &&
                        // <DialogContent>
                        <>
                            <EmailVerification
                                email={this.state.email}
                                phone_number={this.state.phone_number}
                                onSuccess={async()=>{
                                    await onVerificationSuccess();
                                    window.sessionStorage.removeItem('visitor_token');
                                    this.setState({
                                        error: null,
                                        loadEmailVerification: false,
                                    })
                                    handleClose();
                                    this.resetForm();
                                }}
                                onFailure={(reason)=>{
                                    if(reason){
                                        this.setState({
                                            loadEmailVerification: false,
                                            error: reason,
                                        })
                                    }
                                }}
                                spaceOnTop={false}
                                showLoading={true}
                                onLoad={()=>{
                                    this.setState({
                                        hasEmailLoaded: true
                                    })
                                }}
                            />
                            {this.state.hasEmailLoaded &&                            
                                <DialogActions>
                                    <Button color="default" onClick={()=>{
                                        handleClose();
                                        this.resetForm();
                                    }} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                            }
                        </>
                        // </DialogContent>
                    }
                </Dialog>
            </React.Fragment>
        );
    }
}
export default ClinicQueueForm;