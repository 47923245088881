const io = require('socket.io-client');
const { CHAT_SOCKETIO_URL, CHAT_SOCKETIO_PATH } = require("../Constants");
const _toString = require("lodash/toString");
const _size = require("lodash/size");
const _map = require("lodash/map");
const _toNumber = require("lodash/toNumber");
const _compact = require("lodash/compact");
const _get = require("lodash/get");
const { getKeycloak } = require("../services/keycloak");

var socket;
export default function () {
  if(!socket){
    const keycloak = getKeycloak();
    // console.log('---TOKEN---', keycloak.token);
    socket = io.connect(CHAT_SOCKETIO_URL, {
        path: CHAT_SOCKETIO_PATH, 
        query: {
          token: _get(keycloak, 'token', '')
        }
     
      }
    );
  }

  // register callbacks for event listeners

  function setOnMessageCallback(processMessage) {
    socket.on('message', processMessage);
  }

  function setOnReconnectCallback(processReconnect){
    socket.on('reconnect', processReconnect);
  }


  function setOnChatHisotryCallback(processChatHistory) {
    socket.on('chat-history', processChatHistory);
  }

  function setOnFinalizeEncounterCallback(processFinalizeEncounter){
    socket.on('finalize-encounter', processFinalizeEncounter);
  }

  function setOnOnlinePresenceCallback(updateOnlinePresence){
    socket.on('online-presence', updateOnlinePresence)
  }

  function setOnNetworkOnlinePresenceCallback(updateNetworkOnlinePresence){
    socket.on('network-online-presence', updateNetworkOnlinePresence)
  }

  function setOnQueueUpdateCallback(updateQueue){
    socket.on('queue-update', updateQueue)
  }

  function setOnReloadAppointmentRepliesCallback(reloadAppointments){
    socket.on('reload-appointment-replies', reloadAppointments)
  }

  // unregister callbacks for event listeners

  function unsetOnMessageCallback(processMessage) {
    socket.off('message', processMessage);
  }

  function unsetOnReconnectCallback(processReconnect){
    socket.off('reconnect', processReconnect);
  }


  function unsetOnChatHisotryCallback(processChatHistory) {
    socket.off('chat-history', processChatHistory);
  }

  function unsetOnFinalizeEncounterCallback(processFinalizeEncounter){
    socket.off('finalize-encounter', processFinalizeEncounter);
  }

  function unsetOnOnlinePresenceCallback(updateOnlinePresence){
    socket.off('online-presence', updateOnlinePresence)
  }

  function unsetOnNetworkOnlinePresenceCallback(updateNetworkOnlinePresence){
    socket.off('network-online-presence', updateNetworkOnlinePresence)
  }

  function unsetOnQueueUpdateCallback(updateQueue){
    socket.off('queue-update', updateQueue)
  }

  function unsetOnReloadAppointmentRepliesCallback(reloadAppointments){
    socket.off('reload-appointment-replies', reloadAppointments)
  }

  socket.on('error', function (err) {
    console.log('received socket error:');
    console.log(err)
  })

  // event emitters

  function join(chatroomName, userName) {
    socket.emit('join', chatroomName, userName);
  }

  function leave(chatroomName, userName) {
    socket.emit('leave', chatroomName, userName);
  }

  function message(chatroomName, userName, msg) {
    socket.emit('message', { chatroomName, userName, message: msg });
  }

  function requestChatHistory(chatroomName){
    socket.emit('request-chat-history', chatroomName);
  }

  function sendFinalizeEncounter(chatroomName){
    socket.emit('finalize-encounter', chatroomName);
  }

  function clearChatHistory(chatroomName){
    socket.emit('clear-chat-history', chatroomName);
  }
  // OZ: The default state_code is OH (Ohio) to keep backward compatibility
  function publishPresence(networkID, userID, state_code="OH"){
    if(networkID && userID){
      socket.emit('publish-presence', _toString(networkID), _toString(userID), state_code);
    }
  }

  function subscribePresence(ids){
    const _ids = _compact(_map(ids, (id) => _toString(id)));
    if(_size(_ids)>0){
      socket.emit('subscribe-presence', _ids);
    }
  }

  function subscribeNetworkPresence(network_id){
    if(network_id){
      console.log('Subscribing to network presence', network_id)
      socket.emit('subscribe-network-presence', _toString(network_id));
    }
  }

  function subscribeQueue(queue_id, clinic_id){
    if(queue_id && clinic_id){
      socket.emit('subscribe-queue', _toString(queue_id), _toString(clinic_id));
    }
  }

  function addToQueue(queue_id, clinic_id, user=null){
    if(queue_id && clinic_id){
      socket.emit('add-to-queue', _toString(queue_id), _toString(clinic_id), user)
    }
  }

  function removeFromQueue(queue_id, clinic_id, uuid=null){
    if(queue_id && clinic_id && uuid){
      socket.emit('remove-from-queue', _toString(queue_id), _toString(clinic_id), uuid)
    }
  }

  function switchQueue(new_queue_id, user){
    if(new_queue_id && user){
      socket.emit('switch-queue', _toString(new_queue_id), user);
    }
  }

  function moveUserInQueue(queue_id, user){
    socket.emit('move-user-in-queue', _toString(queue_id), user);
  }


  function subscribeAppointmentReplies(request_uuid){
    if(request_uuid){
      socket.emit('subscribe-appointment-reply', request_uuid);
    }
  }

  function sendAppointmentReply(request_uuid){
    if(request_uuid){
      socket.emit('send-appointment-reply', request_uuid);
    }
  }

  function switchQueue(new_queue_id, user){
    if(new_queue_id && user){
      socket.emit('switch-queue', _toString(new_queue_id), user);
    }
  }


  function subscribeAppointmentReplies(request_uuid){
    if(request_uuid){
      socket.emit('subscribe-appointment-reply', request_uuid);
    }
  }

  function sendAppointmentReply(request_uuid){
    if(request_uuid){
      socket.emit('send-appointment-reply', request_uuid);
    }
  }

  function switchQueue(new_queue_id, user){
    if(new_queue_id && user){
      socket.emit('switch-queue', _toString(new_queue_id), user);
    }
  }


  function subscribeAppointmentReplies(request_uuid){
    if(request_uuid){
      socket.emit('subscribe-appointment-reply', request_uuid);
    }
  }

  function sendAppointmentReply(request_uuid){
    if(request_uuid){
      socket.emit('send-appointment-reply', request_uuid);
    }
  }

  function resetQueue(queue_id, count){
    if(queue_id){
      socket.emit('reset-queue', _toString(queue_id), _toNumber(count));
    }
  }

  function disconnect(){
    socket.close();
  }

  async function getClinicsByLocation(longitude, latitude, distance = 50, limit = 10) {
    // OZ: Making a promise out of socket.emit and using the acknowledge workflow to get the result rightaway by passing in a callback
    return await new Promise((resolve) => {
      // console.log("---Emit---");
      socket.emit('get-clinics-by-location', longitude, latitude, distance, limit, (clinics) => {
        // console.log('Resolve: ', JSON.stringify(clinics));
        resolve(clinics);
      });
    });
  }


  async function addToQueueAwaitable(queue_id, clinic_id, user=null){
    return await new Promise((resolve) => {
      if(queue_id && clinic_id){
        socket.emit('add-to-queue', _toString(queue_id), _toString(clinic_id), user, (addedUser) => {
          resolve(addedUser);
        });
      }
    });
  }

  async function getQueueUser(uuid){
    return await new Promise((resolve) => {
      if(uuid){
        socket.emit('get-queue-user', uuid, (queueUser) => {
          resolve(queueUser);
        });
      }
    });
  }

  async function addClinic(clinic){
    return await new Promise((resolve) => {
        socket.emit('add-clinic', clinic, (addedClinic) => {
          resolve(addedClinic);
        });
    });
  }

  async function removeClinic(clinic){
    return await new Promise((resolve) => {
        socket.emit('remove-clinic', clinic, (isRemoved) => {
          resolve(isRemoved);
        });
    });
  }

  return {
    socket,
    join,
    leave,
    publishPresence,
    subscribePresence,
    subscribeNetworkPresence,

    subscribeQueue,
    addToQueue,
    addToQueueAwaitable,
    removeFromQueue,
    switchQueue,
    moveUserInQueue,
    getQueueUser,
    resetQueue,

    subscribeAppointmentReplies,
    sendAppointmentReply,


    message,
    requestChatHistory,
    sendFinalizeEncounter,
    clearChatHistory,

    setOnMessageCallback,
    setOnReconnectCallback,
    setOnChatHisotryCallback,
    setOnFinalizeEncounterCallback,
    setOnOnlinePresenceCallback,
    setOnNetworkOnlinePresenceCallback,
    setOnQueueUpdateCallback,
    setOnReloadAppointmentRepliesCallback,

    unsetOnMessageCallback,
    unsetOnReconnectCallback,
    unsetOnChatHisotryCallback,
    unsetOnFinalizeEncounterCallback,
    unsetOnOnlinePresenceCallback,
    unsetOnNetworkOnlinePresenceCallback,
    unsetOnQueueUpdateCallback,
    unsetOnReloadAppointmentRepliesCallback,

    getClinicsByLocation,
    addClinic,
    removeClinic,

    disconnect,
  }
}

