import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Block from "./Block";

// Lodash/fp
import isUndefined from "lodash/fp/isUndefined";
export const privacy = [
  'Privacy Policy',
  'Last update: May 20th 2020',
  'This Privacy Policy describes the types of information AMK Technologies may collect from you or that you may provide when you visit this website. Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our website. By accessing our website and using our services, as more defined in our Terms of Use Agreement (“Terms of Use”), you agree to the terms of and to be contractually bound by this Privacy Policy.',
  'Minors/Under 18:',
  'We do not knowingly allow individuals under 18 to use the website or create accounts.',
  'What we provide:',
  'This website and AMK Technologies provide only the technology for the platform which allows you to obtain services for telemedicine with a licensed medical provider. We do not supply medical services. The medical services are provided by your medical provider(s), who are independent licensed professionals in your state of residence.',
  'Patient Information:',
  'Your medical provider may request certain patient information from you. Your medical provider will have their own Privacy Policy.',
  'AMK Technologies requests, obtains, collects, or stores your medical information only for the purposes of coordinating medical care and payment for medical services. The receipt of payment information is stored but is not identifiable to particular treatment, care, services or treatment.  AMK Technlogies may share this records with medical care providers, pharmacies, laboratories, insurance providers, clearinghouses, and suppliers under a Business Associate Agreement.',
  'You should request your medical provider to provide their own Privacy Policy. Your independent healthcare professional is responsible for taking appropriate measures to ensure the safety of your patient healthcare records in accordance with federal and state rules. If you desire your patient records, you should request those directly from the independent provider.',
  'Payment Information: ',
  'AMK Technologies uses third party processors (such as Square, Gpay, or ApplePay) to process the payments on behalf of the medical provider.',
  'This information is not stored.',
  'Information Collected:',
  'AMK Technologies’ website may collect information from individuals that visit the website. Some examples of information that we collect include your name, address, telephone number, email address, IP address, and other information you provide to us. We may also collect behavioral information regarding how you use our website and the areas of our website that you visit to better serve you in the future. We do monitor website activity information and we may collect information about the device you are using e.g. computer or cell phone, which browser you are using, and other information about how you use the site. We use Google analytics to improve our site and services.',
  'We may use both session cookies and persistent cookies. You can change your browser setting to stop accepting cookies.',
  'How Information Is Used:',
  'By accessing our website, you have agreed to allow us to use your information, subject to applicable laws, in the following manner:',
  'To present the platform to obtain telemedicine services',
  'To provide you with information regarding our policies',
  'To help us improve our website and analyze statistics',
  'To notify you about changes to our website or any products or services we offer or provide through it',
  'For any other purpose with your consent',
  'Subject To Change:', 
  'The terms and conditions of this Privacy Policy are subject to change at any time by updating this page. Your continued use of our website after we make changes is deemed to be acceptance of those changes, so please check the Privacy Policy periodically for updates.',
  'Discrimination:',
  'We will not discriminate against you for exercising any of your privacy rights under law or set forth by this site policy.',
  'External Sites:',
  'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.',
  'Contact Information:',
  'If you have any questions or comments about this Privacy Policy and our privacy practices, please contact AMK Technologies at info@amktechnologies.net.',
]
export default ( { height, onAccept, initialValue, showAccept=true }) => {
  const [checked, setChecked] = useState(initialValue || false);

  const handleChangedCheckbox = () => {
    if (!checked) {
      setChecked(true);
      onAccept(true, privacy);
    }
    else {
      setChecked(false);
      onAccept(false, privacy);
    }
  }
  const styles = {
    text: isUndefined(height) ? { padding: '0 2em' } : {
      overflow: "auto",
      maxHeight: height,
      padding: "0 4em 0 0",
    },
    underlinedHeading: {
      fontWeight: '600',
      textDecoration: 'underline',
      display: 'inline',
    },
    textInline: {
      display: 'inline',
      marginLeft: '5px',
    },
    textInlineBold: {
      display: 'inline',
      marginLeft: '5px',
      fontWeight: '500',
    }
  }

    return (

      <React.Fragment>

        <Block size="h2" />

        <Typography variant="h5" style={{ textAlign: 'center'}}>
          {privacy[0]}
        </Typography>


        <Block size="h2" />

        <div style={styles.text}>
          <Typography variant="body1">
            {privacy[1]}
          </Typography>

          <Block size="h1" />

          <Typography variant="body1">
            {privacy[2]}
          </Typography>

          <Block size="h1" />

          <Typography style={styles.underlinedHeading}>
            {privacy[3]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[4]}
          </Typography>

          <Block size="h1" />

          <Typography style={styles.underlinedHeading}>
            {privacy[5]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[6]}
          </Typography>

          <Block size="h1" />

          <Typography style={styles.underlinedHeading}>
            {privacy[7]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[8]}
          </Typography>
          <Typography style={styles.textInlineBold}>
            {privacy[9]}
          </Typography>

          <Block size="h1" />

          <Typography>
            {privacy[10]}
          </Typography>

          <Block size="h1" />

          <Typography style={styles.underlinedHeading}>
            {privacy[11]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[12]}
          </Typography>
          <Typography style={styles.textInlineBold}>
            {privacy[13]}
          </Typography>

          <Block size="h1" />

          <Typography style={styles.underlinedHeading}>
            {privacy[14]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[15]}
          </Typography>


          <Block size="h1" />
          <Typography>
            {privacy[16]}
          </Typography>

          <Block size="h1" />

          <Typography style={styles.underlinedHeading}>
            {privacy[17]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[18]}
          </Typography>

          <ul>
            <li>
              <Typography>
                {privacy[19]}
              </Typography>
            </li>
            <li>
              <Typography>
                {privacy[20]}
              </Typography>
            </li>
            <li>
              <Typography>
                {privacy[21]}
              </Typography>
            </li>
            <li>
              <Typography>
                {privacy[22]}
              </Typography>
            </li>
            <li>
              <Typography>
                {privacy[23]}
              </Typography>
            </li>
          </ul>

          <Block size="h1" />


          <Typography style={styles.underlinedHeading}>
            {privacy[24]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[25]}
          </Typography>


          <Block size="h1" />


          <Typography style={styles.underlinedHeading}>
            {privacy[26]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[27]}
          </Typography>


          <Block size="h1" />


          <Typography style={styles.underlinedHeading}>
            {privacy[28]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[29]}
          </Typography>


          <Block size="h1" />


          <Typography style={styles.underlinedHeading}>
            {privacy[30]}
          </Typography>
          <Typography style={styles.textInline}>
            {privacy[31]}
          </Typography>


          <Block size="h1" />

        </div>

        { showAccept &&
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChangedCheckbox}
                name="checkedB"
                color="primary"
              />
            }
            label="I accept the privacy policy"
          />
        }
          </React.Fragment>   
            )

}