const fetch = require("node-fetch");
const _get = require("lodash/get");
const { RESEND_VERIFICATION_CODE_ENDPOINT, VERIFY_EMAIL_ENDPOINT, VERIFY_KEYCLOAK_TOKEN } = require("../../Constants");

/**
 * Request to resend verification code
 * @param {String} email
 * @param {String} phone_number
 */
const resendVerificationCode = async (email, phone_number) => {
    try{
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email,
              phone_number,
            }),
        }
        const response = await fetch(RESEND_VERIFICATION_CODE_ENDPOINT, request);

        const body = await response.json()
     
        if(_get(body, 'status') == 200){
            return {
                success: true
            };
        }else{
            console.error("Request to resend verification code failed");
            return {
                success: false,
                message: _get(body, 'message', 'Failed to send verification code')
            }
        }
        
    } catch(e){
        console.error(e);
        return {
            success: false,
            message: 'Something went wrong with backend server'
        }
    }
}


const verifyEmail = async(email, code) => {
    try{
         const body = {
           email,
           verificationCode: code,
         }
         
         const request = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify(body),
         }
         
         const response = await fetch(VERIFY_EMAIL_ENDPOINT, request);
         const resBody = await response.json();
         const status = _get(resBody, 'status');
         const token = _get(resBody, 'data.token');
    
        if(status == 200){
            return token;
        } else {
            return null
        }

    
     } catch(e){
         console.error(e);
         return null;
     }
 }
 const verifyKeycloakToken = async(token) => {
    try{
         const body = {
           token
         }
         
         const request = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify(body),
         }
         
         const response = await fetch(VERIFY_KEYCLOAK_TOKEN, request);
         const resBody = await response.json();
         const status = _get(resBody, 'status');
    
        if(status == 200){
            return true;
        } else {
            return false
        }

    
     } catch(e){
         console.error(e);
         return null;
     }
 }
 export default {
    resendVerificationCode,
    verifyEmail,
    verifyKeycloakToken,
 }