import React from "react";

// Lodash/fp
import get from "lodash/fp/get";
import isUndefined from "lodash/fp/isUndefined";

export default ({ width="100%", height, size="h1" }) => {

    const _height = isUndefined(height) ? get(size)({
        "h1": "1em",
        "h2": "2em",
        "h3": "4em",
        "h4": "8em",
    }) :  height;

    return (
        <div style={{ width, height: _height, }}>

        </div>
    );
}