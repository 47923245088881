const {
    IP_LOOKUP_URL
} = require("../../Constants");

// direct request without involving the backend server - has a limit of 1000/day and 30,000/month if ipapi.co is being used
export const getLocationData = async()=>{
    try {
        const response = await fetch(
            `${IP_LOOKUP_URL}`,
        );
        const data = response.json()
        // Example response:
        // {
        //     "ip": "XXX.XXX.XXX.XXX",
        //     "hostname": "XXX-XXX-XXX-XXX.dsl.net.pk",
        //     "city": "Islamabad",
        //     "region": "Islamabad",
        //     "country": "PK",
        //     "loc": "33.7215,73.0433",
        //     "org": "AS23674 Nayatel (Pvt) Ltd",
        //     "postal": "44000",
        //     "timezone": "Asia/Karachi"
        // }
        return data;
    } catch(e){
        console.error(e)
    }
}

export default {
    getLocationData
}