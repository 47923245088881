import fetch from "node-fetch"
import _get from "lodash/get"

import { NOMINATIM_ENDPOINT } from "../../Constants"

const geocodeLocation = async (address) => {
    try {
        const res = await fetch(NOMINATIM_ENDPOINT + `?q=${address}&addressdetails=1&format=jsonv2`)
        const result = await res.json()

        // const { lat, lon } = _get(result, "0")

        return _get(result, "0")
    }
    catch (e)
    {
        return {}
    }
}


const reverseGeocode = async (lat, lon) => {
    try {
        const res = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`)
        const result = await res.json()

        // const { lat, lon } = _get(result, "0")

        return result
    }
    catch (e)
    {
        return {}
    }
}
export default {
    geocodeLocation,
    reverseGeocode
}