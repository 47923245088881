// Lodash/fp
const get = require("lodash/fp/get");

// Lodash
const _trimEnd = require("lodash/trimEnd");

// Util Function
// MK.  DO NO IMPORT 
// Importing this function breaks either Storybook if module.exports is used
// or node if export is used
// Just add directly in file
const getLinkBaseUrl = (baseurl) => {
    const protocol = get("protocol")(baseurl);
    const hostname = get("hostname")(baseurl);
    const port = get("port")(baseurl);
    const urlString = `${protocol}//${hostname}${port ? `:${port}` : ""}`;

    let url = null;
    try {
        url = _trimEnd(new URL(urlString).href, "/");
    } catch (err) {
        console.error("Could not make valid base url", {
            protocol,
            hostname,
            port,
            urlString,
        });
        console.error(err);
    }

    return url;
}

const getApiServerURL = (server_port) => {
    const locationObj = get('location')(window);
    const protocol = get("protocol")(locationObj);
    const hostname = get("hostname")(locationObj);
    const port = get("port")(locationObj);

    let apiServerURL = `${protocol}//${hostname}${port?`:${server_port}`:''}/api`;
    return apiServerURL;
}


// CONSTANTS
const SSE_BASE_URL = "https://sse.demo.amkserve.net";
const API_SERVER_LOCAL_PORT = "3002";
// const API_SERVER = process.env.REACT_APP_API_SERVER || "http://localhost:3002/api";
const API_SERVER = getApiServerURL(API_SERVER_LOCAL_PORT);
const DIRECT_CALLS_SERVER = "https://secure.calls.demo.amktechnologies.net";

// MK:
// Use ROOT_URL_ALIAS for the link base
// For proxies the ROOT_URL maybe different than proxy url
// eg. clinic.oneclinic.io  ->   clinic1.gs.amkserve.net
let ROOT_URL = process.env.REACT_APP_ROOT_URL || "http://localhost:3000";
try {
    const linkBaseUrl = getLinkBaseUrl(window.location);
    console.log("--link-base", linkBaseUrl);
    ROOT_URL = linkBaseUrl ? linkBaseUrl : ROOT_URL;
} catch (err) {
    console.error("Could not make ROOT_URL");
    console.error(err);
}

console.log("--root-url", ROOT_URL);

const SITE_METADATA = {
    title: process.env.REACT_APP_SITE_TITLE,
    description: process.env.REACT_APP_SITE_DESCRIPTION,
    icon: `${ROOT_URL}/favicon.ico`,
    apple_icon: `${ROOT_URL}/logo192.png`,
    manifest: `${ROOT_URL}/manifest.json`,
    pharmacy_favicon: `${ROOT_URL}/favicon-pharmacy.ico`,
    pharmacy_apple_icon: `${ROOT_URL}/apple-touch-icon-pharmacy.png`,
};
const DATE_DEPLOYED = "__DATE_DEPLOYED__";
const DEPLOYMENT_ID = "__DEPLOYMENT_ID__";

const DYNAMIC_PROFILE_GENERATION_URL = `https://tinc.amkserve.net/api`

// MK.  Switched from module.exports
//      module.exports breaks storybook
//      export default breaks the app
module.exports = {
    API_SERVER_URL: API_SERVER,
    SSE_PUBLISH_URL: `${SSE_BASE_URL}/publish`,
    SSE_SUBSCRIBE_URL: (channelName, clientId) => clientId ? `${SSE_BASE_URL}/subscribe?channels=${channelName}&clientId=${clientId}` : `${SSE_BASE_URL}/subscribe?channels=${channelName}`,
    ONLINE_PRESENCE_URL: (clientId) => `${SSE_BASE_URL}/heartbeat/?clientId=${clientId}`,
    //ROOT_URL: process.env.ROOT_URL || "http://localhost:3000",
    ROOT_URL,
    SMS_ENDPOINT: 'https://sse.amkserve.net/sms',
    EMAIL_ENDPOINT: 'https://sse.amkserve.net/email',
    HASURA_URI: process.env.HASURA_URI || process.env.REACT_APP_HASURA_URI || 'http://localhost:8080/v1/graphql',
    HASURA_WS_URI: process.env.HASURA_WS_URI || process.env.REACT_APP_WS_HASURA_URI || 'ws://localhost:8080/v1/graphql',
    // token for ipinfo.io is linked to this email: osama197@gmail.com
    // ipapi.co/json does not work at least from localhost (it has cloudflare checking as well) and does not allow sign up without payment
    IP_LOOKUP_URL: 'https://ipinfo.io/json?token=491781ab89bdc9', //'https://ipapi.co/json', // Allows 30,000 requests/month and 1000 requests/day
    MIN_WAIT_TIME: 10,
    MAX_WAIT_TIME: 60,
    SQUARE_SCRIPT_PATH: "https://js.squareupsandbox.com/v2/paymentform",
    SQUARE_WEB_PAYMENT_SCRIPT_PATH: 'https://sandbox.web.squarecdn.com/v1/square.js', // new web payment SDK of square to replace payment form
    SQUARE_APPLICATION_ID: "sandbox-sq0idb-ZyxSbbBijiv79enribFbQg",
    SQUARE_LOCATION_ID: "X80SP8SQH2MZZ",
    REGISTER_USER_ENDPOINT: `${API_SERVER}/keycloak/register-user`,
    RESET_PASSWORD_ENDPOINT: `${API_SERVER}/keycloak/reset-password`,
    JOIN_NETWORK_GROUP_ENDPOINT: `${API_SERVER}/keycloak/join-network-group`,
    SQUARE_BACKEND_API: "http://localhost:3001/payment-api",
    GET_PROVIDER_ENDPOINT: `${API_SERVER}/external-patient/get-provider`,
    GET_PROVIDERS_BY_ZIP_ENDPOINT: `${API_SERVER}/external-patient/get-providers-by-zip`,
    GET_PROVIDERS_BY_STATE_ENDPOINT: `${API_SERVER}/external-patient/get-providers-by-state`,
    CREATE_ENCOUNTER_ENDPOINT: `${API_SERVER}/external-patient/create-encounter`,
    NETWORK_CLIENT_CREATE_ENCOUNTER_ENDPOINT: `${API_SERVER}/external-patient/create-encounter-for-network-client`,
    VERIFY_EMAIL_ENDPOINT: `${API_SERVER}/external-patient/verify-email`,
    VERIFY_EMAIL_FOR_SIGNUP_ENDPOINT: `${API_SERVER}/external-patient/verify-email-for-signup`,
    VERIFY_DISCOUNT_CODE_ENDPOINT: `${API_SERVER}/external-patient/verify-discount-code`,
    RESEND_VERIFICATION_CODE_ENDPOINT: `${API_SERVER}/external-patient/resend-verification-code`,
    EXTERNAL_PAYMENT_API: `${API_SERVER}/payment-api`,
    VERIFY_ENCOUNTER_ENDPOINT: `${API_SERVER}/external-patient/verify-encounter`,
    UPDATE_ROOM_ENDPOINT: `${API_SERVER}/external-patient/update-room`,
    ADD_BUSINESS_DETAILS_ENDPOINT: `${API_SERVER}/external-patient/add-business-details`,
    GENERATE_PREPAID_CODE_ENDPOINT: `${API_SERVER}/provider/generate-code`,
    VERIFY_PREPAID_CODE_ENDPOINT: `${API_SERVER}/external-patient/verify-prepaid-code`,
    INSURANCE_DETAILS_ENDPOINT: `${API_SERVER}/external-patient/insurance-details`,
    REQUEST_APPOINTMENT_ENDPOINT: `${API_SERVER}/external-patient/request-appointment`,
    VERIFY_KEYCLOAK_TOKEN: `${API_SERVER}/external-patient/verify-keycloak-token`,
    NETWORK_ENDPOINT: `${API_SERVER}/network`,
    FILE_UPLOAD_ENPOINT: `${API_SERVER}/files/profile`,
    SECURE_FILE_UPLOAD_ENPOINT: `${API_SERVER}/secure-files`,
    PDF_FILLER_ENDPOINT: `${API_SERVER}/pdf-filler`,
    INTAKE_FORM_GENERATION_ENDPOINT: `${API_SERVER}/intake-form-generation`,
    AUTO_FILL_ENDPOINT: `${API_SERVER}/auto-fill`,
    PING_ENDPOINT: `${API_SERVER}/ping`,
    APPOINTMENT_ENDPOINT: `${API_SERVER}/appointment`,
    GET_ENCOUNTER_ENDPOINT: `${API_SERVER}/external-patient/get-encounter-details`,
    CUBBY_ENDPOINT: `${API_SERVER}/cubby`,
    PATIENT_CUBBY_ENDPOINT: `${API_SERVER}/patient-cubby`,
    SECURE_PATIENT_ENDPOINT: `${API_SERVER}/secure-patient`,
    CARD_ENDPOINT: `${API_SERVER}/card`,
    MARKETING_ENDPOINT: `${API_SERVER}/marketing`,
    RUN_LOCAL_SCRIPTS_ENDPOINT: `http://localhost:3050`,
    CALL_SOCKETIO_URL: "https://clinic.demo.amktechnologies.net",
    CALL_SOCKETIO_PATH: "/call", // should be same as on server end, defaults to /socket.io
    // CHAT_SOCKETIO_URL: "https://clinic.demo.amktechnologies.net",
    CHAT_SOCKETIO_URL: "https://sse.demo.amkserve.net",
    CHAT_SOCKETIO_PATH: "/chat", // should be same as on server end, defaults to /socket.io
    DIRECT_CALLS_URL: DIRECT_CALLS_SERVER,
    WAITLIST_ENDPOINT: `${DIRECT_CALLS_SERVER}/api/provider/waitlist`,
    DIRECT_CALLS_CODE: "2040",
    APPRTC_SERVER: "calls.amktechnologies.net",
    OPENEXCHANGE_API_TOKEN: "0784cdc6a7974da18f37feb2d7081239",
    USE_SECURE_CALLS: true, // Use DIRECT_CALLS_URL(if true) or APPRTC_SERVER(if false) for external calls 
    WHATSAPP_BASE_URL: 'https://wa.me/',
    LOGGING_URL: 'https://clinic.demo.amktechnologies.net/logging',
    EXTERNAL_LOGGING_URL: `${API_SERVER}/external-patient/log`,
    EXTERNAL_CALL_EVENT_LOGGING_URL: `${API_SERVER}/external-patient/call-event`,
    EXTERNAL_FEEDBACK_URL: `${API_SERVER}/external-patient/feedback`,
    CHECK_CARD_ON_FILE_ENDPOINT: `${API_SERVER}/provider/check-card-exists`,
    CHECK_ACTIVE_SUBSCRIPTION_ENDPOINT: `${API_SERVER}/provider/check-subscription-exists`,
    SUBSCRIPTION_PLANS_ENDPOINT: `${API_SERVER}/provider/list-subscription-plans`,
    GET_SUBSCRIPTIONS_ENDPOINT: `${API_SERVER}/provider/get-subscriptions`,
    GET_ACTIVE_SUBSCRIPTION_ITEMS_ENDPOINT: `${API_SERVER}/provider/get-subscription-items`,
    GET_SUBSCRIPTION_SUMMARY_ENDPOINT: `${API_SERVER}/provider/get-subscription-summary`,
    CREATE_SUBSCRIPTION_EVENT_ENDPOINT: `${API_SERVER}/provider/create-subscription-event`,
    SAVE_CARD_ENDPOINT: `${API_SERVER}/provider/save-card`,
    PROVIDER_ENDPOINT: `${API_SERVER}/provider`,
    CUSTOMER_ENDPOINT: `${API_SERVER}/customer`,
    ORDER_ENDPOINT: `${API_SERVER}/order`,
    SUBSCRIPTION_LICENSE: `${API_SERVER}/subscription-license`,
    PROVIDER_PROFILE_ENDPOINT: `${API_SERVER}/provider-profile`,
    CLINIC_ENDPOINT: `${API_SERVER}/clinic`,
    NETWORK_FORM_ENDPOINT: `${API_SERVER}/network_form`,
    REGENERATE_PROFILE_ENDPOINT: DYNAMIC_PROFILE_GENERATION_URL,
    GENERATE_PROFILE_ENDPOINT: `${DYNAMIC_PROFILE_GENERATION_URL}/generate-profile`,
    SERVE_STATIC_PROFILE_ENDPOINT: "https://profiles.oneclinic.io",
    PROVIDER_PROFILE_CLAIM_ENDPOINT: "https://horizon-prod.amkserve.net/api",
    PROVIDER_SEARCH_ENDPOINT: "https://horizon-prod.amkserve.net/api",
    INVITATION_ENDPOINT: `${API_SERVER}/invitation`,
    UNSUBSCRIBE_ENDPOINT: `${API_SERVER}/provider/unsubscribe`,
    REVERT_UNSUBSCRIBE_ENDPOINT: `${API_SERVER}/provider/revert-unsubscribe`,
    COFFEEMEET_ENDPOINT: `${API_SERVER}/coffee`,
    ACTIVE_BUSINESS_ENDPOINT: `${API_SERVER}/active-business`,
    URL_ENDPOINT: `${API_SERVER}/url`,
    QUEUE_ENDPOINT: `${API_SERVER}/queue`,
    LAB_RESULT_ENDPOINT: `${API_SERVER}/lab-result`,
    LOG_TAG: 'reactclient.clinic.demo',
    CALL_STATS_ENDPOINT: `${API_SERVER}/external-patient/call-stats`,
    CONFIGURATION_UUID_FOR_WIDGET: "d193f95a-72b4-4a04-afb5-38ec727cc363",
    EXTERNAL_CALL_URL: 'https://calls4.amktechnologies.net',
    PROVIDER_UUID_FOR_CONSULTATION: '190c3b8c-40c8-4dd8-9b10-37cbfe75c818',
    PRODUCTS_ENDPOINT: `${API_SERVER}/products`,
    INSURANCE_VERIFICATION_ENDPOINT: `${API_SERVER}/api-check`,
    NETWORK_OWNER_ENDPOINT: `${API_SERVER}/network-owner`,
    NETWORK_CONFIGURATION_ENDPOINT: `${API_SERVER}/network-configuration`,
    NETWORK_FEATURES_ENDPOINT: `${API_SERVER}/network-feature`,
    INVOICE_ENDPOINT: `${API_SERVER}/invoice`,
    BILLING_ACCOUNT_ENDPOINT: `${API_SERVER}/billing-account`,
    CUBBY_UUID_CLINIC_PROVIDER_CONTACT: 'd0cb017f-b330-4025-bfc8-1f9347d22d13', 
    PROVIDER_SEARCH_AUTOCOMPLETE_SOCKETIO_URL: `https://autocomplete.gs.amkserve.net`,
    NOMINATIM_ENDPOINT: `https://nominatim.openstreetmap.org/search.php`,
    networks: [
        {
            country_code: "US",
            network_id: "94238295",
            details: {
                TECH_SUPPORT_PHONE: '+1 740-263-6090',
                enableAppointmentRequest: true,
            },
        },
        {
            country_code: "PK",
            network_id: "59856452",
            details: {
                TECH_SUPPORT_PHONE: '+92 342 5217585',
                PAYMENT_OPTS_URL: "https://storage.googleapis.com/amk-pictures/myclinic/diabetesclinic.online/payments.pdf",
            },

        },
        {
            network_id: "67208377",
            details: {
                TECH_SUPPORT_PHONE: '+92 342 5217585',
                enableAppointmentRequest: true,
            },

        }
    ],
    TECH_SUPPORT_EMAIL: 'support@oneclinic.io',
    COMPATIBLE_BROWSERS: {
        apple: ['ios', 'safari'],
        others: ['chrome', 'samsung'],
        minVersion: {
            ios: 12,
            safari: 12,
            chrome: 76,
            samsung: 12,
        },
        special: [
            'macos:chrome'
        ],
    }, // browser names as returned by detect-browser package
    DEFAULT_SCHEDULE: [
        {
            days: [1, 2, 3, 4, 5],
            hours: ["09:00:00", "17:00:00"],
            slot_duration: "00:15:00",
            timezone: "America/New_York",
        }
    ],

    // Slot delay between now and first slot
    // MK.  Move to network settings
    MIN_SLOT_DELAY: 120,

    SITE_METADATA,
    DATE_DEPLOYED,
    DEPLOYMENT_ID,
    SPONSORED_PROVIDER: {
        prefix: "Dr.",
        firstName: "Ayesha",
        lastName: "Khan",
        credentials: "MD",
        speciality: "Family Medicine",
        phone: "(740) 263-6090",
        photoURL: "https://storage.googleapis.com/amk-pictures/profiles/1602684567432_ayesha-2.png",
        // demo widget url: "https://clinic.demo.oneclinic.io/w/5b581cc2-e901-4d36-81bc-454ae528c963"
        // prod widget url: "https://clinic.oneclinic.io/w/052f1cfc-8366-45b6-9581-e1cd5842d159"
        widgetURL: "https://clinic.demo.oneclinic.io/w/5b581cc2-e901-4d36-81bc-454ae528c963",
        npi: "123456",
        // network_id and user_profile_id is for demo1.provider
        network_id: "847494", // 847494 for demo hasura, 641291 for prod hasura
        user_profile_id: 134, // 134 for demo hasura, 67 for prod hasura
        address: "107 S. Main Street, Suite 301, Mount Vernon, OH",
        display_name: 'Dr. Ayesha Khan (MD)',
        provider_specialization: 'Family Medicine',
        formatted_phone_number: '(740) 263-6090',
        provider_first_line_business_practice_location_address: '107 S. Main St., Ste. 301',
        provider_business_practice_location_address_state_name: 'OH',
        provider_business_practice_location_address_city_name: 'Mount Vernon',


    },
    // A boolean to suspend the un-licensed accounts (subscription-invitation workflow). Keep this false for now.
    SUSPEND_ACCOUNTS: false,
    // OZ: Used for Pines Health pharmacy workflow. For local testing, use 990070 (or similar)
    PINES_HEALTH_NETWORK_ID: '632700',
    PINES_HEALTH_PHARMACY: {
        "uuid": "4eb6ddfe-b584-40db-a91a-1482dcf2c23e", // image saved to oneclinic/clinics/<uuid> on gcp
        "name": "Pines Health Mart Pharmacy",
        "type": "Pharmacy",
        "address": "2301 N University Dr # 112",
        "city": "Pembroke Pines",
        "state": "Florida",
        "zip": "33024",
        "phone_number": "(954) 404-6610",
        "clia": "10D2185710",
        "npi": "1780053769",
        "services": [
            "Covid-PCR",
            "Covid-Rapid",
            "Covid-PCR-24-hr"
        ],
        "services_in_stock": {
             "Covid-PCR": true,
             "Covid-Rapid": true,
             "Covid-PCR-24-hr": true
        },
        "schedule": {
            0: [], 
            1: ['09:00:00', '18:00:00'], 
            2: ['09:00:00', '18:00:00'], 
            3: ['09:00:00', '18:00:00'], 
            4: ['09:00:00', '18:00:00'], 
            5: ['09:00:00', '18:00:00'], 
            6: ['09:00:00', '13:00:00']
        },
        "timezone": "America/New_York",
        logo_url: "https://storage.googleapis.com/oneclinic/clinics/4eb6ddfe-b584-40db-a91a-1482dcf2c23e/logo64.jpg"
    },
    EMR_PRACTICE_NAME: "Family Urgent Care",
    // OZ: USE_CUSTOM_IMAGE_RES_INTAKE_FORM flag sets the custom resolution for videoConstraints
    // There are a few issues with using it:
    // 1. Takes a long time for images to upload
    // 2. Size of images and generated forms is quite large 
    USE_CUSTOM_IMAGE_RES_INTAKE_FORM: false,
    CUSTOM_IMAGE_WIDTH_INTAKE_FORM: 1920,
    CUSTOM_IMAGE_HEIGHT_INTAKE_FORM: 1080,
    ENABLE_NOTIFY_PROVIDER_VIA_TWILIO_CALL: false,
};
