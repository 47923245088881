const io = require("socket.io-client")
const { PROVIDER_SEARCH_AUTOCOMPLETE_SOCKETIO_URL } = require("../../Constants")

const socketioURL = PROVIDER_SEARCH_AUTOCOMPLETE_SOCKETIO_URL || "ws://localhost:7090"

var socket

export default function () {
    if (!socket)
    {
        socket = io.connect(socketioURL, {
            reconnectionDelayMax: 10000,
            // path: "/"
          });
    }

    function autocompleteReply(autocompleteReplyCallbackFunction) {
        socket.on("autocomplete-reply", autocompleteReplyCallbackFunction)
    }

    function autocompleteSearch(searchVariablesObj) {
        socket.emit("autocomplete-search", searchVariablesObj)
    }

    function searchProviders(searchVariablesObj) {
        socket.emit("get-providers", searchVariablesObj)
    }

    function getProviders(getProvidersCallbackFunction) {
        socket.on("receive-providers", getProvidersCallbackFunction)
    }

    function searchClinics(searchVariablesObj) {
        socket.emit("get-clinics", searchVariablesObj)
    }

    function getClinics(getClinicsCallbackFunction) {
        socket.on("receive-clinics", getClinicsCallbackFunction)
    }

    function saveClinicProfilePicture(data){
        socket.emit("save-clinic-profile-picture", data)
    }

    function disconnect() {
        socket.disconnect()
    }

    return {
        socket,
        autocompleteReply,
        autocompleteSearch,
        searchProviders,
        getProviders,
        searchClinics,
        getClinics,
        saveClinicProfilePicture,
        disconnect,
    }
}

