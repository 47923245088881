import React, { useState, useEffect, useRef } from 'react';
// rxjs
import { fromEvent, asyncScheduler } from "rxjs";
import { scan, throttleTime, } from "rxjs/operators";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from "@material-ui/core/Tooltip";
import CardActionArea from '@material-ui/core/CardActionArea';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from "@material-ui/core/Link";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import ChatIcon from "@material-ui/icons/ChatBubble";

import SearchBar from '../../ProviderSearch/SearchBar';
import TelemedicineItem from '../../ProviderSearch/TelemedicineItem';
import InpersonItem from '../../ProviderSearch/InpersonItem';
import SponsoredItem from '../../ProviderSearch/SponsoredItem';
import Header from "../../blocks/Common/Header";
import FooterCopyright from "../../blocks/Common/FooterCopyright";

import { MapContainer, TileLayer, Marker, Circle, Popup, useMap, Tooltip as LeafletToolTip } from 'react-leaflet';

import { SERVE_STATIC_PROFILE_ENDPOINT, SPONSORED_PROVIDER, ROOT_URL } from "../../../Constants";
import ProviderSearchAPI from '../../../services/provider_search/ProviderSearchAPI';
import getChatClient from "../../../services/chatManager";
import RenderStatus from "../../status/RenderStatus";
import ChatDialog from "../../blocks/ChatDialog.jsx";
import { createChatEncounter } from "../../../services/encounter/EncounterRequests";
import getClientUUID from "../../../utils/ClientUUID";
import InfoForm from "../../blocks/Queue/ClinicQueueForm";
import { createNetworkClient } from "../../../services/network_client/NetworkClientAPI";
import * as L from "leaflet"
import "./styles.css"
import MarkerClusterGroup from 'react-leaflet-markercluster';
import queueAPI from "../../../services/queue/queueAPI";
import AutocompleteManager from '../../../services/autocomplete_providers/AutocompleteManager';
import stateCodes from "us-state-codes"
import ProviderProfileAPI from '../../../services/provider_profile/ProviderProfileAPI';

// lodash 
const _map = require("lodash/map");
const _get = require("lodash/get");
const _capitalize = require("lodash/capitalize");
const _isEmpty = require("lodash/isEmpty");
const _toLower = require("lodash/toLower");
const _startCase = require("lodash/startCase");
const _sortBy = require("lodash/sortBy");
const _isArray = require("lodash/isArray");
const _slice = require("lodash/slice");
const _size = require("lodash/size");
const _uniq = require("lodash/uniq");
const _includes = require("lodash/includes");
const _concat = require("lodash/concat");
const _toString = require("lodash/toString");
const _remove = require("lodash/remove");
const _set = require("lodash/set");
const _keys = require("lodash/keys");
const _first = require("lodash/first");
const _filter = require("lodash/filter");
const _lowerCase = require('lodash/lowerCase');
const _replace = require("lodash/replace");
const _isNull = require("lodash/isNull");
const _kebabCase = require("lodash/kebabCase");
const _find = require("lodash/find");
const _forEach = require("lodash/forEach");
const _findIndex = require("lodash/findIndex");
const _toNumber = require("lodash/toNumber")
const _mapKeys = require("lodash/mapKeys")
const _isUndefined = require("lodash/isUndefined")
const _split = require("lodash/split");
const _has = require("lodash/has");
const { v4: uuidv4 } = require('uuid');


const encounter_npi = new Map();

const useStyles = makeStyles((theme) => ({
    gridContainer: {

    },
    searchBarContainer: {
        border: '1px solid black',
        backgroundColor: '#E6E6E6',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(4),
        display: 'flex',
    },
    toggleBtn: {
        padding: theme.spacing(1),
        fontSize: '9px !important',
        fontFamily: "'Noto Sans HK'",
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#2C9BE5',
            color: 'white'
        },
    },
    noTextStyle: {
        textTransform: "none",
    },
    mt: {
        marginTop: theme.spacing(1),
    },
    formControl: {
        marginLeft: 'auto',
        marginRight: theme.spacing(1),
        // minWidth: 240,
    },
    banner: {
        border: '1px solid #CCCCCC',
        padding: theme.spacing(2),
    },
    btnContainer: {
        marginLeft: theme.spacing(1),

    },
    banner2: {
        // border: '1px solid #CCCCCC',
        borderTop: 'none',
    },
    aptText: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontFamily: "'Noto Sans HK'",
    },
    item: {
        // borderLeft: '1px solid #CCCCCC',
        // borderRight: '1px solid #CCCCCC',
    },
    itemNum: {
        fontFamily: "'Noto Sans HK'",
        fontWeight: 'normal',
        textAlign: 'center',
    },
    sticky: {
        position: 'sticky !important',
        top: 0,
        zIndex: 1000,
    },
    requestBtn: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(8)
    },
    map: {
        height: '100%',
        maxHeight: '100vh',
        width: '100%'
    },
    mapSm: {
        height: '250px',
        width: '100%'
    },
    showMoreContainer: {
        display: 'flex',
        borderTop: 'none',
        margin: 'auto',
    }
}));

var isComponentMounted = false;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// bounce map back to the defined center
function ChangeView({ center, zoom }) {
    const map = useMap();
    if (map.getBounds().length == 0)
    {
        return
    }
    map.setView(center, zoom);
    map.setMaxBounds(map.getBounds())
    return null;
}

function FitBounds({bounds}) {
    
    const map = useMap()
    if (bounds.length == 0)
    {
        return
    }
    // console.log("Bounds: ", JSON.stringify(bounds))
    const newBounds = L.latLngBounds(bounds)
    map.fitBounds(newBounds, {padding: [7, 7]})
    return null
}

const NUM_RECORDS = 10; // # of records to display


const LANDING_PAGE_IMAGE_URL = "https://storage.googleapis.com/oneclinic/assets/bg/LandingPageImage1.png"
export default function ProviderSearchLandingPage(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const [param, setParam] = useState({})

    const [specialityList, setSpecialityList] = useState([]);

    const telemedicineOffset = useRef(0);
    const inPersonOffset = useRef(0);

    // const telemedicineTotal = useRef(0);
    // const inPersonTotal = useRef(0);
    const [telemedicineTotalState, setTelemedicineTotalState] = useState(0)
    const [inPersonTotalState, setInPersonTotalState] = useState(0)

    const [telemedicinePage, setTelemedicinePage] = useState(1);
    const [inPersonPage, setInPersonPage] = useState(1);

    const [telemedicineProviders, setTelemedicineProviders] = useState([]);
    const [inPersonProviders, setInPersonProviders] = useState([]);

    const [center, setCenter] = useState({ "lat": 41.15049955262252, "lon": -98.52832837980993 });
    // const [telemedicineCenters, setTelemedicineCenters] = useState([]);
    // const [inPersonCenters, setInPersonCenters] = useState([]);
    const telemedicineCenters = useRef([]);
    const inPersonCenters = useRef([]);

    const [isLoading, setIsLoading] = useState(true);

    const [isTelemedicine, setIsTelemedicine] = useState(false);

    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [openSnackbar, setSnackbarOpen] = useState(false);


    const [onlineNetworks, setOnlineNetworks] = useState([])
    const [showChatDialog, setShowChatDialog] = useState(false);
    const [randomUserID, setRandomUserID] = useState(null);
    const [chatEncounterUUID, setChatEncounterUUID] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [showMessageChange, setShowMessageChange] = useState(false);
    const [queues, setQueues] = useState({})
    const [selectedChatProvider, setSelectedChatProvider] = useState(null);
    const [providersChatData, setProvidersChatData] = useState(null);
    const [selectedQueueProvider, setSelectedQueueProvider] = useState(null);
    const [showQueueForm, setShowQueueForm] = useState(false);
    const [isUserInQueue, setIsUserInQueue] = useState(false);
    const [networkClient, setNetworkClient] = useState(null);
    const [showChatForm, setShowChatForm] = useState(false);
    const [isInfoFormSubmitted, setIsInfoFormSubmitted] = useState(false);
    const [queueUUID, setQueueUUID] = useState(null);
    const [typeFilter, setTypeFilter] = useState("All")
    const [isClinic, setIsClinic] = useState(false)

    // OZ: searchParams is used to kind of cache the params being received by the onClickSearch() function
    // This has been added to handle the case in which toggling the Providers/Clinics switch should perform a search 
    const [searchParams, setSearchParams] = useState(null);

    const previousChatHistory = usePrevious(chatHistory);
    const previousShowChatDialog = usePrevious(showChatDialog);
    const previousChatEncounterUUID = usePrevious(chatEncounterUUID);
    const previousRandomUserID = usePrevious(randomUserID)
    const previousOnlineNetworks = usePrevious(onlineNetworks)
    const previousTelemedicineProviders = usePrevious(telemedicineProviders);
    const previousQueues = usePrevious(queues);
    const previousProvidersChatData = usePrevious(providersChatData);
    const previousSelectedChatProvider = usePrevious(selectedChatProvider);
    // const previousProviderData_ = usePrevious(providerData);
    // const previousIsNetworkOnline = usePrevious(isNetworkOnline);

    // console.log("Is network online?", isNetworkOnline);
    // console.log('Online?', isProviderOnline, 'Available?', state.providerAvailable)
    // console.log("Online Networks", onlineNetworks);
    // console.log("Queues", queues);
    // console.log('Chat provider', selectedChatProvider);
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref;
    }
    const processNewMessage = (newMessage) => {
        if (isComponentMounted) {
            const encounter_uuid = _get(newMessage, 'chatroomName');
            const npi = encounter_npi.get(encounter_uuid);
            let showMessageChange = false;
            if (!_includes(_get(newMessage, 'user.name'), 'SYSTEM')) {
                ;
                // play sound on new message only if chat dialog is not open
                if (!_isEmpty(_get(newMessage, 'message')) && !previousShowChatDialog.current) {
                    const messageSound = _get(window, 'sounds.messageSound')
                    if (!_isEmpty(messageSound)) {
                        messageSound.play();
                    }
                    showMessageChange = true;
                }
                if (npi) {
                    const _providersChatData = {
                        ...previousProvidersChatData.current,
                        [npi]: {
                            ..._get(previousProvidersChatData.current, npi),
                            chatHistory: _concat(_get(previousProvidersChatData.current, `${npi}.chatHistory`, []), newMessage),
                            showMessageChange,
                        }
                    }
                    setProvidersChatData(_providersChatData)
                }


            }
        }
    }

    const processChatHistory = (_chatHistory) => {
        if (isComponentMounted) {
            const encounter_uuid = _first(_keys(_chatHistory));
            const npi = encounter_npi.get(encounter_uuid);
            if (npi) {
                const _providersChatData = {
                    ...previousProvidersChatData.current,
                    [npi]: {
                        ..._get(previousProvidersChatData.current, npi),
                        chatHistory: _get(_chatHistory, `${encounter_uuid}`),
                    }
                }
                setProvidersChatData(_providersChatData);
            }
        }
    }

    const processReconnection = (num_attempts) => {
        if (isComponentMounted) {
            const _chatClient = getChatClient();
            _chatClient.join(previousChatEncounterUUID.current, 'User' + `${previousChatEncounterUUID.current}`);
            _chatClient.requestChatHistory(previousChatEncounterUUID.current);
            const network_ids = _uniq(_map(previousTelemedicineProviders.current, (provider) => provider.network_id));
            _chatClient.subscribePresence([...network_ids, _get(SPONSORED_PROVIDER, 'network_id')]);

            // re-subscribe to queues
            _forEach(previousTelemedicineProviders.current, (p)=>{
                const network_id = _get(p, 'network_id');
                const clinic_id = _get(p, 'npi');
                _chatClient.subscribeQueue(network_id, clinic_id);
            })

        }
    }

    const processFinalizeEncounter = (data) => {
        if (isComponentMounted) {
            if (_get(data, 'chatroomName') == previousChatEncounterUUID.current) {
                //TODO: Use if required
                // this.setState({
                //     isEncounterFinalized: _get(data, 'finalized'),
                // })
            }
        }
    }

    const updateOnlinePresence = (data) => {
        // console.log("---Online Presence Data---");
        // console.log(data);
        const id = _get(data, 'id');
        const isOnline = _get(data, 'online');
        if (isOnline) {
            setOnlineNetworks(_uniq([...previousOnlineNetworks.current, id]))
        } else {
            // console.log(previousOnlineNetworks.current)
            const currentOnlineNetworks = [...previousOnlineNetworks.current];
            _remove(currentOnlineNetworks, (network_id) => network_id == id)
            setOnlineNetworks(_uniq(currentOnlineNetworks))
        }

    }
    const updateQueue = (queueObj) => {
        // console.log('Queue', queueObj);
        const queue_id = _get(queueObj, 'id');
        const count = _get(queueObj, 'count') - _get(queueObj, 'examRoomCount') -_get(queueObj, 'finalizedCount');
        const users = _filter(_get(queueObj, 'users'), (u) => (u.status != 'FINALIZED' && u.status != 'EXAM_ROOM'));
        if (queue_id) {
            const _queues = {
                ...previousQueues.current,
            }
            _set(_queues, queue_id, {count, users});
            setQueues(_queues);
        }


    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const unregisterCallbacks = () => {
        // console.log('Unregister callbacks');
        const _chatClient = getChatClient();
        if (_chatClient) {
            _chatClient.unsetOnMessageCallback(processNewMessage);
            _chatClient.unsetOnChatHisotryCallback(processChatHistory);
            _chatClient.unsetOnFinalizeEncounterCallback(processFinalizeEncounter);
            _chatClient.unsetOnReconnectCallback(processReconnection);
            _chatClient.unsetOnOnlinePresenceCallback(updateOnlinePresence);
            _chatClient.unsetOnQueueUpdateCallback(updateQueue);
            // console.log('Finished unregistering callbacks')
        }
    }
    const isUserInAnyQueue = (uuid, queues)=>{
        const keys = _keys(queues);
        let _isUserInQueue = false;
        _forEach(keys, (k)=>{
            const users = _get(queues, `${k}.users`);
            // console.log('---Users---', users)
            const foundUser = _find(users, (user)=>user.uuid == uuid)
            if(!_isEmpty(foundUser)){
                _isUserInQueue = true;
                // console.log("---User In Queue---", foundUser);
                // this is just to break the _forEach loop
                return false;
            }
        })
        return _isUserInQueue;
    }
    useEffect(() => {
        isComponentMounted = true;
        const fetchSpecialityList = async () => {
            const data = await ProviderSearchAPI.fetchSpecialityList()
            if (!_isEmpty(data)) {
                const speciality_list = _map(data, (item) => _get(item, "provider_specialization"))
                setSpecialityList(speciality_list)
            }
        }

        fetchSpecialityList();
        setIsLoading(false);
        const _chatClient = getChatClient();
        _chatClient.setOnMessageCallback(processNewMessage);
        _chatClient.setOnChatHisotryCallback(processChatHistory);
        _chatClient.setOnReconnectCallback(processReconnection);
        _chatClient.setOnFinalizeEncounterCallback(processFinalizeEncounter);
        _chatClient.setOnOnlinePresenceCallback(updateOnlinePresence);
        _chatClient.setOnQueueUpdateCallback(updateQueue);

        // setup rxjs stream
        const queue$ = fromEvent(_chatClient.socket, 'queue-update').pipe(
            scan((acc, data) => {
                let users = _get(data, 'users');
                users = _filter(users, (u) => (u.status != 'EXAM_ROOM' && u.status != 'FINALIZED'))
                const count = _size(users);
                let _queue = {
                    count,
                    users,
                };
                let key = '';
                if (data.clinic_id) {
                    key = `${data.id}_${data.clinic_id}`
                } else {
                    key = `${data.id}`
                }
                _set(acc, key, _queue);
                // console.log(key);
                return acc;
            }, {}),
            throttleTime(500, asyncScheduler, { leading: true, trailing: true }),
        );

        // subscribe to rxjs stream
        const queueSub = queue$.subscribe((data) => {
            // console.log(data);
            // console.log(_size(_keys(data)))
            setQueues((prevQueues) => {
                const _queues = {
                    ...prevQueues,
                    ...data,
                }
                // console.log(JSON.stringify(_queues, null, 1))
                return _queues;
            })
        });

        _chatClient.subscribePresence([_get(SPONSORED_PROVIDER, 'network_id')])
        // subscribe queue for sponsored provider
        _chatClient.subscribeQueue(_get(SPONSORED_PROVIDER, 'network_id'), _get(SPONSORED_PROVIDER, 'npi'));

        return () => {
            unregisterCallbacks();
            // unsubscribe from rxjs stream
            queueSub.unsubscribe();
            isComponentMounted = false;

        }
    }, [])

    const getProvidersCallback = (suggestions) => {
        const profiles = _map(_get(suggestions, "data"), (suggestion) => suggestion)
        // let telemedicineObj = []

        // let telemedicineData = _get(telemedicineObj, "data", [])

        // Remap the keys so that the UI renders with the same properties

        let telemedicineData = _map(profiles, (profile) => {
            return _mapKeys(profile, (value, key) => {
                // console.log("Key: ", key)
                if (key == "lat")
                {
                    return "latitude"
                }

                if (key == "lon")
                {
                    return "longitude"
                }

                if (key == "address")
                {
                    return "provider_first_line_business_practice_location_address"
                }

                if (key == "phone_number")
                {
                    return "formatted_phone_number"
                }

                if (key == "city")
                {
                    return "provider_business_practice_location_address_city_name"
                }

                if (key == "state")
                {
                    return "provider_business_practice_location_address_state_name"
                }

                if (key == "specialty")
                {
                    return "provider_specialization"
                }

                if (key == "distance")
                {
                    return "dist"
                }
                
                return key
            })
        })

        // console.log("New Profiles: ", telemedicineData)

        // console.log("Telemedicine Obj: ", telemedicineObj)

        // // inserting dummy network_ids and dummy user_profile_ids
        // telemedicineData = _map(telemedicineData, (data, index)=>{
        //     let _data = data;
        //     if(index < 5){
        //         _data = {
        //             ..._data,
        //             network_id: 897766, //demo1.provider.osama's network on localhost
        //             user_profile_id: 1,
        //             npi: 123456 + index,
        //         }
        //     } 
        //     else {
        //         _data = {
        //             ..._data,
        //             network_id: 805647, // demo16.provider's network on localhost
        //             user_profile_id: 116,
        //             npi: 123456 + index,
        //         }
        //     }
        //     return _data;
        // })
        if (!_isEmpty(telemedicineData)) {
            setTelemedicineProviders(prevVal => ([...prevVal, ...telemedicineData]));
            // setTelemedicineCenters([{
            //     lat: _get(telemedicineObj, "coords.latitude", 0),
            //     lon: _get(telemedicineObj, "coords.longitude", 0),
            // }])
            // telemedicineCenters.current = [{
            //     // lat: _get(telemedicineObj, "coords.latitude", 0),
            //     // lon: _get(telemedicineObj, "coords.longitude", 0),
            //     lat: 0,
            //     lon: 0
            // }]
            telemedicineCenters.current = [{ "lat": 41.15049955262252, "lon": -98.52832837980993 }]
            // telemedicineTotal.current = _get(suggestions, "total_count", 0)
            setTelemedicineTotalState(_get(suggestions, "total_count", 0))
            if (telemedicineOffset.current > 0)
            {
                setTelemedicinePage(prevVal => prevVal + NUM_RECORDS)
            }
            // setTelemedicineCenters([{ "lat": 41.15049955262252, "lon": -98.52832837980993 }])
        } else {
            setTelemedicineProviders([])
        }

        setIsLoading(false)

    }

    const getClinicsCallback = (suggestions) => {
        const profiles = _map(_get(suggestions, "data"), (suggestion) => suggestion)
        // let telemedicineObj = []

        // let telemedicineData = _get(telemedicineObj, "data", [])

        // Remap the keys so that the UI renders with the same properties

        let clinicsData = _map(profiles, (profile) => {
            return _mapKeys(profile, (value, key) => {
                // console.log("Key: ", key)

                if (key == "organization_name")
                {
                    return "provider_organization_name_legal_business_name"
                }

                if (key == "lat")
                {
                    return "latitude"
                }

                if (key == "lon")
                {
                    return "longitude"
                }

                if (key == "address")
                {
                    return "provider_first_line_business_practice_location_address"
                }

                if (key == "phone_number")
                {
                    return "formatted_phone_number"
                }

                if (key == "city")
                {
                    return "provider_business_practice_location_address_city_name"
                }

                if (key == "state")
                {
                    return "provider_business_practice_location_address_state_name"
                }

                if (key == "specialty")
                {
                    return "provider_specialization"
                }

                if (key == "distance")
                {
                    return "dist"
                }
                
                return key
            })
        })

        // console.log("Clinics Data:", clinicsData)

        // console.log("New Profiles: ", telemedicineData)

        // console.log("Telemedicine Obj: ", telemedicineObj)

        // // inserting dummy network_ids and dummy user_profile_ids
        // telemedicineData = _map(telemedicineData, (data, index)=>{
        //     let _data = data;
        //     if(index < 5){
        //         _data = {
        //             ..._data,
        //             network_id: 897766, //demo1.provider.osama's network on localhost
        //             user_profile_id: 1,
        //             npi: 123456 + index,
        //         }
        //     } 
        //     else {
        //         _data = {
        //             ..._data,
        //             network_id: 805647, // demo16.provider's network on localhost
        //             user_profile_id: 116,
        //             npi: 123456 + index,
        //         }
        //     }
        //     return _data;
        // })
        if (!_isEmpty(clinicsData)) {
            setInPersonProviders(prevVal => ([...prevVal, ...clinicsData]));
            // setTelemedicineCenters([{
            //     lat: _get(telemedicineObj, "coords.latitude", 0),
            //     lon: _get(telemedicineObj, "coords.longitude", 0),
            // }])
            // telemedicineCenters.current = [{
            //     // lat: _get(telemedicineObj, "coords.latitude", 0),
            //     // lon: _get(telemedicineObj, "coords.longitude", 0),
            //     lat: 0,
            //     lon: 0
            // }]
            inPersonCenters.current = [{ "lat": 41.15049955262252, "lon": -98.52832837980993 }]
            // telemedicineTotal.current = _get(suggestions, "total_count", 0)
            // setTelemedicineTotalState(_get(suggestions, "total_count", 0))
            // inPersonTotal.current = _get(suggestions, "total_count", 0)
            setInPersonTotalState(_get(suggestions, "total_count", 0))
            if (inPersonOffset.current > 0)
            {
                setInPersonPage(prevVal => prevVal + NUM_RECORDS)
            }
            // setTelemedicineCenters([{ "lat": 41.15049955262252, "lon": -98.52832837980993 }])
        } else {
            setInPersonProviders([])
        }

        setIsLoading(false);



    }

    // console.log("telemedicine centers current: ", JSON.stringify(telemedicineCenters))
    // console.log("Telemedicine Providers: ", telemedicineProviders)
    

    useEffect(() => {
        const manager = AutocompleteManager()
        manager.getProviders(getProvidersCallback)
        manager.getClinics(getClinicsCallback)
    }, [])

    useEffect(() => {
        const chatClient = getChatClient();
        const network_ids = _uniq(_map(telemedicineProviders, (provider) => provider.network_id));
        chatClient.subscribePresence(network_ids);
        // subscribe queue for telemedicine providers
        _forEach(telemedicineProviders, (p)=>{
            const network_id = _get(p, 'network_id');
            const clinic_id = _get(p, 'npi');
            chatClient.subscribeQueue(network_id, clinic_id);
        })
    }, [telemedicineProviders])

    useEffect(() => {
        const chatClient = getChatClient();
        const network_ids = _uniq(_map(inPersonProviders, (provider) => provider.network_id));
        chatClient.subscribePresence(network_ids)
        // subscribe queue for telemedicine providers
        _forEach(inPersonProviders, (p)=>{
            const network_id = _get(p, 'network_id');
            const clinic_id = _get(p, 'npi');
            chatClient.subscribeQueue(network_id, clinic_id);
        })
    }, [inPersonProviders])

    // function createClusterIcon(cluster) {
    //     // var childCount = cluster.getChildCount();
    //     var c = ' marker-cluster-small';

    //     return new L.DivIcon({ html: '<div><span>' + '&#10021;' + '</span></div>', 
    //         className: 'marker-cluster' + c, iconSize: new L.Point(40, 40) });
    // }

    function createClusterIcon(cluster) {
        var childCount = cluster.getChildCount();
        // var c = ' marker-cluster-small';

        return new L.DivIcon({ className: "custom-cluster-icon",
        html: `<div class="cluster-pin"></div><i>${childCount}+</i>`,
        iconSize: [36, 46],
        iconAnchor: [18, 46] });
    }

    const updateMapCenter = () => {
        // console.log(telemedicineCenters.current, telemedicineOffset.current)
        // console.log(inPersonCenters.current, inPersonOffset.current)
        if (isTelemedicine) {
            setCenter(_get(telemedicineCenters, `${parseInt(telemedicineOffset.current / NUM_RECORDS)}`, { "lat": 41.15049955262252, "lon": -98.52832837980993 }))
        } else if (isClinic) {
            setCenter(_get(inPersonCenters.current, `${parseInt(inPersonOffset.current / NUM_RECORDS)}`, { "lat": 41.15049955262252, "lon": -98.52832837980993 }))
        } else {
            // TODO: Think about the case when "All" is selected
        }
    }

    const handleTypeFilterChange = (e, val) => {
        console.log(val)
        setTypeFilter(val)

        if (val == "Doctors"){
            setIsTelemedicine(true);
        } else {
            setIsTelemedicine(false);
        }

        if(val == "Clinics"){
            setIsClinic(true);
        } else {
            setIsClinic(false)
        }

        if(val == "Doctors" || val == "Clinics"){
            updateMapCenter()
        }


    };

    const clearSearch = ()=>{
        setTelemedicineTotalState(0)
        setInPersonTotalState(0)
        setTelemedicinePage(1)
        setInPersonPage(1)
        setTelemedicineProviders([])
        setInPersonProviders([])
    }

    const onClickSearch = async (params) => {
        const queryParams = new URLSearchParams()
        if(params?.location){
            queryParams.set("location", params.location)
        }
        if(params?.speciality){
            queryParams.set("specialty", params.speciality)
        }
        if(params?.searchString){
            queryParams.set("name", params.searchString)
        }
        if(params?.geoLocation && params?.geoLocation?.state){
            queryParams.set("state", params.geoLocation.state)
        }
        if(params?.geoLocation && params?.geoLocation?.lat){
            queryParams.set("lat", params.geoLocation.lat)
        }
        if(params?.geoLocation && params?.geoLocation?.lon){
            queryParams.set("lon", params.geoLocation.lon)
        }

        const queryString = queryParams.toString()
        console.log("Searching...")
        window.open(`/search${queryString?`?${queryString}`:""}`, "_self")
        // if(params){
        //     setSearchParams(params)
        // } else {
        //     params = searchParams
        // }

        // if(!params){
        //     return;
        // }
        // setIsLoading(true);

        // // resetting variables
        // telemedicineOffset.current = 0
        // inPersonOffset.current = 0
        // // telemedicineTotal.current = 0
        // setTelemedicineTotalState(0)
        // // inPersonTotal.current = 0
        // setInPersonTotalState(0)
        // setTelemedicinePage(1)
        // setInPersonPage(1)
        // setTelemedicineProviders([])
        // setInPersonProviders([])

        // // storing params
        // setParam(params);

        // // fetching center of map
        // // const mapCenter = await ProviderSearchAPI.getMapCenter({
        // //     zipcode: params.location,
        // // })
        // // setCenter({
        // //     lat: _get(mapCenter, "latitude", 0),
        // //     lon: _get(mapCenter, "longitude", 0),
        // // })

        // // fetching telemedicine providers
        // // let telemedicineObj = await ProviderSearchAPI.getProviderDataByOffset({
        // //     location: params.location,
        // //     providerType: params.speciality,
        // //     entityType: 1,
        // //     offset: telemedicineOffset.current,
        // // });

        // // let telemedicineData = _get(telemedicineObj, "data", [])

        // // console.log("Telemedicine Data: ", telemedicineData)

        // const stateCode = stateCodes.getStateCodeByStateName(_get(params, "geoLocation.state"))
        // // console.log("Params: ", params)

        // const manager = AutocompleteManager()
        // if(isTelemedicine){

        //     if (_get(params, "exactSearch"))
        //     {
        //         manager.searchProviders({npi: _get(params, "npi")})
        //     }
        //     else
        //     {
        //         manager.searchProviders({...params?.geoLocation, specialization: [params?.speciality], offset: telemedicineOffset.current, limit: 10, radius: 50, state: stateCode, q: _get(params, "searchString")} )
        //     }
        // } else if (isClinic) {

        //     manager.searchClinics({...params?.geoLocation, specialization: [params?.speciality], offset: telemedicineOffset.current, limit: 10, radius: 50, state: stateCode, q: _get(params, "searchString")} )
        // } else {
        //     manager.searchClinics({...params?.geoLocation, specialization: [params?.speciality], offset: telemedicineOffset.current, limit: 10, radius: 50, state: stateCode, q: _get(params, "searchString")} )
        //     if (_get(params, "exactSearch"))
        //     {
        //         manager.searchProviders({npi: _get(params, "npi")})
        //     }
        //     else
        //     {
        //         manager.searchProviders({...params?.geoLocation, specialization: [params?.speciality], offset: telemedicineOffset.current, limit: 10, radius: 50, state: stateCode, q: _get(params, "searchString")} )
        //     }
        // }
        
        // // console.log("Telemedicine Obj: ", telemedicineObj)

        // // // inserting dummy network_ids and dummy user_profile_ids
        // // telemedicineData = _map(telemedicineData, (data, index)=>{
        // //     let _data = data;
        // //     if(index < 5){
        // //         _data = {
        // //             ..._data,
        // //             network_id: 897766, //demo1.provider.osama's network on localhost
        // //             user_profile_id: 1,
        // //             npi: 123456 + index,
        // //         }
        // //     } 
        // //     else {
        // //         _data = {
        // //             ..._data,
        // //             network_id: 805647, // demo16.provider's network on localhost
        // //             user_profile_id: 116,
        // //             npi: 123456 + index,
        // //         }
        // //     }
        // //     return _data;
        // // })
        // // if (!_isEmpty(telemedicineData)) {
        // //     setTelemedicineProviders(telemedicineData);
        // //     // setTelemedicineCenters([{
        // //     //     lat: _get(telemedicineObj, "coords.latitude", 0),
        // //     //     lon: _get(telemedicineObj, "coords.longitude", 0),
        // //     // }])
        // //     telemedicineCenters.current = [{
        // //         lat: _get(telemedicineObj, "coords.latitude", 0),
        // //         lon: _get(telemedicineObj, "coords.longitude", 0),
        // //     }]
        // //     telemedicineTotal.current = _get(telemedicineData, "0.total_count")
        // // } else {
        // //     setTelemedicineProviders([])
        // // }

        // // fetching in-person providers
        // // console.log("Location: ", params.state)
        // // const location = _isUndefined(_get(params, "geoLocation.lat")) && !_isUndefined(_get(params, "geoLocation.state")) ? [_get(params, "geoLocation.state")] : location
        // // let inPersonObj = await ProviderSearchAPI.getProviderDataByOffset({
        // //     // location: params.location,
        // //     location,
        // //     providerType: params.speciality,
        // //     entityType: 2,
        // //     offset: inPersonOffset.current,
        // // });

        // // let inPersonData = _get(inPersonObj, "data", [])


        // // // // inserting dummy network_ids and dummy user_profile_ids
        // // // inPersonData = _map(inPersonData, (data, index)=>{
        // // //     let _data = data;
        // // //     if(index < 5){
        // // //         _data = {
        // // //             ..._data,
        // // //             network_id: 897766, //demo1.provider.osama's network on localhost
        // // //             user_profile_id: 1,
        // // //         }
        // // //     } else {
        // // //         _data = {
        // // //             ..._data,
        // // //             network_id: 805647, // demo16.provider's network on localhost
        // // //             user_profile_id: 116,
        // // //         }
        // // //     }
        // // //     return _data;
        // // // })

        // // if (!_isEmpty(inPersonData)) {
        // //     setInPersonProviders(inPersonData)
        // //     // setInPersonCenters([{
        // //     //     lat: _get(inPersonObj, "coords.latitude", 0),
        // //     //     lon: _get(inPersonObj, "coords.longitude", 0),
        // //     // }])
        // //     inPersonCenters.current = [{
        // //         lat: _get(inPersonObj, "coords.latitude", 0),
        // //         lon: _get(inPersonObj, "coords.longitude", 0),
        // //     }]
        // //     inPersonTotal.current = _get(inPersonData, "0.total_count")
        // // } else {
        // //     setInPersonProviders([])
        // // }

        // // update map center 
        // updateMapCenter()

        // // snackbar handling 
        // // if (_isEmpty(telemedicineData) && _isEmpty(inPersonData)) {
        // //     setSnackbarMsg("Unable to fetch related Providers!")
        // //     setSnackbarOpen(true)
        // // } else if (isTelemedicine && _isEmpty(telemedicineData)) {
        // //     setSnackbarMsg("Unable to fetch Telemedicine Providers!")
        // //     setSnackbarOpen(true)
        // // } else if (!isTelemedicine && _isEmpty(inPersonData)) {
        // //     setSnackbarMsg("Unable to fetch In-Person Providers!")
        // //     setSnackbarOpen(true)
        // // }

        // // setIsLoading(false);
    }

    // useEffect(()=>{
    //     // console.log("isTelemedicine has toggled!")
    //     // OZ: Whenever isTelemedicine is set, re-run the search if providers are empty
    //     if(isTelemedicine && _isEmpty(telemedicineProviders)){
    //         onClickSearch()
    //     }
    // }, [isTelemedicine])

    // useEffect(()=>{
    //     // console.log("isClinic has toggled!")
    //     // OZ: Whenever isClinic is set, re-run the search if providers are empty
    //     if(isClinic && _isEmpty(inPersonProviders)){
    //         onClickSearch()
    //     }
    // }, [isClinic])

    // useEffect(()=>{
    //     // console.log("isClinic has toggled!")
    //     // OZ: Whenever isClinic is set, re-run the search if providers are empty
    //     if(typeFilter == "All" && (_isEmpty(inPersonProviders) || _isEmpty(telemedicineProviders))){
    //         onClickSearch()
    //     }
    // }, [typeFilter])

    const handleSort = (event) => {
        setIsLoading(true);
        if (event.target.value == "speciality") {
            setInPersonProviders(_sortBy(inPersonProviders, [(o) => _get(o, "provider_specialization")]))
            setTelemedicineProviders(_sortBy(telemedicineProviders, [(o) => _get(o, "provider_specialization")]))
        }
        setIsLoading(false);
    }

    const onClickTrackQueue = () => {
        const url = `${ROOT_URL}/queue/${queueUUID}`;
        window.open(url, '_blank');
    }

    const redirectToStaticPage = async (provider) => {
        setIsLoading(true)
        // console.log(provider)
        // const state = _kebabCase(_get(provider, 'provider_business_practice_location_address_state_name'))
        // const city = _kebabCase(_get(provider, 'provider_business_practice_location_address_city_name'))
        // let fullName = ''
        // // TODO: fullName logic has been done exactly as the hugo generation on backend. Should be improved in backend or only npi should be used in url
        // if(_get(provider, 'provider_first_name')){
        //     const credentials = _kebabCase(_replace(_toLower(_get(provider, "provider_credential_text", "")), /[^A-Za-z,\s]/g, ""))
        //     // console.log(credentials)
        //     if(credentials && _get(provider, 'provider_name_prefix_text')){
        //         if(credentials == 'md' || credentials == 'do'){
        //             fullName = _kebabCase(`dr ${_get(provider, 'provider_first_name', '')} ${_get(provider, 'provider_middle_name', '')} ${_get(provider, 'provider_last_name_legal_name','')} ${credentials}`)
        //         } else {
        //             fullName = _kebabCase(`${_get(provider, 'provider_name_prefix_text', '')} ${_get(provider, 'provider_first_name', '')} ${_get(provider, 'provider_middle_name', '')} ${_get(provider, 'provider_last_name_legal_name','')} ${credentials}`)
        //         }
        //     } else {
        //         fullName = _kebabCase(`${_get(provider, 'provider_name_prefix_text', '')} ${_get(provider, 'provider_first_name', '')} ${_get(provider, 'provider_middle_name', '')} ${_get(provider, 'provider_last_name_legal_name','')}`)
        //     }
        // } else {
        //     fullName = _kebabCase(_get(provider, "provider_organization_name_legal_business_name"))
        // }
        // const npi = _get(provider, 'npi');
        // const url = `${SERVE_STATIC_PROFILE_ENDPOINT}/${state}/${city}/${npi}/${fullName}/`;
        // console.log(url)

        // OZ: open new window instantly otherwise browser may block pop-up
        // Later, we will change the href of the window
        // window.open(url, '_blank');
        console.log("redirectToStaticPage() called")
        console.log(provider)
        if (_get(provider, "npi"))
        {
            const isOrganization = !_isEmpty(_get(provider, 'provider_organization_name_legal_business_name'))
            const winRef = window.open("", '_blank')
            winRef.document.write("This may take a few seconds. Loading...")
            const npi = _get(provider, 'npi')
            // OZ: forceRecreate if provider is an organization since previously statically generated profiles won't be valid for this case anymore.
            const response = await ProviderProfileAPI.generateAndGetProfile(npi, true)
            if (!_isEmpty(response) && !_isEmpty(_get(response, "webpagePath")))
            {
                const webpagePath = _get(response, "webpagePath")
                const [_, path] = _split(webpagePath, '/static_profiles')
                if(path){
                    const URL = SERVE_STATIC_PROFILE_ENDPOINT + path
                    winRef.location.href = URL;
                } else {
                    console.error('Path extracted from webpagePath is empty so failed to make url');
                    winRef.close();
                }

            }
        }

        setIsLoading(false)
        // console.log("Response: ", response)
    }


    const formatName = (prefix, name, credentials) => {
        const name_format = _startCase(_toLower(name))
        const prefix_format = _startCase(_toLower(prefix))
        const credentials_clean = _replace(credentials, /[^A-Za-z,\s]/g, "")

        if (!_isEmpty(credentials_clean) && !_isEmpty(prefix)) {

            if (credentials_clean == 'MD' || credentials_clean == 'DO') {
                return `Dr. ${name_format} (${credentials_clean})`
            }

            return `${prefix_format}. ${name_format} (${credentials_clean})`
        }

        /* only credential is missing */
        else if (!_isEmpty(prefix)) {
            return `${prefix_format}. ${name_format}`

        }

        /* both are missing */
        else {
            return `${name_format}`
        }
    }

    const loadNext = async () => {
        setIsLoading(true);
        if (isTelemedicine) {
            if ((_size(telemedicineProviders) < telemedicinePage + NUM_RECORDS) && ((telemedicinePage - 1) <= telemedicineOffset.current)) {
                // incrementing offset
                telemedicineOffset.current += NUM_RECORDS

                // fetching telemedicine providers
                // let telemedicineObj = await ProviderSearchAPI.getProviderDataByOffset({
                //     location: _get(param, "location"),
                //     providerType: _get(param, "speciality"),
                //     entityType: 1,
                //     offset: telemedicineOffset.current,
                // });
                // let telemedicineData = _get(telemedicineObj, "data", [])

                const stateCode = stateCodes.getStateCodeByStateName(_get(param, "geoLocation.state"))

                const manager = AutocompleteManager()
                manager.searchProviders({...param.geoLocation, specialization: [param.speciality], offset: telemedicineOffset.current, limit: 10, radius: 100, state: stateCode, q: _get(param, "searchString")} )


                // // inserting dummy network_ids and dummy user_profile_ids
                // telemedicineData = _map(telemedicineData, (data, index)=>{
                //     let _data = data;
                //     if(index < 5){
                //         _data = {
                //             ..._data,
                //             network_id: 897766, //demo1.provider.osama's network on localhost
                //             user_profile_id: 1,
                //         }
                //     } else {
                //         _data = {
                //             ..._data,
                //             network_id: 805647, // demo16.provider's network on localhost
                //             user_profile_id: 116,
                //         }
                //     }
                //     return _data;
                // })
                // if (_isArray(telemedicineData) && !_isEmpty(telemedicineData)) {
                //     setTelemedicineProviders(prevVal => [...prevVal, ...telemedicineData]);
                //     // setTelemedicineCenters(prevVal => [...prevVal, {
                //     //     lat: _get(telemedicineObj, "coords.latitude", 0),
                //     //     lon: _get(telemedicineObj, "coords.longitude", 0),
                //     // }]);
                //     telemedicineCenters.current = [...telemedicineCenters.current, {
                //         lat: _get(telemedicineObj, "coords.latitude", 0),
                //         lon: _get(telemedicineObj, "coords.longitude", 0),
                //     }]
                //     setTelemedicinePage(prevVal => prevVal + NUM_RECORDS)
                // } else {
                //     setSnackbarMsg("No more Telemedicine Providers to show!")
                // }
            } else {
                setTelemedicinePage(prevVal => prevVal + NUM_RECORDS)

            }
        }
        else if(isClinic)
        {
            if ((_size(inPersonProviders) < inPersonPage + NUM_RECORDS) && ((inPersonPage - 1) <= inPersonOffset.current))
            {
                inPersonOffset.current += NUM_RECORDS
                const stateCode = stateCodes.getStateCodeByStateName(_get(param, "geoLocation.state"))

                const manager = AutocompleteManager()
                manager.searchClinics({...param.geoLocation, specialization: [param.speciality], offset: inPersonOffset.current, limit: 10, radius: 100, state: stateCode, q: _get(param, "searchString")} )

            }
            else 
            {
                setInPersonPage(prevVal => prevVal + NUM_RECORDS)
            }
        }
        // else {
        //     if ((_size(inPersonProviders) < inPersonPage + NUM_RECORDS) && ((inPersonPage - 1) <= inPersonOffset.current)) {
        //         // incrementing offset
        //         inPersonOffset.current += NUM_RECORDS

        //         // fetching telemedicine providers
        //         const location = _isUndefined(_get(param, "geoLocation.lat")) && !_isUndefined(_get(param, "geoLocation.state")) ? [_get(param, "geoLocation.state")] : location
        //         let inPersonObj = await ProviderSearchAPI.getProviderDataByOffset({
        //             // location: _get(param, "location"),
        //             location,
        //             providerType: _get(param, "speciality"),
        //             entityType: 2,
        //             offset: inPersonOffset.current,
        //         });
        //         let inPersonData = _get(inPersonObj, "data", [])

        //         // // inserting dummy network_ids and dummy user_profile_ids
        //         // inPersonData = _map(inPersonData, (data, index) => {
        //         //     let _data = data;
        //         //     if (index < 5) {
        //         //         _data = {
        //         //             ..._data,
        //         //             network_id: 897766, //demo1.provider.osama's network on localhost
        //         //             user_profile_id: 1,
        //         //         }
        //         //     } else {
        //         //         _data = {
        //         //             ..._data,
        //         //             network_id: 805647, // demo16.provider's network on localhost
        //         //             user_profile_id: 116,
        //         //         }
        //         //     }
        //         //     return _data;
        //         // })

        //         if (_isArray(inPersonData) && !_isEmpty(inPersonData)) {
        //             setInPersonProviders(prevVal => [...prevVal, ...inPersonData]);
        //             // setInPersonCenters(prevVal => [...prevVal, {
        //             //     lat: _get(inPersonObj, "coords.latitude", 0),
        //             //     lon: _get(inPersonObj, "coords.longitude", 0),
        //             // }]);
        //             inPersonCenters.current = [...inPersonCenters.current, {
        //                 lat: _get(inPersonObj, "coords.latitude", 0),
        //                 lon: _get(inPersonObj, "coords.longitude", 0),
        //             }]
        //             setInPersonPage(prevVal => prevVal + NUM_RECORDS)
        //         } else {
        //             setSnackbarMsg("No more In-Person Providers to show!")
        //         }
        //     } else {
        //         setInPersonPage(prevVal => prevVal + NUM_RECORDS)
        //     }
        // }

        // update map center
        updateMapCenter()

        // scroll to top
        window.scrollTo(0, 0)

        setIsLoading(false);
    }

    const loadPrev = async () => {
        setIsLoading(true);
        if (isTelemedicine) {
            setTelemedicinePage(prevVal => prevVal - NUM_RECORDS)

            // force re-rendering providers
            setTelemedicineProviders(prevVal => prevVal)
        } else if(isClinic) {
            setInPersonPage(prevVal => prevVal - NUM_RECORDS)

            // force re-rendering providers
            setInPersonProviders(prevVal => prevVal)
        }

        // update map center
        updateMapCenter()

        // scroll to top
        window.scrollTo(0, 0)

        setIsLoading(false);
    }

    const renderMap = () => {
        let dataRows;
        if (isTelemedicine) {
            dataRows = _slice(telemedicineProviders, telemedicinePage - 1, telemedicinePage + NUM_RECORDS - 1)

        } else if(isClinic) {
            dataRows = _slice(inPersonProviders, inPersonPage - 1, inPersonPage + NUM_RECORDS - 1)
        } else {
            // OZ: In case of "All", pick first two clinics and first two telemedicine providers
            dataRows = [..._slice(inPersonProviders, 0, 2), ..._slice(telemedicineProviders, 0, 2)]
        }

        const row0 = _get(dataRows, "0")
        const mapCenter = (_isNull(center) || _isNull(_get(center, "lat"))) ?
            _get(row0, "latitude", 0) && _get(row0, "longitude", 0) ?
                {
                    "lat": _get(row0, "latitude", 41.15049955262252),
                    "lon": _get(row0, "longitude", -98.52832837980993)
                }
                :
                {
                    "lat": _get(row0, "zip_lat", 41.15049955262252),
                    "lon": _get(row0, "zip_lon", -98.52832837980993)
                }
            : center;

        const bounds = []

        for (let i = 0; i < dataRows.length; i++)
        {
            let lat, lon
            if (_get(dataRows, `${i}.latitude`) && _get(dataRows, `${i}.longitude`))
            {
                [lat, lon] = [_get(dataRows, `${i}.latitude`), _get(dataRows, `${i}.longitude`)]
            }
            else
            {
                [lat, lon] = [_get(dataRows, `${i}.zip_lat`, 41.15049955262252), _get(dataRows, `${i}.zip_lon`, -98.52832837980993)]
            }

            bounds.push([_toNumber(lat), _toNumber(lon)])
        }

        if (bounds.length == 0)
        {
            bounds.push([41.15049955262252, -98.52832837980993])
        }

        // console.log("Map centers: ", JSON.stringify(mapCenter))


        return (
            <MapContainer
                center={[_get(mapCenter, "lat", 41.15049955262252), _get(mapCenter, "lon", -98.52832837980993)]}
                zoom={11}
                key={`${parseInt(_get(mapCenter, "lat", 41.15049955262252) + _get(mapCenter, "lon", -98.52832837980993) + Math.random())}`}
                className={matches ? classes.mapSm : clsx(classes.sticky, classes.map)}
            >
                {/* {matches && <ChangeView center={[_get(mapCenter, "lat", 41.15049955262252), _get(mapCenter, "lon", -98.52832837980993)]} zoom={11} />} */}
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <FitBounds bounds={bounds} />

                {
                    (!_isEmpty(_get(center, "lat")) && !_isEmpty(_get(center, "lon"))) &&
                    <Marker
                        position={[_get(center, "lat"), _get(center, "lon")]}
                        key={-1}
                    >
                        <LeafletToolTip >
                            You are here
                        </LeafletToolTip>
                    </Marker>
                }

                <MarkerClusterGroup iconCreateFunction={createClusterIcon} key={new Date()} maxClusterRadius={25}>
                

                {
                    _map(dataRows, (row, key) => {
                        {

                            const marker_position = _get(row, "latitude") && _get(row, "longitude") ?
                                [_get(row, "latitude"), _get(row, "longitude")] :
                                [_get(row, "zip_lat"), _get(row, "zip_lon")]

                            const pageOffset = isTelemedicine ? telemedicinePage : inPersonPage

                            const divIcon = L.divIcon({
                                className: "custom-div-icon",
                                html: `<div class="marker-pin"></div><i>${key + pageOffset}</i>`,
                                iconSize: [36, 46],
                                iconAnchor: [18, 46]
                            })

                            return (
                                <Marker position={marker_position} key={key} icon={divIcon}>
                                    <Popup>
                                        <b> {
                                            _has(row, "first_name") ? 
                                                formatName(
                                                    _get(row, "display_prefix"),
                                                    `${_get(row, "first_name")} ${_get(row, "last_name")}`,
                                                    _get(row, "display_credential")
                                                )
                                                :
                                                _startCase(_toLower(_get(row, "provider_organization_name_legal_business_name")))
                                        } </b> < br />
                                        {_startCase(_toLower(_get(row, "provider_first_line_business_practice_location_address")))}, {_startCase(_toLower(_get(row, "provider_business_practice_location_address_city_name")))}
                                    </Popup>
                                </Marker>
                            )

                        }

                    })
                }
                {/* {
                    !(_isNull(center) || _isNull(_get(center, "lat"))) &&
                    <Circle
                        center={{ lat: _get(mapCenter, "lat", 41.15049955262252), lng: _get(mapCenter, "lon", -98.52832837980993) }}
                        fillColor="blue"
                        radius={3 * 1609}
                    />
                } */}

                </MarkerClusterGroup>

                

            </MapContainer>
        )
    }

    const renderTelemedicineItems = () => {

        const renderItems = _map(_slice(telemedicineProviders, telemedicinePage - 1, telemedicinePage + NUM_RECORDS - 1), (item, index) => {
            return (
                <Grid item container direction="row" key={index} style={{ borderBottom: '1px solid #CCCCCC' }}>
                    {/* <Grid item xs={1} style={{ margin: 'auto' }}>
                        <Typography variant="h6" className={classes.itemNum}>
                            {index + telemedicinePage}
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12} className={classes.item}>
                        <TelemedicineItem
                            address={
                                `${_startCase(_toLower(_get(item, "provider_first_line_business_practice_location_address")))}, ${_startCase(_toLower(_get(item, "provider_business_practice_location_address_city_name")))}, ${_get(item, "provider_business_practice_location_address_state_name")}`
                            }
                            prefix=''
                            name={`${_get(item, "display_name")}`}
                            credentials={_get(item, "provider_credential_text")}
                            speciality={_get(item, "provider_specialization")}
                            phone={_get(item, "formatted_phone_number")}
                            onCardClick={async() => {await redirectToStaticPage(item)}}
                            onChatClick={() => {
                                if (!randomUserID) {
                                    const _randomUserID = Math.floor(Math.random() * 899999) + 100000
                                    setRandomUserID(_randomUserID);
                                }
                                // setShowChatDialog(true)
                                setSelectedChatProvider(item)
                                const _providersChatData = {
                                    ...providersChatData,
                                    [item.npi]: {
                                        ..._get(providersChatData, `${item.npi}`),
                                        showMessageChange: false,
                                    }
                                }
                                setProvidersChatData(_providersChatData);
                                // if form is already submitted then open chat dialog
                                // otherwise open info form
                                if (isInfoFormSubmitted) {
                                    setShowChatDialog(true);
                                } else {
                                    setShowChatForm(true);
                                }
                            }}
                            onClickAddToQueue={() => {
                                setSelectedQueueProvider(item)
                                if (isInfoFormSubmitted) {
                                    const chatClient = getChatClient()
                                    const user = {
                                        npi: _get(item, 'npi'),
                                        uuid: uuidv4(),
                                        name: _get(networkClient, 'name'),
                                        email: _get(networkClient, 'email'),
                                        phone_number: _get(networkClient, 'phone_number'),
                                        provider_name: _startCase(_lowerCase(`${_get(item, "provider_first_name")} ${_get(item, "provider_last_name_legal_name")}`)),
                                        tag: 'DIRECT_NETWORK',
                                        channel: _get(item, 'network_id'),
                                        details: {
                                            provider: item
                                        },
                                        status: 'IN_QUEUE',
                                    }
                                    chatClient.addToQueue(_get(item, 'network_id'), _get(item, 'npi'), user);
                                    queueAPI.createQueueUser(user)
                                    setIsUserInQueue(true);
                                    setQueueUUID(_get(user, 'uuid'))
                                } else {
                                    setShowQueueForm(true)
                                }
                            }}
                            onClickRemoveFromQueue={() => {
                                const chatClient = getChatClient();
                                chatClient.removeFromQueue(_get(item, 'network_id'), _get(item, 'npi'), queueUUID)
                                setQueueUUID(null)
                                setIsUserInQueue(false);
                            }}
                            queueSize={_get(queues, `${item.network_id}_${item.npi}.count`, 0)}
                            queuePosition={_findIndex(_get(queues, `${item.network_id}_${item.npi}.users`, []), (user)=>user.uuid == queueUUID) + 1}
                            isUserInQueue={isUserInAnyQueue(queueUUID, queues)}
                            isUserInMyQueue={!_isEmpty(_find(_get(queues, `${item.network_id}_${item.npi}.users`, []), (user)=>(user.npi == _get(item, 'npi') && user.uuid == queueUUID)))}
                            isOnline={_includes(onlineNetworks, _toString(item.network_id))}
                            isNetworkPresent={!_isEmpty(_toString(_get(item, 'network_id')))}
                            showMessageChange={_get(providersChatData, `${item.npi}.showMessageChange`)}
                            showDistance={_get(item, "dist", false) == false ? false : true}
                            distance={_get(item, "dist")}
                            onClickTrackQueue={onClickTrackQueue}
                            showTrackQueueButton={false}
                            showChatButton={false}
                            showQueue={false}
                        />
                    </Grid>
                    {/* <Grid item xs={11} className={classes.item}>

                    </Grid> */}
                </Grid>

            )
        })

        if (!_isEmpty(renderItems)) {
            return (
                <>
                    {/* <Grid item container direction="row" style={{ borderBottom: '1px solid #CCCCCC' }}> */}
                        {/* <Grid item xs={1} style={{ margin: 'auto' }}>
                            <Typography variant="h6" className={classes.itemNum}>
                                &#9733;
                            </Typography>
                        </Grid> */}
                        {/* <Grid item xs={12} className={classes.item}>
                            <SponsoredItem
                                address={_get(SPONSORED_PROVIDER, "address")}
                                prefix={_get(SPONSORED_PROVIDER, "prefix")}
                                name={`${_get(SPONSORED_PROVIDER, 'firstName')} ${_get(SPONSORED_PROVIDER, "lastName")}`}
                                credentials={_get(SPONSORED_PROVIDER, 'credentials')}
                                speciality={_get(SPONSORED_PROVIDER, 'speciality')}
                                phone={_get(SPONSORED_PROVIDER, "phone")}
                                photo_url={_get(SPONSORED_PROVIDER, "photoURL")}
                                onCardClick={() => window.open(_get(SPONSORED_PROVIDER, "widgetURL"), '_blank')}
                                onChatClick={() => {
                                    if (!randomUserID) {
                                        const _randomUserID = Math.floor(Math.random() * 899999) + 100000
                                        setRandomUserID(_randomUserID);
                                    }
                                    setSelectedChatProvider({
                                        npi: _get(SPONSORED_PROVIDER, "npi"),
                                        network_id: _get(SPONSORED_PROVIDER, "network_id"),
                                        user_profile_id: _get(SPONSORED_PROVIDER, "user_profile_id"),
                                        provider_first_name: _get(SPONSORED_PROVIDER, "firstName"),
                                        provider_last_name_legal_name: _get(SPONSORED_PROVIDER, "lastName"),
                                    })
                                    const _providersChatData = {
                                        ...providersChatData,
                                        [_get(SPONSORED_PROVIDER, 'npi')]: {
                                            ..._get(providersChatData, `${_get(SPONSORED_PROVIDER, 'npi')}`),
                                            showMessageChange: false,
                                        }
                                    }
                                    setProvidersChatData(_providersChatData);
                                    if (isInfoFormSubmitted) {
                                        setShowChatDialog(true);
                                    } else {
                                        setShowChatForm(true);
                                    }
                                }}
                                onClickAddToQueue={() => {
                                    setSelectedQueueProvider({
                                        ...SPONSORED_PROVIDER,
                                        provider_first_name: _get(SPONSORED_PROVIDER, "firstName"),
                                        provider_last_name_legal_name: _get(SPONSORED_PROVIDER, "lastName"),
                                    })
                                    if (isInfoFormSubmitted) {
                                        const chatClient = getChatClient()
                                        const user = {
                                            npi: _get(SPONSORED_PROVIDER, "npi"),
                                            uuid: uuidv4(),
                                            name: _get(networkClient, 'name'),
                                            email: _get(networkClient, 'email'),
                                            phone_number: _get(networkClient, 'phone_number'),
                                            provider_name: `${_get(SPONSORED_PROVIDER, 'prefix')} ${_get(SPONSORED_PROVIDER, 'firstName')} ${_get(SPONSORED_PROVIDER, 'lastName')}`,
                                            tag: 'DIRECT_NETWORK',
                                            channel: _get(SPONSORED_PROVIDER, 'network_id'),
                                            details: {
                                                provider: SPONSORED_PROVIDER,
                                            },
                                            status: 'IN_QUEUE',
                                        }
                                        chatClient.addToQueue(_get(SPONSORED_PROVIDER, 'network_id'), _get(SPONSORED_PROVIDER, 'npi'), user);
                                        queueAPI.createQueueUser(user)
                                        setIsUserInQueue(true);
                                        setQueueUUID(_get(user, 'uuid'))
                                    } else {
                                        setShowQueueForm(true)
                                    }
                                }}
                                onClickRemoveFromQueue={() => {
                                    const chatClient = getChatClient();
                                    chatClient.removeFromQueue(_get(SPONSORED_PROVIDER, 'network_id'), _get(SPONSORED_PROVIDER, 'npi'), queueUUID)
                                    setQueueUUID(null)
                                    setIsUserInQueue(false);
                                }}
                                queuePosition={_findIndex(_get(queues, `${_get(SPONSORED_PROVIDER, 'network_id')}_${_get(SPONSORED_PROVIDER, 'npi')}.users`, []), (user)=>user.uuid == queueUUID) + 1}
                                queueSize={_get(queues, `${_get(SPONSORED_PROVIDER, 'network_id')}_${_get(SPONSORED_PROVIDER, 'npi')}.count`, 0)}
                                isUserInQueue={isUserInAnyQueue(queueUUID, queues)}
                                isUserInMyQueue={!_isEmpty(_find(_get(queues, `${SPONSORED_PROVIDER.network_id}_${_get(SPONSORED_PROVIDER, 'npi')}.users`, []), (user)=>(user.npi == _get(SPONSORED_PROVIDER, 'npi') && user.uuid == queueUUID)))}
                                isOnline={_includes(onlineNetworks, `${_get(SPONSORED_PROVIDER, 'network_id')}`)}
                                isNetworkPresent={true}
                                showMessageChange={_get(providersChatData, `${_get(SPONSORED_PROVIDER, 'npi')}.showMessageChange`)}
                                onClickTrackQueue={onClickTrackQueue}
                                showTrackQueueButton={true}
                            />
                        </Grid>
                    </Grid> */}
                    {/* <Grid item container direction="row" style={{ borderBottom: '1px solid #CCCCCC' }}> */}
                        {/* <Grid item xs={1} style={{ margin: 'auto' }}>
                            <Typography variant="h6" className={classes.itemNum}>
                                &#9733;
                            </Typography>
                        </Grid> */}
                        {/* <Grid item xs={12} className={classes.item}>
                            <SponsoredItem
                                address="7420 Sawmill Rd, Columbus, OH"
                                prefix="Dr."
                                name="Syed Naqvi"
                                credentials="MD"
                                speciality="Urgent Care"
                                phone="(614) 940-9431"
                                photo_url="https://storage.googleapis.com/amk-pictures/profiles/dr-naqvi-480.jpeg"
                                onCardClick={() => window.open("https://clinic.oneclinic.io/w/d18012e8-c908-4f9d-a4b9-c877dd5e88cb", '_blank')}
                            />
                        </Grid> */}
                    {/* </Grid> */}
                    {renderItems}
                </>
            )
        }
    }

    const renderInPersonItems = () => {
        return _map(_slice(inPersonProviders, inPersonPage - 1, inPersonPage + NUM_RECORDS - 1), (item, index) => {
            return (
                <Grid item container direction="row" key={index} style={{ borderBottom: '1px solid #CCCCCC' }}>
                    {/* <Grid item xs={1} style={{ margin: 'auto' }}>
                        <Typography variant="h6" className={classes.itemNum}>
                            {index + inPersonPage}
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12} className={classes.item}>
                        <InpersonItem
                            name={_get(item, "provider_organization_name_legal_business_name")}
                            address_line1={_startCase(_toLower(_get(item, "provider_first_line_business_practice_location_address")))}
                            address_line2={`${_startCase(_toLower(_get(item, "provider_business_practice_location_address_city_name")))}, ${_get(item, "provider_business_practice_location_address_state_name")}`}
                            state_code={_get(item, "zip")}
                            speciality={_get(item, "provider_specialization")}
                            phone={_get(item, "formatted_phone_number")}
                            onCardClick={async() => {await redirectToStaticPage(item)}}
                            onChatClick={() => {
                                if (!randomUserID) {
                                    const _randomUserID = Math.floor(Math.random() * 899999) + 100000
                                    setRandomUserID(_randomUserID);
                                }
                                setSelectedChatProvider(item)
                                const _providersChatData = {
                                    ...providersChatData,
                                    [item.npi]: {
                                        ..._get(providersChatData, `${item.npi}`),
                                        showMessageChange: false,
                                    }
                                }
                                setProvidersChatData(_providersChatData);
                                if (isInfoFormSubmitted) {
                                    setShowChatDialog(true);
                                } else {
                                    setShowChatForm(true);
                                }
                            }}
                            onClickAddToQueue={() => {
                                setSelectedQueueProvider(item)
                                if (isInfoFormSubmitted) {
                                    const chatClient = getChatClient()
                                    const user = {
                                        npi: _get(item, 'npi'),
                                        uuid: uuidv4(),
                                        name: _get(networkClient, 'name'),
                                        email: _get(networkClient, 'email'),
                                        phone_number: _get(networkClient, 'phone_number'),
                                        provider_name: _startCase(_lowerCase(`${_get(item, "provider_first_name")} ${_get(item, "provider_last_name_legal_name")}`)),
                                        tag: 'DIRECT_NETWORK',
                                        channel: _get(item, 'network_id'),
                                        details: {
                                            provider: item
                                        },
                                        status: 'IN_QUEUE',
                                    }
                                    chatClient.addToQueue(_get(item, 'network_id'), _get(item, 'npi'), user);
                                    queueAPI.createQueueUser(user)
                                    setIsUserInQueue(true);
                                    setQueueUUID(_get(user, 'uuid'))
                                } else {
                                    setShowQueueForm(true)
                                }
                            }}
                            onClickRemoveFromQueue={() => {
                                const chatClient = getChatClient();
                                chatClient.removeFromQueue(_get(item, 'network_id'), _get(item, 'npi'), queueUUID)
                                setQueueUUID(null)
                                setIsUserInQueue(false);
                            }}
                            queueSize={_get(queues, `${item.network_id}_${item.npi}.count`, 0)}
                            queuePosition={_findIndex(_get(queues, `${item.network_id}_${item.npi}.users`, []), (user)=>user.uuid == queueUUID) + 1}
                            isUserInQueue={isUserInAnyQueue(queueUUID, queues)}
                            isUserInMyQueue={!_isEmpty(_find(_get(queues, `${item.network_id}_${item.npi}.users`, []), (user)=>(user.npi == _get(item, 'npi') && user.uuid == queueUUID)))}
                            isOnline={_includes(onlineNetworks, _toString(item.network_id))}
                            isNetworkPresent={!_isEmpty(_toString(_get(item, 'network_id')))}
                            showMessageChange={_get(providersChatData, `${item.npi}.showMessageChange`)}
                            showDistance={_get(item, "dist", false) == false ? false : true}
                            distance={_get(item, "dist")}
                            image={_get(item, 'image64') || `${SERVE_STATIC_PROFILE_ENDPOINT}/pictures/${item.npi}/profile.png`}
                        />
                    </Grid>
                </Grid>

            )
        })
    }

    const renderCombinedItems = ()=>{
        return (
            <React.Fragment>
                {!isLoading &&
                    <Typography variant="h6" style={{marginLeft: "0.75em", marginTop: "0.5em", marginBottom: "0.5em"}}>Clinics</Typography>
                }
                {_isEmpty(inPersonProviders) && !isLoading &&
                    <Typography color="textSecondary" style={{marginLeft: "1em"}}>
                        No Clinic Results
                    </Typography>
                }
                {!_isEmpty(inPersonProviders) && _map(_slice(inPersonProviders, 0, 2), (item, index) => {
                    return (
                        <Grid item container direction="row" key={index} style={{ borderBottom: index!=1? '1px solid #CCCCCC':"", borderTop: index==0?"1px solid #CCCCCC":"" }}>
                            {/* <Grid item xs={1} style={{ margin: 'auto' }}>
                                <Typography variant="h6" className={classes.itemNum}>
                                    {index + inPersonPage}
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12} className={classes.item}>
                                <InpersonItem
                                    name={_get(item, "provider_organization_name_legal_business_name")}
                                    address_line1={_startCase(_toLower(_get(item, "provider_first_line_business_practice_location_address")))}
                                    address_line2={`${_startCase(_toLower(_get(item, "provider_business_practice_location_address_city_name")))}, ${_get(item, "provider_business_practice_location_address_state_name")}`}
                                    state_code={_get(item, "zip")}
                                    speciality={_get(item, "provider_specialization")}
                                    phone={_get(item, "formatted_phone_number")}
                                    onCardClick={async() => {await redirectToStaticPage(item)}}
                                    onChatClick={() => {
                                        if (!randomUserID) {
                                            const _randomUserID = Math.floor(Math.random() * 899999) + 100000
                                            setRandomUserID(_randomUserID);
                                        }
                                        setSelectedChatProvider(item)
                                        const _providersChatData = {
                                            ...providersChatData,
                                            [item.npi]: {
                                                ..._get(providersChatData, `${item.npi}`),
                                                showMessageChange: false,
                                            }
                                        }
                                        setProvidersChatData(_providersChatData);
                                        if (isInfoFormSubmitted) {
                                            setShowChatDialog(true);
                                        } else {
                                            setShowChatForm(true);
                                        }
                                    }}
                                    onClickAddToQueue={() => {
                                        setSelectedQueueProvider(item)
                                        if (isInfoFormSubmitted) {
                                            const chatClient = getChatClient()
                                            const user = {
                                                npi: _get(item, 'npi'),
                                                uuid: uuidv4(),
                                                name: _get(networkClient, 'name'),
                                                email: _get(networkClient, 'email'),
                                                phone_number: _get(networkClient, 'phone_number'),
                                                provider_name: _startCase(_lowerCase(`${_get(item, "provider_first_name")} ${_get(item, "provider_last_name_legal_name")}`)),
                                                tag: 'DIRECT_NETWORK',
                                                channel: _get(item, 'network_id'),
                                                details: {
                                                    provider: item
                                                },
                                                status: 'IN_QUEUE',
                                            }
                                            chatClient.addToQueue(_get(item, 'network_id'), _get(item, 'npi'), user);
                                            queueAPI.createQueueUser(user)
                                            setIsUserInQueue(true);
                                            setQueueUUID(_get(user, 'uuid'))
                                        } else {
                                            setShowQueueForm(true)
                                        }
                                    }}
                                    onClickRemoveFromQueue={() => {
                                        const chatClient = getChatClient();
                                        chatClient.removeFromQueue(_get(item, 'network_id'), _get(item, 'npi'), queueUUID)
                                        setQueueUUID(null)
                                        setIsUserInQueue(false);
                                    }}
                                    queueSize={_get(queues, `${item.network_id}_${item.npi}.count`, 0)}
                                    queuePosition={_findIndex(_get(queues, `${item.network_id}_${item.npi}.users`, []), (user)=>user.uuid == queueUUID) + 1}
                                    isUserInQueue={isUserInAnyQueue(queueUUID, queues)}
                                    isUserInMyQueue={!_isEmpty(_find(_get(queues, `${item.network_id}_${item.npi}.users`, []), (user)=>(user.npi == _get(item, 'npi') && user.uuid == queueUUID)))}
                                    isOnline={_includes(onlineNetworks, _toString(item.network_id))}
                                    isNetworkPresent={!_isEmpty(_toString(_get(item, 'network_id')))}
                                    showMessageChange={_get(providersChatData, `${item.npi}.showMessageChange`)}
                                    showDistance={_get(item, "dist", false) == false ? false : true}
                                    distance={_get(item, "dist")}
                                    image={_get(item, 'image64') || `${SERVE_STATIC_PROFILE_ENDPOINT}/pictures/${item.npi}/profile.png`}
                                />
                            </Grid>
                        </Grid>

                        )
                    }) 
                }
                {!_isEmpty(inPersonProviders) && 
                    <Link component="button" style={{marginLeft: "1em", marginTop: "0.5em", fontSize: "medium", textAlign: "left"}} onClick={()=>{
                        setTypeFilter("Clinics")
                        setIsClinic(true)
                    }}>
                        Show More Clinics...
                    </Link>
                }
                {!isLoading &&
                    <Typography variant="h6" style={{marginTop: "1em", paddingTop: "0.5em", marginBottom: "0.5em", paddingLeft: "0.75em", borderTop: "1px solid #CCCCCC"}}>Doctors</Typography>
                }
                {_isEmpty(telemedicineProviders) && !isLoading &&
                    <Typography color="textSecondary" style={{marginLeft: "1em"}}>
                        No Doctor Results
                    </Typography>
                }
                {!_isEmpty(telemedicineProviders) && _map(_slice(telemedicineProviders, 0, 2), (item, index) => {
                    return (
                        <Grid item container direction="row" key={index} style={{ borderBottom: index!=1?'1px solid #CCCCCC':"", borderTop: index==0?"1px solid #CCCCCC":"" }}>
                            {/* <Grid item xs={1} style={{ margin: 'auto' }}>
                                <Typography variant="h6" className={classes.itemNum}>
                                    {index + telemedicinePage}
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12} className={classes.item}>
                                <TelemedicineItem
                                    address={
                                        `${_startCase(_toLower(_get(item, "provider_first_line_business_practice_location_address")))}, ${_startCase(_toLower(_get(item, "provider_business_practice_location_address_city_name")))}, ${_get(item, "provider_business_practice_location_address_state_name")}`
                                    }
                                    prefix=''
                                    name={`${_get(item, "display_name")}`}
                                    credentials={_get(item, "provider_credential_text")}
                                    speciality={_get(item, "provider_specialization")}
                                    phone={_get(item, "formatted_phone_number")}
                                    onCardClick={async() => {await redirectToStaticPage(item)}}
                                    onChatClick={() => {
                                        if (!randomUserID) {
                                            const _randomUserID = Math.floor(Math.random() * 899999) + 100000
                                            setRandomUserID(_randomUserID);
                                        }
                                        // setShowChatDialog(true)
                                        setSelectedChatProvider(item)
                                        const _providersChatData = {
                                            ...providersChatData,
                                            [item.npi]: {
                                                ..._get(providersChatData, `${item.npi}`),
                                                showMessageChange: false,
                                            }
                                        }
                                        setProvidersChatData(_providersChatData);
                                        // if form is already submitted then open chat dialog
                                        // otherwise open info form
                                        if (isInfoFormSubmitted) {
                                            setShowChatDialog(true);
                                        } else {
                                            setShowChatForm(true);
                                        }
                                    }}
                                    onClickAddToQueue={() => {
                                        setSelectedQueueProvider(item)
                                        if (isInfoFormSubmitted) {
                                            const chatClient = getChatClient()
                                            const user = {
                                                npi: _get(item, 'npi'),
                                                uuid: uuidv4(),
                                                name: _get(networkClient, 'name'),
                                                email: _get(networkClient, 'email'),
                                                phone_number: _get(networkClient, 'phone_number'),
                                                provider_name: _startCase(_lowerCase(`${_get(item, "provider_first_name")} ${_get(item, "provider_last_name_legal_name")}`)),
                                                tag: 'DIRECT_NETWORK',
                                                channel: _get(item, 'network_id'),
                                                details: {
                                                    provider: item
                                                },
                                                status: 'IN_QUEUE',
                                            }
                                            chatClient.addToQueue(_get(item, 'network_id'), _get(item, 'npi'), user);
                                            queueAPI.createQueueUser(user)
                                            setIsUserInQueue(true);
                                            setQueueUUID(_get(user, 'uuid'))
                                        } else {
                                            setShowQueueForm(true)
                                        }
                                    }}
                                    onClickRemoveFromQueue={() => {
                                        const chatClient = getChatClient();
                                        chatClient.removeFromQueue(_get(item, 'network_id'), _get(item, 'npi'), queueUUID)
                                        setQueueUUID(null)
                                        setIsUserInQueue(false);
                                    }}
                                    queueSize={_get(queues, `${item.network_id}_${item.npi}.count`, 0)}
                                    queuePosition={_findIndex(_get(queues, `${item.network_id}_${item.npi}.users`, []), (user)=>user.uuid == queueUUID) + 1}
                                    isUserInQueue={isUserInAnyQueue(queueUUID, queues)}
                                    isUserInMyQueue={!_isEmpty(_find(_get(queues, `${item.network_id}_${item.npi}.users`, []), (user)=>(user.npi == _get(item, 'npi') && user.uuid == queueUUID)))}
                                    isOnline={_includes(onlineNetworks, _toString(item.network_id))}
                                    isNetworkPresent={!_isEmpty(_toString(_get(item, 'network_id')))}
                                    showMessageChange={_get(providersChatData, `${item.npi}.showMessageChange`)}
                                    showDistance={_get(item, "dist", false) == false ? false : true}
                                    distance={_get(item, "dist")}
                                    onClickTrackQueue={onClickTrackQueue}
                                    showTrackQueueButton={false}
                                    showChatButton={false}
                                    showQueue={false}
                                />
                            </Grid>
                            {/* <Grid item xs={11} className={classes.item}>

                            </Grid> */}
                        </Grid>

                    )
                    })
                }

                {!_isEmpty(telemedicineProviders) && 
                    <Link component="button" style={{marginLeft: "1em", marginTop: "0.5em", marginBottom: "0.5em", fontSize: "medium", textAlign: "left"}} onClick={()=>{
                        setTypeFilter("Doctors")
                        setIsTelemedicine(true)
                    }}>
                        Show More Doctors...
                    </Link>
                }
            </React.Fragment>
        );
    }

    const footerText = () => {
        let footer_text = "";
        if (isTelemedicine) {
            const telemedicine_end = ((telemedicinePage + NUM_RECORDS - 1) > telemedicineTotalState) ? telemedicineTotalState : (telemedicinePage + NUM_RECORDS - 1);
            console.log("Telemedicine Current: ", telemedicineTotalState)
            footer_text = (telemedicineTotalState == 1000) ?
                `${telemedicinePage}-${telemedicine_end} of ${telemedicineTotalState}+`
                : `${telemedicinePage}-${telemedicine_end} of ${telemedicineTotalState}`
        } else if(isClinic){
            const inperson_end = ((inPersonPage + NUM_RECORDS - 1) > inPersonTotalState) ? inPersonTotalState : (inPersonPage + NUM_RECORDS - 1);
            footer_text = (inPersonTotalState == 1000) ?
                `${inPersonPage}-${inperson_end} of ${inPersonTotalState}+`
                : `${inPersonPage}-${inperson_end} of ${inPersonTotalState}`
        }

        return footer_text;
    }

    return (
        <>
            <Header showBackBtn={false} backgroundColorOfHeader="#009be5"/>
            <Container component="main" maxWidth="xl" disableGutters>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="warning">
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
                <Grid container spacing={0} direction="row" className={classes.gridContainer}>
                    <Grid item xs={12} container direction="column">
                        {/* className={classes.searchBarContainer} style={{ textAlign: "center" }} */}
                        <Grid item style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                            <SearchBar
                                specialityList={specialityList}
                                onClickPrimary={onClickSearch}
                                isTelemedicine={isTelemedicine}
                                setParentLoading={setIsLoading}
                                clearSearch={clearSearch}
                            />
                        </Grid>
                    </Grid>
                    {
                        matches ?
                            (
                                <React.Fragment>
                                    <Grid item xs={12} style={{ width: '100%', position: "sticky", top: 0, zIndex: 10000 }}>
                                        {
                                            (!_isEmpty(telemedicineProviders) || !_isEmpty(inPersonProviders)) && renderMap()
                                        }
                                    </Grid>
                                    
                                    {(isTelemedicine && _isEmpty(telemedicineProviders) || (isClinic && _isEmpty(inPersonProviders))) || (_isEmpty(telemedicineProviders) && _isEmpty(inPersonProviders))?
                                        <Grid item container direction="row" justify="center" alignItems="center" alignContent="center" className={classes.banner2} style={{ minHeight: '300px', }}>
                                            <Grid item xs={12}>
                                                <img src={LANDING_PAGE_IMAGE_URL} style={{margin:"auto", maxWidth: "100%", height: "auto", display: "block"}}/>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid item container direction="column" className={classes.banner2}>
                                            <Grid item xs={12} container direction="column">
                                                {isTelemedicine ? renderTelemedicineItems() : isClinic? renderInPersonItems() : renderCombinedItems()}
                                            </Grid>
                                        </Grid>
                                    }
                                </React.Fragment>
                            )
                            :
                            (
                                (isTelemedicine && _isEmpty(telemedicineProviders) || (isClinic && _isEmpty(inPersonProviders))) || (_isEmpty(telemedicineProviders) && _isEmpty(inPersonProviders))?
                                    <Grid item container direction="row" justify="center" alignItems="center" alignContent="center" className={classes.banner2} style={{ minHeight: '600px' }}>
                                        <Grid item xs={12}>
                                            <img src={LANDING_PAGE_IMAGE_URL} style={{margin:"auto", width: "auto", height: "640px", display: "block"}}/>
                                        </Grid>
                                    </Grid>
                                :
                                    <Grid item container direction="row" className={classes.banner2} style={{ minHeight: '600px', borderBottom: "1px solid #CCC", borderLeft: "1px solid #CCC", borderRight: "1px solid #CCC" }}>
                                        <Grid item xs={7} md={7} lg={5} xl={4} container direction="column">
                                            {isTelemedicine ? renderTelemedicineItems() : isClinic? renderInPersonItems() : renderCombinedItems()}
                                        </Grid>
                                        <Grid item xs={5} md={5} lg={7} xl={8} style={{ width: '100%' }}>
                                            {
                                                (!_isEmpty(telemedicineProviders) || !_isEmpty(inPersonProviders)) && renderMap()

                                            }
                                        </Grid>
                                    </Grid>
                            )
                    }
                    {
                        (
                            (isTelemedicine && !_isEmpty(telemedicineProviders))
                            || (isClinic && !_isEmpty(inPersonProviders))
                        ) &&
                        <Grid item xs={12} className={clsx(classes.banner, classes.showMoreContainer)}>
                            <div style={{ marginLeft: 'auto' }}>
                                <Typography
                                    className={classes.aptText}
                                    style={{ marginRight: '1em', display: 'inline-block' }}
                                >
                                    {
                                        footerText()
                                    }
                                </Typography>
                                <IconButton
                                    size="small"
                                    disabled={((isTelemedicine && telemedicinePage == 1) || (!isTelemedicine && inPersonPage == 1)) ? true : false}
                                    onClick={loadPrev}
                                >
                                    <ArrowBackIosIcon />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    disabled={((isTelemedicine && (telemedicinePage + NUM_RECORDS - 1) >= telemedicineTotalState) || (!isTelemedicine && (inPersonPage + + NUM_RECORDS - 1) >= inPersonTotalState)) ? true : false}
                                    onClick={loadNext}
                                >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </div>
                        </Grid>
                    }
                </Grid>
                <ChatDialog
                    user={{
                        name: _get(networkClient, 'name')
                    }}
                    messages={_filter(_get(providersChatData, `${_get(selectedChatProvider, 'npi')}.chatHistory`), (data) => !_isEmpty(data.message)).slice(0).reverse()}
                    showDialog={showChatDialog}
                    onCloseDialog={() => {
                        setShowChatDialog(false);
                    }}
                    onSend={async (msg) => {
                        const chatClient = getChatClient();
                        const npi = _get(selectedChatProvider, 'npi');
                        if (_isEmpty(_get(providersChatData, `${npi}.encounter.uuid`))) {
                            const data = {
                                name: _get(networkClient, 'name'),
                                email: _get(networkClient, 'email'),
                                phone_number: _get(networkClient, 'phone_number'),
                                network_id: _toString(_get(selectedChatProvider, 'network_id')),
                                // TODO: Probably, provider id from user_profile table should also be inserted into the npi table
                                provider_id: _get(selectedChatProvider, 'user_profile_id'),
                                'client_uuid': _get(networkClient, 'uuid'),
                                // 'browser': _get(props, 'browser'),
                            }
                            const encounter = await createChatEncounter(data);
                            const encounter_uuid = _get(encounter, 'uuid');
                            encounter_npi.set(encounter_uuid, npi);
                            chatClient.publishPresence(encounter_uuid, `User${_get(networkClient, 'uuid')}`);
                            if (!_isEmpty(encounter_uuid)) {
                                // setChatEncounterUUID(encounter_uuid)
                                // _chatEncounterUUID = encounter_uuid;
                                const _providersChatData = {
                                    ...providersChatData,
                                    [npi]: {
                                        ..._get(providersChatData, `${npi}`),
                                        encounter,
                                    }
                                }
                                setProvidersChatData(_providersChatData);
                            } else {
                                console.error('Encounter uuid is empty');
                                return;
                            }
                            chatClient.join(encounter_uuid, _get(networkClient, 'name'));
                            chatClient.requestChatHistory(encounter_uuid);
                            chatClient.message(encounter_uuid, _get(networkClient, 'name'), msg);

                        } else {
                            const encounter_uuid = _get(providersChatData, `${npi}.encounter.uuid`)
                            chatClient.message(encounter_uuid, _get(networkClient, 'name'), msg);
                        }
                    }}
                />
                <InfoForm
                    openDialog={showQueueForm || showChatForm}
                    handleClose={() => {
                        setShowQueueForm(false)
                        setShowChatForm(false)
                    }}
                    onDataSubmit={async (data, callback) => {
                        console.log(data)
                        const { name, email, phone_number } = data;
                        setIsLoading(true)
                        let network_client;
                        if (showChatForm) {
                            network_client = {
                                network_id: _toString(_get(selectedChatProvider, 'network_id')),
                                name,
                                phone_number,
                                email,
                            };
                        } else if (showQueueForm) {
                            network_client = {
                                network_id: _toString(_get(selectedQueueProvider, 'network_id')),
                                name,
                                phone_number,
                                email,
                            };
                        }

                        const response = await createNetworkClient(network_client);
                        console.log('Network client response: ', response);
                        setNetworkClient(response)
                        setIsLoading(false)
                        callback();
                    }}
                    onVerificationSuccess={() => {
                        if (showQueueForm) {
                            const chatClient = getChatClient()
                            const user = {
                                npi: _get(selectedQueueProvider, 'npi'),
                                uuid: uuidv4(),
                                name: _get(networkClient, 'name'),
                                email: _get(networkClient, 'email'),
                                phone_number: _get(networkClient, 'phone_number'),
                                provider_name: _startCase(_lowerCase(`${_get(selectedQueueProvider, "provider_first_name")} ${_get(selectedQueueProvider, "provider_last_name_legal_name")}`)),
                                tag: 'DIRECT_NETWORK',
                                channel: _get(selectedQueueProvider, 'network_id'),
                                details: {
                                    provider: selectedQueueProvider,
                                },
                                status: 'IN_QUEUE',

                            }
                            chatClient.addToQueue(_get(selectedQueueProvider, 'network_id'), _get(selectedQueueProvider, 'npi'), user);
                            queueAPI.createQueueUser(user)
                            setIsUserInQueue(true);
                            setQueueUUID(_get(user, 'uuid'))
                        }
                        if (showChatForm) {
                            setShowChatDialog(true)
                        }
                        setIsInfoFormSubmitted(true);
                    }}
                    isMobile={matches}
                />
                <Backdrop style={{ color: "#fff", zIndex: 10000 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container >
            {(_size(telemedicineProviders) == 0 && _size(inPersonProviders) == 0) &&
                <FooterCopyright/>
            }
        </>
    );
}