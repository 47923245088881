// Lodash/fp
import get from "lodash/fp/get";
import fetch from "node-fetch";
import _get from "lodash/get";
import _isUndefined from "lodash/isUndefined"

// Project
import { PROVIDER_PROFILE_ENDPOINT, REGENERATE_PROFILE_ENDPOINT,
    PROVIDER_PROFILE_CLAIM_ENDPOINT, GENERATE_PROFILE_ENDPOINT } from "../../Constants";
import { getKeycloak } from '../keycloak';

const getProviderData = async ({
    editToken,
}) => {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');

    try {
        const response = await fetch(
            `${PROVIDER_PROFILE_ENDPOINT}/provider-data`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token ? `Bearer ${token}` : "",
                },
            }
        );


        const body = await response.json();
        // console.log(body)

        if (get("status")(body) != 200) {
            return {
                error: get('message')(body) || 'Operation failed'
            }
        }

        const data = get("data")(body);

        return data;
    } catch (e) {
        console.log("Provider Data: ")
        console.log(e)
    }
}

const getProviderDataByNpi = async ({
    npi,
    editToken,
}) => {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');

    try {
        const response = await fetch(
            `${PROVIDER_PROFILE_ENDPOINT}/provider-data-by-npi`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token ? `Bearer ${token}` : "",
                },
                body: JSON.stringify({
                    npi,
                })
            }
        );


        const body = await response.json();
        // console.log(body)

        if (get("status")(body) != 200) {
            return {
                error: get('message')(body) || 'Operation failed'
            }
        }

        const data = get("data")(body);

        return data;
    } catch (e) {
        console.log("Provider Data: ")
        console.log(e)
    }
}

const editProviderInfo = async ({
    provider_id,
    user_profile_id,
    prefix,
    name,
    credentials,
    speciality,
    description,
    editToken,
}) => {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');

    try {
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/edit-provider-info`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                user_profile_id,
                prefix,
                name,
                credentials,
                speciality,
                description,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
} catch (error) {
    console.error(error);
}
}

const editProviderInfoUnregistered = async ({
    provider_id,
    unregistered_profile_id,
    prefix,
    name,
    credentials,
    speciality,
    description,
    editToken,
}) => {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');

    try {
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/edit-provider-info-unregistered`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                unregistered_profile_id,
                prefix,
                name,
                credentials,
                speciality,
                description,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const getStateData = async ({
    provider_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/state-data`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const data = get("data")(body);

    return data;
} catch (error) {
    console.error(error);
}
}

const addNewLicenseState = async ({
    provider_id,
    state_name,
    license_number,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/add-license-state`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                state_name,
                license_number
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
} catch (error) {
    console.error(error);
}
}

const deleteLicenseState = async ({
    provider_id,
    license_state_id,
    editToken,
}) => {
    try { 
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/delete-license-state`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                license_state_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }

}

const getAllTags = async ({
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/all-tags`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const tags = get("tags")(body);

    return tags;
    } catch (error) {
        console.error(error);
    }
}

const getTags = async ({
    provider_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/tags`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const tags = get("tags")(body);

    return tags;
    } catch (error) {
        console.error(error);
    }
}

const deleteTag = async ({
    provider_id,
    tag_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/delete-tag`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                tag_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const addTag = async ({
    provider_id,
    tag_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/add-tag`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                tag_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const getAllServices = async ({
    network_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/all-services`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                network_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const services = get("services")(body);

    return services;
    } catch (error) {
        console.error(error);
    }
}

const getServices = async ({
    provider_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/services`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const services = get("services")(body);

    return services;
    } catch (error) {
        console.error(error);
    }
}

const addService = async ({
    provider_id,
    service_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/add-service`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                service_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const addNewService = async ({
    provider_id,
    network_id,
    service_name,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/add-new-service`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                network_id,
                service_name,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const deleteService = async ({
    provider_id,
    service_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/delete-service`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                service_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const claimProfile = async ({
    npi,
    user_profile_id,
    network_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/claim-request`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                npi,
                user_profile_id,
                network_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const claim_id = get("data")(body);

    return claim_id;
    } catch (error) {
        console.error(error);
    }
}
const acceptProfileClaim = async ({
    npi,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');

    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/accept-profile-claim`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                npi,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const data = get("data")(body);

    return data;
    } catch (error) {
        console.error(error);
    }
}

const isProfileClaimAccepted = async ({
    npi,
    user_profile_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/is-claim-accepted`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                npi,
                user_profile_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    return get("data")(body);
    } catch (error) {
        console.error(error);
    }
}

const regenerateStaticProfile = async ({
    npi,
}) => {
    try {
    const response = await fetch(
        `${REGENERATE_PROFILE_ENDPOINT}/regenerate_profile/${npi}`,
        {
            method: "GET",
            mode: "no-cors"
        }
    );
    } catch (error) {
        console.error(error);
    }
}

const insertProviderNpiData = async ({
    npi,
    user_profile_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/insert-npi-data`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                npi,
                user_profile_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const data = get("data")(body);

    return data;
    } catch (error) {
        console.error(error);
    }
}

const insertUnregisteredProviderNpiData = async ({
    npi,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/insert-unregister-npi-data`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                npi,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const data = get("data")(body);

    return data;
    } catch (error) {
        console.error(error);
    }
}

const insertClaimEntry = async ({
    npi,
    user_profile_id,
    network_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_CLAIM_ENDPOINT}/claim-profile`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                "npi": `${npi}`,
                "networkId": `${network_id}`,
                "userProfileId": parseInt(user_profile_id),
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    return body;
    } catch (error) {
        console.error(error);
    }
}

const getAllInsurances = async ({
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/all-insurances`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const insurances = get("insurance")(body);

    return insurances;
    } catch (error) {
        console.error(error);
    }
}

const getInsurances = async ({ provider_id, editToken, }) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/insurances`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const insurances = get("insurance")(body);

    return insurances;
    } catch (error) {
        console.error(error);
    }
}

const deleteInsurance = async ({ provider_id, insurance_id, editToken, }) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/delete-insurance`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                insurance_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const addInsurance = async ({ provider_id, insurance_id, editToken, }) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/add-insurance`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                insurance_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const getTestimonials = async ({ provider_id, editToken, }) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/testimonials`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const testimonials = get("testimonials")(body);

    return testimonials;
    } catch (error) {
        console.error(error);
    }
}

const addTestimonial = async ({
    testimonial,
    name,
    type,
    provider_id,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/add-testimonial`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                testimonial,
                name,
                type,
                provider_id,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const deleteTestimonial = async ({ provider_id, testimonial_id, editToken, }) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/delete-testimonial`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                provider_id,
                testimonial_id
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }
    } catch (error) {
        console.error(error);
    }
}

const generateVerificationCode = async ({
    npi,
    code,
    expiry,
}) => {
    try {
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/generate-verification-code`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                npi,
                code,
                expiry,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const verification_code = get("code")(body);

    return verification_code;
    } catch (error) {
        console.error(error);
    }
}

const verifyVerificationCode = async ({
    npi,
    code,
}) => {
    try {
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/verify-verification-code`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                npi,
                code,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const isVerified = get("isVerified")(body);

    return isVerified;
    } catch (error) {
        console.error(error);
    }
}

const generateAuthToken = async () => {
    try {
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/generate-token`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const token = get("token")(body);

    return token;
    } catch (error) {
        console.error(error);
    }
}

const isProviderRegistered = async ({
    npi,
}) => {
    try {
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/is-provider-registered`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                npi,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const isRegistered = get("data")(body);

    return isRegistered;
    } catch (error) {
        console.error(error);
    }
}

const editUnRegisteredProfileDetails = async ({
    unregistered_profile_id,
    details,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');
    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/edit-unregistered-details`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                unregistered_profile_id,
                details,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    return get("data")(body);
    } catch (error) {
        console.error(error);
    }
}

const isNpiValid = async ({
    npi,
    editToken,
}) => {
    try {
    const token = editToken || getKeycloak().token || localStorage.getItem('token');

    const response = await fetch(
        `${PROVIDER_PROFILE_ENDPOINT}/is-npi-valid`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                npi,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        return {
            error: get('message')(body) || 'Operation failed'
        }
    }

    const data = get("data")(body);

    return data;
    } catch (error) {
        console.error(error);
    }
}

const generateAndGetProfile = async (npi, forceRecreate=false) => {
    if (_isUndefined(npi))
    {
        return null
    }

    try {
        const variable = {
            npi,
            forceRecreate
        }

        const response = await fetch(`${GENERATE_PROFILE_ENDPOINT}`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(variable)
        })

        const respJson = await response.json()
        return respJson
    }
    catch (e)
    {
        console.error(e)
        return {}
    }
}

export default {
    getProviderData,
    getProviderDataByNpi,
    editProviderInfo,
    editProviderInfoUnregistered,

    getStateData,
    addNewLicenseState,
    deleteLicenseState,

    getAllTags,
    getTags,
    deleteTag,
    addTag,

    getAllServices,
    getServices,
    addService,
    addNewService,
    deleteService,

    getAllInsurances,
    getInsurances,
    deleteInsurance,
    addInsurance,

    claimProfile,
    isProfileClaimAccepted,
    acceptProfileClaim,

    regenerateStaticProfile,
    insertProviderNpiData,

    insertClaimEntry,

    getTestimonials,
    addTestimonial,
    deleteTestimonial,

    generateVerificationCode,
    verifyVerificationCode,

    generateAuthToken,

    isProviderRegistered,
    insertUnregisteredProviderNpiData,
    editUnRegisteredProfileDetails,
    isNpiValid,
    generateAndGetProfile
}