import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MessageIcon from '@material-ui/icons/Message';
import CheckIcon from '@material-ui/icons/Check';

import RenderStatus from '../status/RenderStatus';

import '@fontsource/noto-sans-hk';
import '@fontsource/oswald';

// lodash 
const _isEmpty = require("lodash/isEmpty");
const _replace = require("lodash/replace");
const _startCase = require("lodash/startCase");
const _toLower = require("lodash/toLower");

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        // padding: theme.spacing(2),
    },
    address: {
        // paddingTop: theme.spacing(2),
        // paddingBottom: theme.spacing(2),
        fontFamily: "'Noto Sans HK'",
    },
    avatar: {
        height: '75px',
        width: '75px',
        margin: 'auto'
    },
    name: {
        fontFamily: "'Noto Sans HK'",
    },
    speciality: {
        fontFamily: "'Noto Sans HK'",
        color: 'gray',
    },
    noTransform: {
        textTransform: 'none',
        fontFamily: 'Helvetica'
    },
    centerItems: {
        margin: 'auto',
    },
    BtnContainerSm: {
        marginTop: theme.spacing(2),
        display: 'grid',
        justifyContent: 'center',
    },

    headerText: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        // fontWeight: 'bolder',
        fontFamily: "'Noto Sans HK'",
    },
    borderTop: {
        borderTop: '1px solid #CCCCCC',
    },
    borderBottom: {
        borderBottom: '1px solid #CCCCCC',
    },
    footer: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footIconContainer: {
        fontSize: 'large',
        margin: 'auto'
    },
    fontIconContainerSm: {
        margin: 'auto',
        fontSize: 'large',
    },
    footIcon: {
        fontSize: '140%',
    },
    footText: {
        margin: 'auto',
        textAlign: 'center'
    },
    addButton: {
        fontFamily: "'Noto Sans HK'",
        textTransform: 'none',
        paddingTop: '0px',
    },
    chatButton: {
        margin: 'auto',
        marginTop: theme.spacing(2),
        fontFamily: "'Noto Sans HK'",
        textTransform: 'none',
        width: '18em',
    },
}));

export default function SponsoredItem(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        address = "1457 South Main Rd, Finkleton, FL",
        prefix = "",
        name = "Dr. Brock Ittaow (DO)",
        credentials = "",
        speciality = "Primary Care",
        phone = "(786) 863-5539",
        photo_url = "",
        onCardClick = () => console.log("Clicked!"),
        onChatClick = () => { console.log('onChatClick is not defined') },
        onClickAddToQueue = () => { console.log('onClickAddToQueue is not defined') },
        onClickRemoveFromQueue = () => { console.log('onClickRemoveFromQueue is not defined') },
        queuePosition = 0,
        queueSize = 0,
        isUserInQueue = false,
        isUserInMyQueue = false,
        isOnline = false,
        isNetworkPresent,
        showMessageChange,
        onClickTrackQueue = () => { console.log("onClickTrackQueue() is not defined") },
        showTrackQueueButton = true,
    } = props;
    const waitTime = (queueSize * 10) < 60?(queueSize * 10):60;
    const formatName = () => {
        const name_format = _startCase(_toLower(name))
        const prefix_format = _startCase(_toLower(prefix))
        const credentials_clean = _replace(credentials, /[^A-Za-z,\s]/g, "")

        if (!_isEmpty(credentials_clean) && !_isEmpty(prefix)) {

            if (credentials_clean == 'MD' || credentials_clean == 'DO') {
                return `Dr. ${name_format} (${credentials_clean})`
            }

            return `${prefix_format}. ${name_format} (${credentials_clean})`
        }

        /* only credential is missing */
        else if (!_isEmpty(prefix)) {
            return `${prefix_format}. ${name_format}`

        }

        /* both are missing */
        else {
            return `${name_format}`
        }
    }

    const setFontColor = (queue_len) => {
        // Green 20 min or less.  Yellow 20-40 min.  Orange 40 min to 90 min.   Red more than 90 minutes
        // if (queue_len <= 20) {
        //     return 'green';
        // } else if (queue_len <= 40) {
        //     return 'yellow';
        // } else if (queue_len <= 90) {
        //     return 'orange';
        // } else {
        //     return 'red';
        // }
        return 'green';
    }

    return (
        <Grid container direction="row" className={classes.root}>
            <Grid item>
                <Chip label="Sponsored" variant="outlined" className={classes.headerText} />
                {/* <Typography className={classes.headerText}>
                </Typography> */}
            </Grid>
            <Grid item xs={12} container direction="row">
                <Grid item xs={matches ? 3 : 2}>
                    <Avatar
                        className={classes.avatar}
                        src={photo_url}
                    ></Avatar>
                </Grid>
                <Grid item xs={matches ? 9 : 6} container direction="column">
                    <CardActionArea onClick={onCardClick}>
                        <Typography variant="h6" className={classes.name}>
                            {formatName()}
                        </Typography>
                        <Typography variant="body1" className={classes.speciality}>
                            {speciality}
                        </Typography>
                        <Typography variant="body2" className={classes.address}>
                            {address}
                        </Typography>
                    </CardActionArea>
                </Grid>
                {
                    !matches &&
                    <Grid item xs={4} className={classes.centerItems}>
                        <Button variant="contained" color="primary" className={classes.noTransform} href={`tel:+1${_replace(phone, /\D/g, '')}`}>
                            Call: {phone}
                        </Button>
                        {isNetworkPresent &&
                            <Button
                                variant={showMessageChange ? 'contained' : 'outlined'}
                                color={showMessageChange ? 'primary' : 'default'}
                                startIcon={<MessageIcon size="small" />}
                                className={classes.noTransform}
                                style={{ width: '12em', marginTop: '0.5em' }}
                                onClick={onChatClick}
                            >
                                {showMessageChange ? 'Unread Message(s)' : 'Chat'}
                                <div style={{ margin: '0 0 0.1em 0.5em', display: 'inline-block' }}>
                                    <RenderStatus status={isOnline} />
                                </div>
                            </Button>
                        }
                    </Grid>
                }
            </Grid>
            {
                matches &&
                <Grid item xs={12} className={classes.BtnContainerSm}>
                    <Button variant="contained" color="primary" className={clsx(classes.noTransform, classes.centerItems)} href={`tel:+1${_replace(phone, /\D/g, '')}`}>
                        Call: {phone}
                    </Button>
                    {isNetworkPresent &&
                        <Button
                            variant={showMessageChange ? 'contained' : 'outlined'}
                            color={showMessageChange ? 'primary' : 'default'}
                            startIcon={<MessageIcon size="small" />}
                            className={clsx(classes.noTransform, classes.centerItems)}
                            style={{ width: '12em', marginTop: '0.5em' }}
                            onClick={onChatClick}
                        >
                            {showMessageChange ? 'Unread Message(s)' : 'Chat'}
                            <div style={{ margin: '0 0 0.1em 0.5em', display: 'inline-block' }}>
                                <RenderStatus status={isOnline} />
                            </div>
                        </Button>
                    }
                </Grid>
            }
            {isNetworkPresent &&
                <Grid item xs={12} container direction="row" className={classes.footer}>
                    {/* <Grid item xs={matches ? 3 : 2} container direction="row">
                        <Grid item className={matches ? classes.fontIconContainerSm : classes.footIconContainer}>
                            <PeopleAltIcon className={classes.footIcon} />
                        </Grid>
                        <Grid item className={classes.footText} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body2" className={classes.address} style={matches ? { fontSize: '11px' } : null}>
                                {isUserInMyQueue?'POSITION':'WAITING'}
                            </Typography>
                            <Typography variant="body2" className={classes.address} style={matches ? { fontSize: '11px', fontWeight: 'bolder' } : { fontWeight: 'bolder' }}>
                                {isUserInMyQueue?queuePosition:queueSize}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item><Divider orientation="vertical" variant="middle" /></Grid> */}

                    <Grid item xs={matches ? 3 : 2}></Grid>
                    <Grid item xs={matches ? 3 : 2} container direction="row">
                        <Button
                            startIcon={<HourglassEmptyIcon className={classes.footIcon} style={{ color: setFontColor(queueSize * 10), fontSize: '20px' }} />}
                            className={classes.addButton}
                            style={matches ? { fontSize: '11px', paddingLeft: '0' } : {paddingLeft: '0'}}
                            onClick={onClickAddToQueue}
                            disabled={true}
                            disableElevation={true}
                            disableFocusRipple={true}
                            disableRipple={true}
                        >
                            <span style={matches ? { fontSize: '11px', color: setFontColor(queueSize * 10) } : { color: setFontColor(queueSize * 10) }}>

                                {
                                    (queueSize == 0 || queuePosition == 1) ? "No Wait" : `${waitTime} min wait`
                                }
                            </span>
                        </Button>
                        {/* <Grid item className={classes.footText} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body2" className={classes.address} style={matches ? { fontSize: '11px' } : null}>
                                EST. WAIT
                            </Typography>
                            <Typography variant="body2" className={classes.address} style={matches ? { fontSize: '11px', fontWeight: 'bolder', color: setFontColor(queueSize * 10) } : { fontWeight: 'bolder', color: setFontColor(queueSize * 10) }}>
                                {queueSize * 10}
                            </Typography>
                        </Grid> */}
                    </Grid>

                    {/* {
                        matches
                            ? <Grid item xs={1} style={{ display: 'flex', maxWidth: '1.333333%' }}><Divider orientation="vertical" variant="middle" style={{ margin: 'auto' }} /></Grid>
                            : <Grid item xs={2}></Grid>
                    } */}

                    <Grid item xs={matches ? 5 : 5} container direction="row">
                        {
                            isUserInMyQueue ?
                                <>
                                    <Grid item className={classes.footText}>
                                        <Typography variant="body2" className={classes.address} style={matches ? { fontSize: '11px' } : null}>
                                            In Wait List
                                        </Typography>
                                    </Grid>
                                    <Grid item style={matches ? { margin: 'auto' } : { margin: 'auto 0 auto 2px' }}>
                                        <Button color="secondary" size="small" variant="outlined" onClick={() => {
                                            onClickRemoveFromQueue();
                                        }}>
                                            Exit
                                        </Button>
                                    </Grid>
                                </>
                                :
                                <Button
                                    startIcon={<AddCircleOutlineIcon className={classes.footIcon} />}
                                    className={classes.addButton}
                                    style={matches ? { fontSize: '11px' } : null}
                                    onClick={onClickAddToQueue}
                                    disabled={isUserInQueue}
                                >
                                    {isUserInQueue ? 'In Another WaitList' : 'Add to WaitList'}
                                </Button>
                        }
                    </Grid>
                    {isUserInMyQueue && showTrackQueueButton &&
                        <Grid item xs={12} container direction="row" className={classes.borderTop} style={{ marginTop: '0.5em' }}>
                            <Button className={classes.centerItems} style={{ marginTop: '1.5em' }} color="primary" variant="contained" size="small"
                                onClick={onClickTrackQueue}
                            >
                                Track Queue Position
                            </Button>
                        </Grid>
                    }
                </Grid>
            }
            {isUserInMyQueue &&
                <Grid item xs={12} container direction="row" className={classes.borderTop}>
                    <Button className={classes.centerItems} style={{ marginTop: '1.5em' }} color="primary" variant="contained" size="small"
                        onClick={onClickTrackQueue}
                    >
                        Track Queue Position
                    </Button>
                </Grid>
            }
            {isUserInMyQueue &&
                <Grid item xs={12} container direction="row" className={classes.borderTop}>
                    <Button className={classes.centerItems} style={{ marginTop: '1.5em' }} color="primary" variant="contained" size="small"
                        onClick={onClickTrackQueue}
                    >
                        Track Queue Position
                    </Button>
                </Grid>
            }
        </Grid >
    );
}