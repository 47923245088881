import React from 'react';
import { TextField, Button } from '@material-ui/core';

export default class CodeVerification extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    const { inputText, handleChange, failed, disableVerifyButton, handleSubmit } = this.props;
    return (
      <div>
        <form onSubmit={(e)=>{
          e.preventDefault();
          handleSubmit();
        }}>
          <TextField
            required 
            fullWidth
            autoFocus
            inputProps={{style: { textAlign: 'center' }}}
            InputLabelProps={{
              shrink: true,
            }}
            error={failed} 
            label='Verification Code'
            variant='outlined' 
            value={inputText} 
            onChange={handleChange}
            helperText={failed?'Verification Failed!':''}
          />
          <Button variant='contained' color='primary' fullWidth
            style={{ margin: "10px 0" }}
            disabled={disableVerifyButton}
            type="submit"
          >
            Verify
          </Button>
        </form>
      </div>
    );
  }
}