
// KeyCloak Import
const Keycloak = require('keycloak-js');
const kc_config = require('../keycloak_config');
const jwt = require('jsonwebtoken');

// Lodash/fp
const isFunction = require("lodash/fp/isFunction");

// Lodash
const _get = require('lodash/get');
const _filter = require("lodash/filter");

const { ROOT_URL } = require("../Constants");

const MINIMUM_EXPIRY_MINS = 540;


const diff_minutes = (dt2, dt1) => {

  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
  
 }

let keycloak = null;

/**
 * Get a keycloak client
 * 
 * @example
 * const Keycloak = require("./services/keycloak");
 * const keycloak = Keycloak();
 * 
 * @example
 * const Keycloak = require("./services/keycloak");
 * // specify token
 * const keycloak = Keycloak(token);
 */
export const getKeycloak = (token, refreshToken, options={}) => {
    const {
        authenticated=false,
        checkLoginIframe=false,
        promiseType="legacy",
        // onLoad: 'check-sso',
        onSuccess=() => {},
        onError=() => {},
    } = options;

    if (keycloak == null) {
        try {
            keycloak = new Keycloak(kc_config);
        } catch (error) {
            console.log("Keycloak not initialized");
            console.log(error);
        }
    }

    // if token is provided use that
    // to initialize
    if (token || refreshToken) {
        try {
            // Setup Keycloak instance as needed
            // Don't reinitialize if already initializedf
            if (!keycloak.initialized) {
                return keycloak.init({
                    token,
                    refreshToken,
                    authenticated,
                    // onLoad,
                    checkLoginIframe,
                    promiseType,
                }).success( authenticated => {
                    if (authenticated) {
                        let userInfo = keycloak.loadUserInfo();
                        userInfo.success( data => {
                            isFunction(onSuccess) && onSuccess(data);
                        }).error( err => {
                            console.log("Keycloak:  Error in getting userinfo");
                            console.log(err);
                            isFunction(onError) && onError(err);
                        })
                    }
                }).error( err => {
                    console.log("Keycloak:  Error in initializing keycloak, so logging out");
                    console.log(err);
                    isFunction(onError) && onError(err);
                    logout()
                });
            }
        } catch (error) {
            console.log("Keycloak token not set");
            console.log(error);
        }
        
        return keycloak;
    }

    return keycloak;
}

export const getTokenWithRefresh = async () => {
    let kc = getKeycloak();
    let token = kc.token;
    let decoded = jwt.decode(token, {complete: true});
    let expiry = _get(decoded.payload, "exp");
    let dateObj = new Date(expiry * 1000); 
    let currDate = new Date();
    let remaining_minutes = diff_minutes(dateObj, currDate);
    if (remaining_minutes < MINIMUM_EXPIRY_MINS){
        console.log(`Token Expiring within: ${remaining_minutes} minutes`);
        console.log("Refreshing token");
        return kc.updateToken(MINIMUM_EXPIRY_MINS*60).then((refreshed) => {
            console.log(`Token Refreshed: ${refreshed}`);
            return token;
        }).catch(() => {
            console.error('failed to refresh access token');
            return token;
        })
    }
}


export const getToken = () => {
    let kc = getKeycloak();
    let token = kc.token;
    let decoded = jwt.decode(token, {complete: true});
    let expiry = _get(decoded.payload, "exp");
    let dateObj = new Date(expiry * 1000); 
    let currDate = new Date();
    let remaining_minutes = diff_minutes(dateObj, currDate);
    if (remaining_minutes < MINIMUM_EXPIRY_MINS){
        console.log(`Token Expiring within: ${remaining_minutes} minutes`);
        console.log("Refreshing token");
        kc.updateToken(MINIMUM_EXPIRY_MINS*60).then((refreshed) => {
            console.log(`Token Refreshed: ${refreshed}`);
        }).catch(() => {
            console.error('failed to refresh access token')
          })
    }
    return token;
}

export const logout = ()=> {
    localStorage.setItem('userAuthenticated', false);
    localStorage.setItem('token', null);
    localStorage.setItem('refresh_token', null);
    getKeycloak().logout({ redirectUri:  ROOT_URL + '/login' });
}


// export {
//     getKeycloak,
//     getToken,
//     getTokenWithRefresh
// }

