import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Block from "./Block";

// Lodash/fp
import isUndefined from "lodash/fp/isUndefined";
export const consent = [
  'Consent Form',
  'Telemedicine is the use of electronic information and communication technologies by a health care provider to deliver services to an individual when he/she is located at a different site than the provider. The health care provider will complete the visit with a secure audio video call. This is a pilot program. You acknowledge your health insurance will most likely not provide reimbursement for the call. The provider will not bill the patient’s health insurance for the visit. You will be responsible directly for all payments.',
  'The electronic systems used during the telemedicine visit will incorporate network and software security protocols to protect the confidentiality of patient identification and imaging data and will include measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption.',
  'Please note that none of the telecommunication will be recorded or stored. No information obtained in the use of telemedicine will be disclosed to researchers or other entities without the patient’s consent.',
  'In rare cases, information transmitted may not be sufficient (e.g. poor resolution of images, delay in sound) to allow for appropriate medical diagnosis and decision making by the health care provider.   Delays in medical evaluation and treatment may occur due to deficiencies or failures of the equipment of the patient or the provider.',
  'You acknowledge, in very rare instances, security protocols may fail, causing a breach of privacy of personal medical information.',
  'You acknowledge, a lack of access to your health records may result in adverse drug interaction or other errors.',
  'Telemedicine in its present state, does have has some limitations (e.g. a provider will not be able to perform hands on examination) and the provider may  decide that his/her services are not sufficient for your health care needs at that moment and may either elect to not provide services through telemedicine or refer you to another provider. Controlled substances may be prescribed through telemedicine. The patient is responsible for paying the consultation fee before the visit.  The consultation fee is non-refundable except if In rare cases, information transmitted may not be sufficient (e.g. poor resolution of images, delay in sound) to allow for appropriate medical diagnosis and decision making by the health care provider.   Delays in medical evaluation and treatment may occur due to deficiencies or failures of the equipment of the patient or the provider. the telemedicine visit cannot take place because of malfunction of the telemedicine technology.',
  'I have read this consent and understand its terms. ',
  'I have a general understanding of how a telemedicine visit is conducted. I also understand the potential benefits and limitations of a telemedicine visit.',
  'I understand that a variety of alternative methods of medical care may be available to me, and that I may choose one or more of these at any time.',
  'By checking the box I hereby give my informed consent to participate in a telemedicine visit under the terms described herein.',
  'Parent/Guardian Waiver for Minors',
  'In the event the patient is under the age of consent (18 years of age) or under a disability, the parent or guardian, on behalf of the child or ward, herein provides informed consent on behalf of the child or ward to participate in a telemedicine visit under the terms described herein by checking the box appropriate box as guardian or parent.',
]
export default ( { height, onAccept, initialValue, showAccept=true }) => {
  const [checked, setChecked] = useState(initialValue || false);

  const handleChangedCheckbox = () => {
    if (!checked) {
      setChecked(true);
      onAccept(true, consent);
    }
    else {
      setChecked(false);
      onAccept(false, consent);
    }
  }
  const styles = {
    text: isUndefined(height) ? { padding: '0 2em' } : {
      overflow: "auto",
      maxHeight: height,
      padding: "0 4em 0 0",
    },
  }

    return (

      <React.Fragment>

        <Block size="h2" />

        <Typography variant="h5" style={{ textAlign: 'center'}}>
          {consent[0]}
        </Typography>


        <Block size="h2" />

        <div style={styles.text}>
          <Typography variant="body1">
            {consent[1]}
          </Typography>

          <Block size="h1" />

          <Typography variant="body1">
            {consent[2]}
          </Typography>

          <Block size="h1" />

          <Typography variant="body1">
            {consent[3]}
          </Typography>

          <Block size="h1" />


          <Typography variant="body1">
            {consent[4]}
          </Typography>

          <Block size="h1" />

          <Typography variant="body1">
            {consent[5]}
          </Typography>

          <Block size="h1" />

          <Typography variant="body1">
            {consent[6]}
          </Typography>


          <Block size="h1" />

          <Typography variant="body1">
            {consent[7]}
          </Typography>


          <Block size="h1" />

          <Typography variant="body1">
            {consent[8]}
          </Typography>


          <Block size="h1" />

          <Typography variant="body1">
            {consent[9]}
          </Typography>


          <Block size="h1" />

          <Typography variant="body1">
            {consent[10]}
          </Typography>


          <Block size="h1" />

          <Typography variant="body1">
            {consent[11]}
          </Typography>

        </div>


        <Block size="h2" />

        <Typography variant="body1" style={{fontWeight: '500', padding: "0 1.5em"}}>
          {consent[12]}
        </Typography>


        <Block size="h2" />


        <div style={styles.text}>
          <Typography variant="body1">
            {consent[13]}
          </Typography>
        </div>

        <Block size="h1" />

        { showAccept &&
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChangedCheckbox}
                name="checkedB"
                color="primary"
              />
            }
            label="I give my consent to participate"
          />
        }
          </React.Fragment>   
            )

}