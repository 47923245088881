import React from 'react';

// Material UI
import Lens from '@material-ui/icons/Lens';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

// Lodash/fp
import isPlainObject from "lodash/fp/isPlainObject";

// Lodash
import _get from "lodash/get";

/**
 * Render a Status as Circle
 * @param {{
 *      status    :   Object,
 *      center    :   boolean,
 * }} param
 */
const RenderStatus = ({ status={}, center=false, colors, }) => {
    const _status = isPlainObject(status) ? _get(status, "status") : status;
    const label = _get(status, "label", "");

    let _colors = {
        true:    green[400],
        false:   grey[400],
        default: grey[400],
    };

    if (isPlainObject(colors)) {
        _colors = Object.assign({}, _colors, colors);
    }

    const getColor = (s) => _get(
        _colors, s, _colors["default"]
    );

    const component = (
        <Tooltip title={label}>
            <Lens style={{ transform: "scale(0.50)", color: getColor(_status), margin: "0px 0px -8px -8px" }}/>
        </Tooltip>
    );

    return (
        !center ?  
            <React.Fragment>{component}</React.Fragment> :
            <Grid container justify="center">
                <Grid item>{component}</Grid>
            </Grid>
    );
}

export default RenderStatus;