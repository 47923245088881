import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton'
import Link from "@material-ui/core/Link";
import Popover from "@material-ui/core/Popover"
import InputLabel from "@material-ui/core/InputLabel"

import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";

import { Formik, Form } from "formik";

import RequestAppointmentDialog from '../Appointment/RequestAppointmentDialog'
import EmailVerification from "../blocks/EmailVerification";
import AppointmentAPI from '../../services/appointment/AppointmentRequests';
import ProviderSearchAPI from '../../services/provider_search/ProviderSearchAPI';
import ipAPI from "../../services/ip/ipAPI";
import getAutocompleteManager from '../../services/autocomplete_providers/AutocompleteManager';
import stateCodes from "us-state-codes"

import '@fontsource/noto-sans-hk';
import '@fontsource/oswald';

import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _toLower from "lodash/toLower"
import _snakeCase from "lodash/snakeCase"
import _map from "lodash/map";
import _toString from "lodash/toString";
import _uniq from "lodash/uniq";
import _size from "lodash/size";
import _concat from "lodash/concat";
import _startCase from "lodash/startCase"
import _isObject from "lodash/isObject"
import _trim from "lodash/trim"
import _includes from "lodash/includes"

import getChatClient from "../../services/chatManager";
import Nominatim from '../../services/geolocation/Nominatim';
import { SPONSORED_PROVIDER, ROOT_URL } from "../../Constants";
import { Avatar, List, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Person } from '@material-ui/icons';

const queryString = require('query-string');
const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles((theme) => ({
    root: {
        // border: '1px solid black',
        // backgroundColor: '#E6E6E6',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    inputGroup: {
        border: '1px solid #CCC',
        borderRadius: '6px',
        // maxWidth: '97ch',
        padding: '0px',
        '& .MuiGrid-item': {
            padding: '0px',
        },
        minHeight: '4.2em',
        backgroundColor: 'white',
        display: 'flex',
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    inputGroupBorderRightRadius: {
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    searchBtn: {
        marginLeft: 'auto',
        textTransform: 'none',
        minWidth: '12em',
    },
    searchBtnSm: {
        fontSize: 'x-small'
    },
    requestBtn: {
        textTransform: 'none',
        marginRight: 'auto',
        minWidth: '12em',
    },
    requestBtnSm: {
        fontSize: 'x-small'
    },
    confirmBtn: {
        marginTop: theme.spacing(4),
        textTransform: 'none',
        marginLeft: 'auto',
        minWidth: '12em',
    },
    confirmBtnSm: {
        fontSize: 'x-small'
    },
    btnGroup: {
        marginTop: theme.spacing(1),
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    dflex: {
        display: 'flex',
    },
    inputChild: {
        // maxWidth: '28ch',
        flexGrow: 1,
        padding: '0px'
    },
    inputBorder: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
    },
    bl0: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '0px',
            borderLeft: '0px',
        }
    },
    br0: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '0px',
            borderRight: '0px',
        }
    },
    rb0: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '0px',
        }
    },
    speacialityChild: {
        // maxWidth: '38ch',
        flexGrow: 10,
        padding: '0px'
    },
    summaryHeading: {
        color: '#666666',
        fontFamily: "'Noto Sans HK'",
        marginBottom: theme.spacing(2),

    },
    icon: {
        color: '#0095e2'
    },
}));


function SimpleDialog(props) {
    const classes = useStyles();
    const {
        onClose,
        open,
        matches,
        specialityList,
        specialityVal,
        locationVal,
        requestedTimeVal,
        onPrimarySubmit,
        dialogLink = "",
        searchError="",
        setSearchError,
    } = props;
    const [loadEmailVerification, setLoadEmailVerfication] = useState(false);
    const [formData, setFormData] = useState(null);

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={!loadEmailVerification} maxWidth={matches ? "lg" : "md"}>
                <div style={{display: loadEmailVerification?'none':'block'}}>
                    <DialogTitle>Request Appointment</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {
                                _isEmpty(dialogLink)
                                    ? "To request an appointment, kindly fill out the form below:"
                                    : "Appointment Request submitted successfully! You can keep track of your appointment here:"

                            }
                        </DialogContentText>
                        {
                            _isEmpty(dialogLink) ?
                                <>
                                <RequestAppointmentDialog
                                    specialityList={specialityList}
                                    specialityVal={specialityVal}
                                    locationVal={locationVal}
                                    requestedTimeVal={Date.parse(requestedTimeVal)}
                                    onPrimarySubmit={(data)=>{
                                        setFormData(data);
                                        setLoadEmailVerfication(true)
                                    }}
                                />
                                {searchError &&
                                    <p style={{color: "red", textAlign: 'center'}}>
                                        {searchError}
                                    </p>
                                }
                                </>
                                :
                                <DialogContentText>
                                    <a href={`${window.location.origin}/appointment/${dialogLink}`}>{`${window.location.origin}/appointment/${dialogLink}`}</a>
                                </DialogContentText>
                        }
                    </DialogContent>
                </div>
            {loadEmailVerification &&
                <div style={{maxWidth: '480px'}}>
                    <EmailVerification
                        email={_get(formData, 'email')}
                        phone_number={_get(formData, 'phone')}
                        onSuccess={async()=>{
                            await onPrimarySubmit(formData)
                            setLoadEmailVerfication(false)
                            window.sessionStorage.removeItem('visitor_token');
                            setSearchError('')
                        }}
                        onFailure={(reason)=>{
                            if(reason){
                                console.error(reason)
                                setLoadEmailVerfication(false)
                                setSearchError(reason)
                            }
                        }}
                        spaceOnTop={false}
                        showLoading={true}
                    />
                </div>
            }
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    matches: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

const states = [
    {name: "Alabama", code: "AL"},
    {name: "Alaska", code: "AK"},
    {name: "Arizona", code: "AZ"},
    {name: "Arkansas", code: "AR"},
    {name: "California", code: "CA"},
    {name: "Colorado", code: "CO"},
    {name: "Connecticut", code: "CT"},
    {name: "Delaware", code: "DE"},
    {name: "District of Columbia", code: "DC"},
    {name: "Florida", code: "FL"},
    {name: "Georgia", code: "GA"},
    {name: "Hawaii", code: "HI"},
    {name: "Idaho", code: "ID"},
    {name: "Illinois", code: "IL"},
    {name: "Indiana", code: "IN"},
    {name: "Iowa", code: "IA"},
    {name: "Kansas", code: "KS"},
    {name: "Kentucky", code: "KY"},
    {name: "Louisiana", code: "LA"},
    {name: "Maine", code: "ME"},
    {name: "Maryland", code: "MD"},
    {name: "Massachusetts", code: "MA"},
    {name: "Michigan", code: "MI"},
    {name: "Minnesota", code: "MN"},
    {name: "Mississippi", code: "MS"},
    {name: "Missouri", code: "MO"},
    {name: "Montana", code: "MT"},
    {name: "Nebraska", code: "NE"},
    {name: "Nevada", code: "NV"},
    {name: "New Hampshire", code: "NH"},
    {name: "New Jersey", code: "NJ"},
    {name: "New Mexico", code: "NM"},
    {name: "New York", code: "NY"},
    {name: "North Carolina", code: "NC"},
    {name: "North Dakota", code: "ND"},
    {name: "Ohio", code: "OH"},
    {name: "Oklahoma", code: "OK"},
    {name: "Oregon", code: "OR"},
    {name: "Pennsylvania", code: "PA"},
    {name: "Rhode Island", code: "RI"},
    {name: "South Carolina", code: "SC"},
    {name: "South Dakota", code: "SD"},
    {name: "Tennessee", code: "TN"},
    {name: "Texas", code: "TX"},
    {name: "Utah", code: "UT"},
    {name: "Vermont", code: "VT"},
    {name: "Virginia", code: "VA"},
    {name: "Washington", code: "WA"},
    {name: "West Virginia", code: "WV"},
    {name: "Wisconsin", code: "WI"},
    {name: "Wyoming", code: "WY"},
    ]

export default function SearchBar(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const [isLoading, setIsLoading] = useState(false);

    const {
        specialityList = [],
        onClickPrimary = () => console.log("clicked!"),
        isTelemedicine,
        setParentLoading,
        clearSearch,
    } = props;

    const [date, changeDate] = useState(new Date());

    const [searchStringValue, setSearchStringValue] = useState("")
    const [searchStringInput, setSearchStringInput] = useState("")
    
    const [locationError, setLocationError] = useState("")
    const [locationAnchorEl, setLocationAnchorEl] = useState(null)
    
    
    const [openDialog, setOpenDialog] = React.useState(false);
    const [searchError, setSearchError] = useState('');
    const [options, setOptions] = useState([])
    const [exactSearch, setExactSearch] = useState(false)
    
    const urlObj = queryString.parseUrl(_get(window, "location.href"))
    const queryObj = _get(urlObj, 'query')
    
    const [locationValue, setLocationValue] = useState(_get(queryObj, 'location') || "")
    const [specialityValue, setSpecialityValue] = useState(_get(queryObj, 'specialty') || "");
    const [specialityInput, setSpecialityInput] = useState(_get(queryObj, 'specialty') || "");
    const [usState, setUsState] = useState(_get(queryObj, 'state') || "")
    const [usStateInput, setUsStateInput] = useState(_get(queryObj, 'state') || "")
    const [searchString, setSearchString] = useState(_get(queryObj, 'name'))
    const [geoLocation, setGeoLocation] = useState({
        state: _get(queryObj, 'state')
    })

    // const [stateInputVal, setStateInputVal] = useState("")

    const autoCompleteReply = (suggestions) => {
        // console.log(suggestions)
        const profiles = _map(_get(suggestions, "data"), suggestion => {
            return suggestion
        })

        setOptions(profiles)
    }

    const updateQueryParam = (key, value) => {
        // Get the current URL
        var url = new URL(window.location.href);
    
        // Update or add the query parameter
        url.searchParams.set(key, value);
    
        // Replace the current URL without reloading the page
        
        window.history && window.history.replaceState(null, null, url.href);
    }

    useEffect(
        () => {
            if(!_isEmpty(queryObj)){
                setParentLoading(true);
            };
            (async()=>{
                if(!_get(queryObj, 'state')){
                    setParentLoading(true)
                    const ipLocation = await ipAPI.getLocationData();
                    // if location is outside USA then set state to Ohio
                    const _defaultState = "Ohio"
                    if(_get(ipLocation, 'country') != "US"){
                        setUsState(_defaultState)
                        setUsStateInput(_defaultState)
                        setGeoLocation({state: _defaultState, country: "United States"})
                    } else {
                        const region = _get(ipLocation, 'region');
                        const regions = _map(states, st => st.name)
                        if(_includes(regions, region)){
                            setUsState(region)
                            setUsStateInput(region)
                            setGeoLocation({state: region, country: "United States"})
                        } else {
                            setUsState(_defaultState)
                            setUsStateInput(_defaultState)
                            setGeoLocation({state: _defaultState, country: "United States"})
                        }
                    }
                    setParentLoading(false)
                }
            })()
            const autocompleteManager = getAutocompleteManager()
            // console.log("Autocomplete Manager: ", autocompleteManager)
            autocompleteManager.autocompleteReply(autoCompleteReply)

            setLocationAnchorEl(document.getElementById("location"));
            
            (async()=>{

                if(!_isEmpty(queryObj)){
                    setParentLoading(true);
                    await onClickPrimary({
                        searchString: _get(queryObj, 'name'),
                        location: _trim(_get(queryObj, "location")),
                        geoLocation: {
                            ...geoLocation,
                            lat: _get(queryObj, 'lat'),
                            lon: _get(queryObj, 'lon'),
                            state: _get(queryObj, 'state'),
                        },
                        exactSearch,
                    })
                    setParentLoading(false);
                }
            })()
            return () => {
                // console.log('Disconnect')
                autocompleteManager.disconnect()
            }
          
        }, []
    );
    
    // OZ: This useEffect does not give consistent results. This logic has been moved to onBlur function of usState input element
    // useEffect(() => {
    //     const delayDebounceFunction = setTimeout(async () => {
    //         const geoResult = await Nominatim.geocodeLocation(locationValue + ", USA")
    //         // console.log("Geo Result: ", geoResult)
    //         const address = _get(geoResult, "address")
    //         const country = _get(address, "country")

    //         if (_toLower(country) == "united states")
    //         {
    //             const { lat, lon } = geoResult
    //             const state = _get(address, "state")
    //             setGeoLocation({lat, lon, state, country})
    //             // console.log(state)
    //             // setUsState(state)
    //         }
    //         else
    //         {
    //             if (usState)
    //             {
    //                 setGeoLocation({state: usState, country: "United States"})
    //             }
    //             else
    //             {
    //                 setGeoLocation({})
    //             }
    //         }
    //     }, 1000)

    //     return () => clearTimeout(delayDebounceFunction)

    // }, [locationValue])

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = (value) => {
        setSearchError(false)
        setOpenDialog(false);
    };

    const [dialogLink, setDialogLink] = useState("")
    const onSubmitAppointmentRequest = async (appointment_request) => {
        setIsLoading(true);
        console.log("SUBMITTED")
        // console.log('---Appointment Request---', appointment_request);
        // TODO: Adding user to queue has been disabled for now. Uncomment if required
        // const state_code = await ProviderSearchAPI.getStateCode(_get(appointment_request, 'location'))
        // console.log('--- State_code ---', state_code);
        // const state_speciality = `${_toLower(state_code)}_${_snakeCase(_get(appointment_request, 'speciality'))}`;
        // const user = {
        //     uuid: uuidv4(),
        //     name: _get(appointment_request, 'name'),
        //     email: _get(appointment_request, 'email'),
        //     phone_number: _get(appointment_request, 'phone'),
        //     provider_specialization: _snakeCase(_get(appointment_request, 'speciality')),
        //     provider_state: _toLower(state_code),
        //     isTelemedicine: _get(appointment_request, 'type')=='TELEMEDICINE'?true:false,
        //     channel: state_speciality,
        //     tag: 'state_speciality_telemedicine',
        // }
        const chatClient = getChatClient()
        // chatClient.addToQueue(state_speciality, user);
        // console.log(appointment_request)
        let response = await ProviderSearchAPI.getProviderDataByOffset({
            location: _get(appointment_request, 'location'),
            providerType: _get(appointment_request, 'speciality'),
            limit: 5,
            entityType: _get(appointment_request, 'type')=='TELEMEDICINE'?1:2,
            offset: 0,
            isClaimed: true,
        });

        // Also get clinics from redis based on long lat
        const longitude = _get(response, 'coords.longitude');
        const latitude = _get(response, "coords.latitude");
        const clinics = await chatClient.getClinicsByLocation(longitude, latitude);
        // console.log("---clinics from redis---", clinics);

        let providers = _get(response, "data", [])
        let nearest_networks = _uniq(_map(_concat(providers, clinics), (p)=>{
            const network_id = _toString(_get(p, 'network_id'))
            return network_id;
        }))
        // Add network_id of sponsored test provider
        nearest_networks = _concat(nearest_networks, _get(SPONSORED_PROVIDER, 'network_id'));
        if(_size(nearest_networks) == 0){
            console.error('No available networks found');
            setSearchError('No available providers found near you.')
            setIsLoading(false);
            return;
        }
        const data = await AppointmentAPI.createAppointmentRequest({
            ...appointment_request,
            near: _get(appointment_request, "location"),
            nearest_networks, // backend will use this array to advertise this appointment to these networks
        })
        if(_isEmpty(_get(data, 'uuid'))){
            setIsLoading(false);
            setSearchError('Failed to create appointment request')
            return;
        }
        setDialogLink(_get(data, "uuid"))
        setIsLoading(false)
    }

    const onClickLocIcon = () => {
        setLocationError("")
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async(pos) => {
                setParentLoading(true);
                const data = await Nominatim.reverseGeocode(_get(pos, 'coords.latitude'), _get(pos, 'coords.longitude'));
                const address = _get(data, 'address')
                if(_get(address, 'country_code') == 'us'){
                    setLocationValue(_get(address, 'postcode') || "");
                    setUsState(_get(address, "state"))
                    setUsStateInput(_get(address, 'state'))
                } else {
                    // TODO: Set location error outside USA
                    setLocationValue(`${address?.postcode}, ${address?.city}, ${address?.country}`)
                    setLocationError("Location not found in USA.")
                }
                setParentLoading(false);
            });
        }
    }

    return (
        <React.Fragment>
            <Formik
                id="main-formik-form"
                initialValues={{
                    location: locationValue,
                    geoLocation,
                    speciality: specialityValue,
                    searchString: searchString,
                    requested_time: date.toISOString(),
                    exactSearch,
                }}
                onSubmit={async(values) => {
                    setLocationError("")
                    setParentLoading(true);
                    clearSearch();
                    let latitude, longitude, state;
                    if(_trim(locationValue)){

                        const geoResult = await Nominatim.geocodeLocation(_trim(locationValue) + ", USA")
                        // console.log("Geo Result: ", geoResult)
                        const address = _get(geoResult, "address")
                        const country = _get(address, "country")
                        if (_toLower(country) == "united states")
                        {
                            const {lat, lon} = geoResult
                            latitude = lat;
                            longitude = lon
                            state = _get(address, "state")
                            if(state){
                                setUsState(state)
                                setUsStateInput(state);
                            }
                        }
                        else {
                            setLocationError("Location not found in USA.")
                            setParentLoading(false);
                            return;
                        }
                    }

                    console.log({
                        ...values,
                        location: _trim(locationValue),
                        geoLocation: {
                            ...geoLocation,
                            lat: latitude,
                            lon: longitude,
                            state: state?state:geoLocation.state,
                        },
                        exactSearch,
                    });
                    await onClickPrimary({
                        ...values,
                        location: _trim(locationValue),
                        geoLocation: {
                            ...geoLocation,
                            lat: latitude,
                            lon: longitude,
                            state: state?state:geoLocation.state,
                        },
                        exactSearch,
                    })

                    updateQueryParam("name", values.searchString)
                    updateQueryParam("location", _trim(locationValue) || "")
                    updateQueryParam("specialty", values.speciality || "")
                    updateQueryParam("state", state?state:geoLocation.state)
                    updateQueryParam("lat", latitude || "")
                    updateQueryParam("lon", longitude || "")
                }}
                autoComplete="off"
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                }) => {
                    

    // console.log("Values: ", values)
    return (
        <Form>
                        <Container component="main" maxWidth="xl" disableGutters className={classes.root} style={{
                            padding: matches?theme.spacing(2):theme.spacing(4),
                            paddingTop: matches?theme.spacing(2):theme.spacing(4),
                            paddingBottom: matches?theme.spacing(2):theme.spacing(4),
                        }}>
                            {!matches &&                            
                                <Popover
                                    open={Boolean(locationError)}
                                    anchorEl={locationAnchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    onClose={()=>{
                                        setLocationError("")
                                    }}
                                >
                                    <p style={{color: "red", padding:"0 1em"}}>{locationError}</p>
                                </Popover>
                            }
                            <Grid container direction="row" justify="flex-end" spacing={2}>
                                <Grid item>
                                    <Typography style={{color: "black", marginTop: "0.5em"}}>State: </Typography>
                                </Grid>
                                <Grid item xs={4} md={1} 
                                    // className={clsx(classes.speacialityChild, classes.inputGroup)} 
                                    style={{marginBottom: "1.5em"}}
                                >
                                        <Autocomplete
                                            id="usState"
                                            clearOnBlur={false}
                                            autoSelect
                                            options={states}
                                            getOptionSelected={(option, value) => option?.name == value}
                                            getOptionLabel={(option) => _get(option, "name") || ""}
                                            renderInput={(params) => <TextField {...params} required fullWidth placeholder="State" inputProps={{ ...params.inputProps, autoComplete: 'off' }} />}
                                            inputValue={usStateInput || ""}
                                            onInputChange={(event, newInputValue) => {
                                                // console.log("onInputChange(): ", newInputValue)
                                                if(newInputValue){
                                                    setUsStateInput(newInputValue)
                                                    setGeoLocation((prevState) => ({...prevState, state: newInputValue, country: 'United States'}))
                                                }
                                            }}
                                            // className={clsx(classes.inputBorder, classes.br0)}
                                            // style={{ width: '38ch' }}
                                            style={{ width: '100%' }}
                                            value={usState || ""}
                                            onChange={(event, newValue) => {
                                                // console.log("onChange(): ", newValue)
                                                setUsState(_get(newValue, "name"))
                                                setUsStateInput(_get(newValue, "name"))
                                                setGeoLocation((prevState) => ({...prevState, state: _get(newValue, "name"), country: 'United States'}))
                                            }}
                                        />
                                    </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="column">
                                <Grid item container spacing={0} direction="row" className={classes.inputGroup} justify="space-between">
                                    <Grid item xs={12} md={4} className={classes.speacialityChild}>
                                        <Autocomplete
                                            id="name"
                                            autoFocus
                                            freeSolo
                                            options={options}
                                            getOptionLabel={option => _get(option, "display_name") || values.searchString || ""}
                                            renderOption={
                                                option => {
                                                    return (
                                                        <>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <Person className={classes.icon}/>
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                            primary={_get(option, "display_name") || ""}
                                                            secondary={`${_get(option, "specialty") || ""} • ${_startCase(_snakeCase(_get(option, "city"))) + ", " || ""} ${_get(option, "state")}`}
                                                            />
                                                        </>
                                                    )
                                                }
                                            }
                                            renderInput={(params) => <TextField {...params} variant="outlined" fullWidth placeholder="Name" inputProps={{ ...params.inputProps, autoComplete: 'off' }} />}
                                            inputValue={searchStringInput}
                                            onInputChange={(event, newInputValue) => {
                                                
                                                // console.log("New value: ", newInputValue)
                                                setExactSearch(false)
                                                if(isTelemedicine){
                                                    const autocompleteManager = getAutocompleteManager()
                                                    const spec = specialityValue ? [specialityValue] : []
                                                    const stateCode = stateCodes.getStateCodeByStateName(usState)
                                                    autocompleteManager.autocompleteSearch({q: newInputValue, ...geoLocation, specialization: spec, state: stateCode})
                                                }
                                                    setSearchStringInput(newInputValue);
                                                    const newEvent = {
                                                        target: {
                                                            name: 'searchString',
                                                            value: _isObject(newInputValue) ? _get(newInputValue, "display_name") : newInputValue || "",
                                                        }
                                                    }
                                                    handleChange(newEvent);
                                            }}
                                            value={values.searchString}
                                            className={clsx(classes.inputBorder, classes.br0)}
                                            // style={{ width: '38ch' }}
                                            style={{ width: '100%' }}
                                            onChange={(event, newValue) => {
                                                _isObject(newValue) ? setExactSearch(true) : setExactSearch(false)
                                                // console.log('New Value: ', newValue)
                                                setSearchStringValue(newValue);
                                                // console.log("Value: ", _get(newValue, "display_name"))
                                                const newEvent = {
                                                    target: {
                                                        name: 'npi',
                                                        value: _isObject(newValue) ? _get(newValue, "npi") : newValue || "",
                                                    }
                                                }
                                                handleChange(newEvent);
                                            }}
                                        />
                                    </Grid>
                                    {
                                        !matches &&
                                        <Grid item>
                                            <Divider orientation="vertical" />
                                        </Grid>
                                    }
                                    <Grid item xs={6} md={4} className={classes.inputChild} style={{borderTop: matches?"1px solid #CCC":"", borderRight: matches?"1px solid #CCC":"",}}>
                                        <TextField
                                            id="location"
                                            placeholder="Near eg. 58917"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={onClickLocIcon}>
                                                            <NearMeOutlinedIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className={clsx(classes.inputBorder, classes.rb0)}
                                            value={locationValue}
                                            onChange={(event) => {
                                                setLocationError("")
                                                setLocationValue(event.target.value)
                                                const newEvent = {
                                                    target: {
                                                        name: 'location',
                                                        value: event.target.value,
                                                    }
                                                }
                                                handleChange(newEvent);
                                            }}
                                            style={{ width: '100%' }}
                                            onBlur={async () => {
                                                if(locationValue){
                                                    setParentLoading(true)
                                                    const geoResult = await Nominatim.geocodeLocation(locationValue + ", USA")
                                                    // console.log("Geo Result: ", geoResult)
                                                    const address = _get(geoResult, "address")
                                                    const country = _get(address, "country")
                                        
                                                    if (_toLower(country) == "united states")
                                                    {
                                                        const { lat, lon } = geoResult
                                                        const state = _get(address, "state")
                                                        setGeoLocation({lat, lon, state, country})
                                                        // console.log(state)
                                                        if(state){
                                                            setUsStateInput(state)
                                                            setUsState(state)
                                                        }
                                                    }
                                                    else
                                                    {
                                                        if (usState)
                                                        {
                                                            setGeoLocation({state: usState, country: "United States"})
                                                        }
                                                        else
                                                        {
                                                            setGeoLocation({})
                                                        }
                                                    }
                                                    setParentLoading(false)
                                                } else {
                                                    setGeoLocation({
                                                        ...geoLocation,
                                                        lat: null,
                                                        lon: null
                                                    })
                                                }
                                            }}
                                            error={matches && Boolean(locationError)}
                                            helperText={matches && locationError}
                                        />
                                    </Grid>
                                    {
                                        !matches &&
                                        <Grid item>
                                            <Divider orientation="vertical" />
                                        </Grid>
                                    }
                                    <Grid item xs={6} md={3} className={classes.speacialityChild} style={{borderTop: matches?"1px solid #CCC":""}}>
                                        <Autocomplete
                                            id="speciality"
                                            autoSelect
                                            options={specialityList}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => <TextField {...params} variant="outlined" fullWidth placeholder="Speciality" inputProps={{ ...params.inputProps, autoComplete: 'off' }} />}
                                            inputValue={specialityInput}
                                            onInputChange={(event, newInputValue) => {
                                                setSpecialityInput(newInputValue);
                                            }}
                                            value={specialityValue}
                                            className={clsx(classes.inputBorder, classes.br0)}
                                            // style={{ width: '38ch' }}
                                            style={{ width: '100%' }}
                                            onChange={(event, newValue) => {
                                                setSpecialityValue(newValue);
                                                const newEvent = {
                                                    target: {
                                                        name: 'speciality',
                                                        value: newValue,
                                                    }
                                                }
                                                handleChange(newEvent);
                                            }}
                                        />
                                    </Grid>
                                    {
                                        !matches &&
                                        <Grid item>
                                            <Divider orientation="vertical" />
                                        </Grid>
                                    }
                                    {/* Show the search icon button only on larger screens */}
                                    {!matches &&
                                        <Grid item>
                                            <IconButton onMouseDown={handleSubmit} type="submit" style={{verticalAlign: "text-top", marginRight: "0.5em"}}>
                                                <SearchIcon color="primary"/>
                                            </IconButton>
                                        </Grid>

                                    }
                                    
                                    {/* {
                                        !matches &&
                                        <Grid item>
                                            <Divider orientation="vertical" />
                                        </Grid>
                                    }
                                    <Grid item className={classes.inputChild}>
                                        <MuiPickersUtilsProvider utils={MomentUtils} >
                                            <KeyboardDatePicker
                                                id="requested_time"
                                                autoOk
                                                orientation="landscape"
                                                disablePast={true}
                                                openTo="date"
                                                value={date}
                                                onChange={(event) => {
                                                    changeDate(event)
                                                    const newEvent = {
                                                        target: {
                                                            name: 'requested_time',
                                                            value: date.toISOString(),
                                                        }
                                                    }
                                                    handleChange(newEvent);
                                                }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                InputAdornmentProps={{ position: "end" }}
                                                className={clsx(classes.inputBorder, classes.bl0)}
                                                // style={matches ? { minWidth: '40ch' } : { minWidth: '29ch' }}
                                                style={{ width: '100%', paddingRight: '0px' }}
                                                format="ddd, MMM DD"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid> */}
                                </Grid>
                                {/* Show Search Providers button on mobiles only */}
                                {matches &&
                                
                                    <Grid item container direction="row" spacing={2} className={classes.btnGroup}>
                                        <Grid item 
                                            // set xs={6} when using two buttons
                                            xs={12} 
                                            className={classes.dflex}
                                            // Remove grid and justifyContent when displaying two buttons side by side
                                            style={{display: "grid", justifyContent: "center"}}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onMouseDown={handleSubmit}
                                                type="submit"
                                                className={matches ? clsx(classes.searchBtnSm, classes.searchBtn) : classes.searchBtn}
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                        {/* <Grid item xs={6} className={classes.dflex}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={matches ? clsx(classes.requestBtnSm, classes.requestBtn) : classes.requestBtn}
                                                onClick={handleDialogOpen}
                                            >
                                                Request Appointment
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                }
                                {/* <Grid item container direction="row" spacing={2} justify="center">
                                    <Grid item xs={12} style={{textAlign: "center", marginTop: "1em", marginLeft: "1em"}}>
                                        <Link
                                            component="button"
                                            color="textSecondary"
                                            onClick={()=>{
                                                const url = `${ROOT_URL}/patient-network-transfer`
                                                window.open(url, '_blank');
                                            }}
                                        >
                                            Transfer My Record
                                        </Link>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Container>
                    </Form>
    );
                }
                    
                
                }
            </Formik >
            <SimpleDialog
                open={openDialog}
                matches={matches}
                onClose={handleDialogClose}
                onSubmit={onSubmitAppointmentRequest}
                specialityList={specialityList}
                specialityVal={specialityValue}
                locationVal={locationValue}
                requestedTimeVal={date}
                onPrimarySubmit={async(data)=>{
                    setSearchError('');
                    await onSubmitAppointmentRequest(data);
                }}
                dialogLink={dialogLink}
                searchError={searchError}
                setSearchError={setSearchError}
            />
            <Backdrop style={{ color: "#fff", zIndex: 10000 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}