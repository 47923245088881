// Lodash/fp
import get from "lodash/fp/get";

// lodash
import _toString from "lodash/toString";

// Project
import { NETWORK_ENDPOINT } from "../../Constants";

/**
 * Create a network client
 * @param {*} network_client
 */
export const createNetworkClient = async (network_client, token) => {
    // console.log(network_client);

    const response = await fetch(
        `${NETWORK_ENDPOINT}/create-network-client`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(network_client)
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not create network-client for ${get("network_id")(network_client)}`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}


/**
 * Get a network client
 * @param {*} uuid - Network client uuid
 */
export const getNetworkClient = async (uuid, token) => {

    const response = await fetch(
        `${NETWORK_ENDPOINT}/get-network-client`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                uuid,
            })
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not get network_client ${uuid}`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

export const searchNetworkClientByKV = async (network_client, key, value, signal, token = null) => {
    const response = await fetch(
        `${NETWORK_ENDPOINT}/search-network-client-by-kv`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                network_id: _toString(network_client),
                key,
                value
            }),
            signal,
        }
    );

    const body = await response.json();

    if (get("status")(body) != 200) {
        console.log(`Could not search network_client`);
        console.log(get("message")(body));
    }

    const data = get("data")(body);

    return data;
}

export const searchNetworkClient = async (network_id, name, dob, token) => {
   
    const response = await fetch(
        `${NETWORK_ENDPOINT}/search-network-client`,
        {
             method: "POST",
             headers: {
                 "Content-Type": "application/json",
                 "Authorization": token ? `Bearer ${token}` : "",
             },
             body: JSON.stringify({
                 network_id,
                 name,
                 dob,
             })
        }
    );
 
    const body = await response.json();
 
    if (get("status")(body) != 200) {
        console.log(`Could not search for network_client: ${network_id}, ${name}, ${dob}`);
        console.log(get("error")(body));
    }
 
    const data = get("data")(body);
 
    return data;
 }

 export const updateNetworkClientOrder = async (uuid, token) => {
   
    const response = await fetch(
        `${NETWORK_ENDPOINT}/update-network-client-order`,
        {
             method: "POST",
             headers: {
                 "Content-Type": "application/json",
                 "Authorization": token ? `Bearer ${token}` : "",
             },
             body: JSON.stringify({
                uuid,
             })
        }
    );
 
    const body = await response.json();
 
    if (get("status")(body) != 200) {
        console.log(`Could not update network_client (${uuid})`);
        console.log(get("error")(body));
    }
 
    const data = get("data")(body);
 
    return data;
 }

 export const updateInsuranceVerificationFlag = async (uuid, is_manually_verified, token) => {
   
    const response = await fetch(
        `${NETWORK_ENDPOINT}/update-insurance-verification-flag`,
        {
             method: "POST",
             headers: {
                 "Content-Type": "application/json",
                 "Authorization": token ? `Bearer ${token}` : "",
             },
             body: JSON.stringify({
                network_client_uuid: uuid,
                is_manually_verified,
             })
        }
    );
 
    const body = await response.json();
 
    if (get("status")(body) != 200) {
        console.log(`Could not update network_client (${uuid})`);
        console.log(get("error")(body));
    }
 
    const data = get("data")(body);
 
    return data;
 }
export default {
    getNetworkClient,
    createNetworkClient,
    searchNetworkClientByKV,
    searchNetworkClient,
    updateNetworkClientOrder,
    updateInsuranceVerificationFlag,
}