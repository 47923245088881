// Lodash/fp
import get from "lodash/fp/get";
import fetch from "node-fetch";
import _get from "lodash/get";

// Project
import {
    PROVIDER_SEARCH_ENDPOINT,
} from "../../Constants";

import { getKeycloak } from '../keycloak';
const token = getKeycloak().token || localStorage.getItem('token');

const getProviderData = async ({
    providerType,
    zipcode,
    entityType,
}) => {
    const response = await fetch(
        `${PROVIDER_SEARCH_ENDPOINT}/get-nearest-data`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                providerType,
                address: {
                    isZipcode: true,
                    location: zipcode
                },
                entityType,
                radius: 3,
                name: {
                    isPresent: false
                }
            })
        }
    );

    try {
        const body = await response.json();
        const data = get("rows")(body);

        return data;
    } catch {
        return null;
    }
}

const getMapCenter = async ({
    zipcode,
}) => {
    try {
        const response = await fetch(
            `${PROVIDER_SEARCH_ENDPOINT}/get-lat-lon`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    address: zipcode
                })
            }
        );

        const data = await response.json();

        return data;
    } catch (e) {
        console.log(e)
        return null
    }
}

const getLocationCoordinates = async () => {
    const getPosition = function (options) {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    const position = await getPosition()

    return {
        latitude: _get(position, "coords.latitude"),
        longitude: _get(position, "coords.longitude"),
    }
}

const getProviderDataByOffset = async ({
    providerType,
    location,
    entityType,
    limit=10,
    radius=3,
    offset,
    isClaimed = null,
}) => {

    const test_coords = {
        latitude: 29.19,
        longitude: -81.05,
    }

    const response = await fetch(
        `${PROVIDER_SEARCH_ENDPOINT}/get-providers-by-search-string`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                searchString: providerType,
                entityType,
                offset,
                limit,
                radius,
                // location: location == "Near You" ? test_coords : location,
                location: location == "Near You" ? await getLocationCoordinates() : location,
                isClaimed,
            })
        }
    );

    try {
        const data = await response.json();
        return data;
    } catch {
        return null;
    }
}
// const getProviderDataByOffset = async ({
//     providerType,
//     location,
//     entityType,
//     offset,
// }) => {
//     const response = await fetch(
//         `${PROVIDER_SEARCH_ENDPOINT}/get-limited-nearest-data`,
//         {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//                 providerType,
//                 address: {
//                     isZipcode: true,
//                     location: location
//                 },
//                 entityType,
//                 radius: 3,
//                 name: {
//                     isPresent: false
//                 },
//                 offset,
//                 limit: 10,
//             })
//         }
//     );

//     try {
//         console.log(response);

//         const body = await response.json();
//         const data = get("rows")(body);

//         return data;
//     } catch {
//         return null;
//     }
// }

const fetchSpecialityList = async () => {
    const response = await fetch(
        `${PROVIDER_SEARCH_ENDPOINT}/get-provider-types`,
        {
            method: "POST",
        }
    );

    try {
        const body = await response.json();
        const data = get("rows")(body);

        return data;
    } catch {
        return null;
    }
}

// Given a location get the state code
const getStateCode = async(location)=>{
    try{
        const response = await fetch(
            `${PROVIDER_SEARCH_ENDPOINT}/get-address-details`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    location: location == "Near You" ? await getLocationCoordinates() : location,
                })
            }
        );
        const body = await response.json();
        if(_get(response, 'status') == 200){
            const state_code = _get(body, 'stateCode');
            return state_code;
        } else {
            console.error('Failed to get stateCode from ProviderSearchAPI');
            return null;
        }
    } catch(e){
        console.error(e);
        return null;
    }
}

// Given a location get geocoded address
const getGeocodedAddress = async(location)=>{
    try{
        const response = await fetch(
            `${PROVIDER_SEARCH_ENDPOINT}/get-address-details`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    location: location == "Near You" ? await getLocationCoordinates() : location,
                })
            }
        );
        if(_get(response, 'status') == 200){
            const body = await response.json();
            return body;
        } else {
            console.error('Failed to get stateCode from ProviderSearchAPI');
            return null;
        }
    } catch(e){
        console.error(e);
        return null;
    }
}



export default {
    getProviderData,
    getMapCenter,
    getProviderDataByOffset,
    fetchSpecialityList,
    getStateCode,
    getGeocodedAddress,
}