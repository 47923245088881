import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BusinessIcon from '@material-ui/icons/Business';
import CardActionArea from '@material-ui/core/CardActionArea';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MessageIcon from '@material-ui/icons/Message';
import CheckIcon from '@material-ui/icons/Check';
import WebIcon from '@material-ui/icons/Web';
import DirectionsIcon from '@material-ui/icons/Directions';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CallIcon from '@material-ui/icons/Call';
import IconButton from '@material-ui/core/IconButton'

import RenderStatus from '../status/RenderStatus';

import PhoneNumber from "awesome-phonenumber"

import '@fontsource/noto-sans-hk';
import '@fontsource/oswald';

// lodash 
const _replace = require("lodash/replace");
const _isEmpty = require("lodash/isEmpty");
const _round = require("lodash/round")

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        // marginTop: theme.spacing(2),
        // paddingBottom: theme.spacing(2),
    },
    header: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontFamily: "'Noto Sans HK'",
    },
    avatar: {
        height: '75px',
        width: '75px',
        margin: 'auto',
    },
    name: {
        fontFamily: "'Noto Sans HK'",
    },
    address: {
        fontFamily: "'Noto Sans HK'",
        color: 'gray',
    },
    noTransform: {
        textTransform: 'none',
        fontFamily: 'Helvetica'
    },
    nameHead: {
        fontWeight: 'bold',
    },
    selectBox: {
        borderTop: '1px solid black',
        fontFamily: "'Noto Sans HK'",
        width: '70%',
        marginTop: theme.spacing(2),
    },
    slotStatus: {
        textTransform: 'uppercase',
        color: "#3498FC",
        marginLeft: theme.spacing(4),
    },
    menuItem: {
        fontFamily: "'Noto Sans HK'",
    },
    centerItems: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    number: {
        fontFamily: "'Noto Sans HK'",
        textAlign: 'center',
        textTransform: 'none',
        color: '#808080',
    },
    businessIcon: {
        fontSize: "200%"
    },
    BtnContainerSm: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    borderBottom: {
        borderBottom: '1px solid #CCCCCC',
    },
    footer: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footIconContainer: {
        fontSize: 'large',
        margin: 'auto'
    },
    fontIconContainerSm: {
        margin: 'auto',
        fontSize: '12px',
    },
    footIcon: {
        fontSize: '140%',
    },
    footText: {
        margin: 'auto',
        textAlign: 'center'
    },
    addButton: {
        fontFamily: "'Noto Sans HK'",
        textTransform: 'none',
    },
    chatButton: {
        margin: 'auto',
        marginTop: theme.spacing(2),
        fontFamily: "'Noto Sans HK'",
        textTransform: 'none',
        width: '18em',
    },
    iconRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        margin: theme.spacing(1),
    },
    roundIcon: {
        border: '1px solid black',
    },
    iconText: {
        marginTop: theme.spacing(1),
    },
    speciality: {
        fontFamily: "'Noto Sans HK'",
        color: 'gray',
    },
}));

export default function InpersonItem(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        name = "The Practice at Worthington",
        address_line1 = "",
        address_line2 = "",
        state_code = "",
        phone = "",
        onCardClick = () => console.log("Clicked!"),
        onChatClick = () => { console.log('onChatClick is not defined') },
        onClickAddToQueue = () => { console.log('onClickAddToQueue is not defined') },
        onClickRemoveFromQueue = () => { console.log('onClickRemoveFromQueue is not defined') },
        queuePosition=0,
        queueSize = 0,
        isUserInQueue = false,
        isUserInMyQueue = false,
        isOnline = false,
        isNetworkPresent,
        showMessageChange,
        showDistance = false,
        distance = 0.0,
        speciality = "Primary Care",
        image="",
        showQueue=false,
        showChat=false,
    } = props;

    const [inQueue, setInQueue] = useState(false);

    const [timeSlot, setTimeSlot] = React.useState("Monday:  10AM - 8PM");

    
    const handleMenuChange = event => {
        setTimeSlot(event.target.value);
    };

    const meter2miles = (distance) => {
        const meter_float = parseFloat(distance);
        const miles_dist = meter_float / 1609.34;
        
        return miles_dist.toFixed(2);
    }

    const getFormattedPhoneNumber = (phone)=>{
        try {
            const phoneObj = new PhoneNumber(phone, "US")
            return phoneObj.getNumber("national")

        } catch(e){
            console.error(e)
            return phone
        }
    }
    const formattedPhoneNumber = getFormattedPhoneNumber(phone);

    return (
        <Grid container spacing={0} direction="row" className={classes.root}>
            {/* <Grid item>
                <Typography className={classes.header}>
                    <span className={classes.nameHead}>{name}</span> &#8226; {address_line2}
                </Typography>
            </Grid> */}
            <Grid item xs={12} container direction="row">
                <Grid item xs={matches ? 3 : 2} style={{ display: 'flex' }}>
                    <Avatar variant="square" src={image} className={classes.avatar} style={matches ? { height: '50px', width: '50px', objectFit: "contain" } : null}
                        imgProps={{
                            style: { objectFit: "contain" }
                        }}
                    >
                        <BusinessIcon size="large" className={classes.businessIcon} />
                    </Avatar>
                </Grid>
                <Grid item xs={matches ? 9 : 6} container direction="column">
                    <CardActionArea onClick={onCardClick} style={{marginBottom: "0.5em"}}>
                        <Typography variant="h6" className={classes.name}>
                            {name}
                        </Typography>
                        <Typography variant="body1" className={classes.speciality}>
                            {speciality}
                        </Typography>
                        <Typography variant="body1" className={classes.address}>
                            {address_line1}
                        </Typography>
                        <Typography variant="body2" className={classes.address}>
                            {`${address_line2}, ${state_code}`}
                        </Typography>
                        {
                            showDistance &&
                            <Typography variant="body2" className={classes.address}>
                                {`${_round(distance, 2)} miles away from you`}
                            </Typography>
                        }
                    </CardActionArea>
                    {/* <Select
                        className={classes.selectBox}
                        inputProps={{ 'aria-label': 'age' }}
                        value={timeSlot}
                        onChange={handleMenuChange}
                    >
                        <MenuItem className={classes.menuItem} value="Monday:  10AM - 8PM">Monday:  10AM - 8PM<span className={classes.slotStatus}>Open</span></MenuItem>{" "}
                        <MenuItem className={classes.menuItem} value="Tuesday:  10AM - 8PM">Tuesday:  10AM - 8PM<span className={classes.slotStatus}>Open</span></MenuItem>{" "}
                    </Select> */}
                    {/* <div className={classes.iconRow}>
                        <div style={{ textAlign: 'center' }}>
                            <IconButton className={classes.roundIcon}>
                                <ChatBubbleOutlineIcon />
                            </IconButton>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant="body2" className={clsx(classes.address, classes.iconText)}>
                                    Chat
                                </Typography>
                                <RenderStatus />
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <IconButton className={classes.roundIcon}>
                                <DirectionsIcon />
                            </IconButton>
                            <Typography variant="body2" className={clsx(classes.address, classes.iconText)}>
                                Directions
                            </Typography>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <IconButton className={classes.roundIcon}>
                                <WebIcon />
                            </IconButton>
                            <Typography variant="body2" className={clsx(classes.address, classes.iconText)}>
                                Website
                            </Typography>
                        </div>
                    </div> */}
                </Grid>
                {
                    !matches &&
                    <Grid item container xs={4} spacing={2} direction="column" className={classes.centerItems} justify="center">
                        {/* <Grid item style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <Button variant="contained" color="primary" className={classes.noTransform}>
                                Request Appointment
                            </Button>
                        </Grid> */}
                        <Grid item style={{ paddingTop: '0px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Button className={classes.number} href={`tel:+1${_replace(phone, /\D/g, '')}`}>
                                <CallIcon size="small"/>&nbsp;{formattedPhoneNumber}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
            {
                matches &&
                <Grid item container xs={12} spacing={2} direction="row" className={clsx(classes.centerItems, classes.BtnContainerSm)} style={{justifyContent: "center"}}>
                    {/* <Grid item xs={6}>
                        <Button variant="contained" color="primary" className={classes.noTransform} style={{ fontSize: '11px' }}>
                            Request Appointment
                        </Button>
                    </Grid> */}
                    <Grid item xs={6}>
                        <Button className={classes.number} href={`tel:+1${_replace(phone, /\D/g, '')}`} style={{ fontSize: '11px' }}>
                            <CallIcon size="small"/>&nbsp;{formattedPhoneNumber}
                        </Button>
                    </Grid>
                </Grid>
            }
            {isNetworkPresent && showQueue &&

                <Grid item xs={12} container direction="row" className={clsx(classes.footer, classes.borderBottom)}>
                    <Grid item xs={matches ? 3 : 2} container direction="row">
                        <Grid item className={matches ? classes.fontIconContainerSm : classes.footIconContainer}>
                            <PeopleAltIcon className={classes.footIcon} />
                        </Grid>
                        <Grid item className={classes.footText} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="overline" className={classes.address}>
                                {isUserInMyQueue?'POSITION':'WAITING'}
                            </Typography>
                            <Typography variant="body2" className={classes.address} style={matches ? { fontSize: '11px', fontWeight: 'bolder' } : { fontSize: 'large' }}>
                                {isUserInMyQueue?queuePosition:queueSize}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item><Divider orientation="vertical" variant="middle" /></Grid>

                    <Grid item xs={matches ? 3 : 2} container direction="row">
                        <Grid item className={matches ? classes.fontIconContainerSm : classes.footIconContainer}>
                            <HourglassEmptyIcon className={classes.footIcon} />
                        </Grid>
                        <Grid item className={classes.footText} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="overline" className={classes.address}>
                                EST. WAIT
                            </Typography>
                            <Typography variant="body2" className={classes.address} style={matches ? { fontSize: '11px', fontWeight: 'bolder' } : { fontSize: 'large' }}>
                                {queueSize * 10}
                            </Typography>
                        </Grid>
                    </Grid>

                    {
                        matches
                            ? <Grid item><Divider orientation="vertical" variant="middle" /></Grid>
                            : <Grid item xs={4}></Grid>
                    }

                    <Grid item xs={3} container direction="row">
                        {
                            isUserInMyQueue ?
                                <>
                                    <Grid item className={classes.footText}>
                                        <Typography variant="body2" className={classes.address} style={matches ? { fontSize: '11px' } : null}>
                                            In Queue
                                        </Typography>
                                    </Grid>
                                    <Grid item style={matches ? { margin: 'auto' } : { margin: 'auto 0 auto 2px' }}>
                                        <Button color="secondary" size="small" variant="outlined" onClick={() => {
                                            onClickRemoveFromQueue();
                                        }}>
                                            Exit
                                        </Button>
                                    </Grid>
                                </>
                                :
                                <Button
                                    startIcon={<AddCircleOutlineIcon className={classes.footIcon} />}
                                    className={classes.addButton}
                                    style={matches ? { fontSize: '11px' } : null}
                                    onClick={onClickAddToQueue}
                                >
                                    Add to Queue
                                </Button>
                        }
                    </Grid>
                </Grid>
            }
            {isNetworkPresent && showChat &&
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MessageIcon />}
                        className={classes.chatButton}
                        style={matches ? { fontSize: '11px' } : null}
                        onClick={onChatClick}
                    >
                        {showMessageChange ? 'Unread Message(s)' : 'Chat'}
                        <div style={{ margin: '0 0 0.1em 0.5em', display: 'inline-block' }}>
                            <RenderStatus status={isOnline} />
                        </div>
                    </Button>
                </Grid>
            }
        </Grid >
    );
}