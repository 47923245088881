import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import IconButton from '@material-ui/core/IconButton'
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";

import { Formik, Form } from "formik";

import '@fontsource/noto-sans-hk';
import '@fontsource/oswald';
import { getThemeProps } from '@material-ui/styles';


const _isFunction = require("lodash/isFunction");
const _get = require("lodash/get");
const _isDate = require("lodash/isDate");

const useStyles = makeStyles((theme) => ({
    root: {
        // border: '1px solid black',
        // backgroundColor: '#E6E6E6',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        width: '100%',
        '& .MuiGrid-item': {
            padding: '0px',
        },
    },
    inputGroup: {
        border: '1px solid black',
        borderRadius: '6px',
        padding: '0px',
        '& .MuiGrid-item': {
            padding: '0px',
        },
        minHeight: '4.2em',
        backgroundColor: 'white',
        display: 'flex',
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    inputGroupBorderRightRadius: {
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    searchBtn: {
        marginLeft: 'auto',
        textTransform: 'none',
        minWidth: '12em',
    },
    searchBtnSm: {
        fontSize: 'x-small'
    },
    requestBtn: {
        textTransform: 'none',
        marginRight: 'auto',
        minWidth: '12em',
    },
    requestBtnSm: {
        fontSize: 'x-small'
    },
    confirmBtn: {
        marginTop: theme.spacing(4),
        textTransform: 'none',
        marginLeft: 'auto',
        minWidth: '12em',
    },
    confirmBtnSm: {
        fontSize: 'x-small'
    },
    btnGroup: {
        marginTop: theme.spacing(4),
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    dflex: {
        display: 'flex',
    },
    inputChild: {
        // maxWidth: '28ch',
        flexGrow: 1,
        padding: '0px'
    },
    inputBorder: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    bl0: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '0px',
            borderLeft: '0px',
        }
    },
    br0: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '0px',
            borderRight: '0px',
        },
    },
    rb0: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '0px',
        }
    },
    speacialityChild: {
        // maxWidth: '38ch',
        flexGrow: 10,
        padding: '0px'
    },
    summaryHeading: {
        color: '#666666',
        fontFamily: "'Noto Sans HK'",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),

    },
    toggleBtnContainer: {
        // marginLeft: theme.spacing(4),
        // marginLeft: 'auto',
        marginBottom: theme.spacing(4),
    },
    noTextStyle: {
        textTransform: "none",
    },
    toggleLabel: {
        color: '#666666',
        fontFamily: "'Noto Sans HK'",
        marginBottom: theme.spacing(1),
    },
    toggleBtn: {
        fontFamily: "'Noto Sans HK'",
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#2C9BE5',
            color: 'white'
        }
    },
}));

export default function RequestAppointmentDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        specialityList = ["Family"],
        specialityVal = "",
        locationVal = "",
        requestedTimeVal = new Date(),
        onPrimarySubmit = () => console.log("onSubmit event!"),
    } = props;

    const [date, changeDate] = useState(requestedTimeVal);

    const [specialityValue, setSpecialityValue] = useState(specialityVal);
    const [specialityInput, setSpecialityInput] = useState(specialityVal);

    const [locationValue, setLocationValue] = useState(locationVal);

    const appointmentTemplate = {
        speciality: specialityVal,
        location: locationValue,
        requested_time: requestedTimeVal,
        type: "",
        name: "",
        phone: "",
        email: "",
    };

    const onClickLocIcon = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => setLocationValue(`Near You`));
        }
    }

    return (
        <Formik
            initialValues={appointmentTemplate}
            onSubmit={async (values) => {
                let form_data;
                if ((locationValue == "Near You") && navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((pos) => {
                        form_data = {
                            ...values,
                            location: `(${_get(pos, "coords.latitude")}, ${_get(pos, "coords.longitude")})`,
                            requested_time: _isDate(date) ? date.toISOString() : (new Date(date)).toISOString(),
                        }
                        console.log(form_data)
                        if (_isFunction(onPrimarySubmit)) {
                            onPrimarySubmit(form_data)
                        }
                    })
                } else {
                    form_data = {
                        ...values,
                        location: locationValue,
                        requested_time: _isDate(date) ? date.toISOString() : (new Date(date)).toISOString(),
                    }
                    console.log(form_data)
                    if (_isFunction(onPrimarySubmit)) {
                        onPrimarySubmit(form_data)
                    }
                }
            }}
            autoComplete="off"
        >
            {({
                values,
                handleChange,
                handleSubmit,
            }) => (
                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(e) }}>
                    <Grid container spacing={2} direction="column" className={classes.root}>
                        <Grid item xs={12} sm={4} md={4} xl={4} lg={4} className={classes.inputGroup} style={{marginBottom: '3em'}}>
                            <Select 
                                fullWidth
                                required
                                name="type"
                                variant="outlined"
                                value={values.type || ""}
                                onChange={handleChange}
                                className={clsx(classes.inputBorder, classes.br0)}
                                displayEmpty
                                style={{color: !values.type?"#aaa":""}}
                            >
                                <MenuItem disabled value="">Select Appointment Type</MenuItem>
                                <MenuItem value="TELEMEDICINE">Telemedicine</MenuItem>
                                <MenuItem value="IN-PERSON">In-person</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} container spacing={0} direction="row" className={classes.inputGroup}>
                            <Grid item className={classes.speacialityChild}>
                                <Autocomplete
                                    id="speciality"
                                    options={specialityList}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} variant="outlined" required fullWidth placeholder="Speciality or Service" inputProps={{ ...params.inputProps, autoComplete: 'off' }} />}
                                    inputValue={specialityInput}
                                    onInputChange={(event, newInputValue) => {
                                        setSpecialityInput(newInputValue);
                                    }}
                                    value={specialityValue}
                                    className={clsx(classes.inputBorder, classes.br0)}
                                    // style={{ width: '38ch' }}
                                    style={{ width: '100%' }}
                                    onChange={(event, newValue) => {
                                        setSpecialityValue(newValue);
                                        const newEvent = {
                                            target: {
                                                name: 'speciality',
                                                value: newValue,
                                            }
                                        }
                                        handleChange(newEvent);
                                    }}
                                />
                            </Grid>
                            {
                                !matches &&
                                <Grid item>
                                    <Divider orientation="vertical" />
                                </Grid>
                            }
                            <Grid item className={classes.inputChild}>
                                <TextField
                                    id="location"
                                    value={locationValue}
                                    required
                                    placeholder="Near eg.  58917"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={onClickLocIcon}>
                                                    <NearMeOutlinedIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className={clsx(classes.inputBorder, classes.rb0)}
                                    onChange={(event) => {
                                        setLocationValue(event.target.value)
                                        const newEvent = {
                                            target: {
                                                name: 'location',
                                                value: event.target.value,
                                            }
                                        }
                                        handleChange(newEvent);
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            {
                                !matches &&
                                <Grid item>
                                    <Divider orientation="vertical" />
                                </Grid>
                            }
                            <Grid item className={classes.inputChild}>
                                <MuiPickersUtilsProvider utils={MomentUtils} >
                                    <KeyboardDatePicker
                                        id="requested_time"
                                        autoOk
                                        orientation="landscape"
                                        disablePast={true}
                                        openTo="date"
                                        value={date}
                                        onChange={(event) => {
                                            changeDate(event)
                                            const newEvent = {
                                                target: {
                                                    name: 'requested_time',
                                                    value: _isDate(date) ? date.toISOString() : (new Date(date)).toISOString(),
                                                }
                                            }
                                            handleChange(newEvent);
                                        }}
                                        variant="inline"
                                        inputVariant="outlined"
                                        InputAdornmentProps={{ position: "end" }}
                                        className={clsx(classes.inputBorder, classes.bl0)}
                                        style={{ width: '100%', paddingRight: '0px' }}
                                        format="ddd, MMM DD"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.summaryHeading}>
                                How can we contact you?
                            </Typography>
                        </Grid>
                        <Grid item container spacing={0} direction="row" className={classes.inputGroup}>
                            <Grid item className={classes.inputChild}>
                                <TextField
                                    id="name"
                                    placeholder="Name*"
                                    variant="outlined"
                                    required
                                    className={clsx(classes.inputBorder, classes.br0)}
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            {
                                !matches &&
                                <Grid item>
                                    <Divider orientation="vertical" />
                                </Grid>
                            }
                            <Grid item className={classes.inputChild}>
                                <TextField
                                    id="phone"
                                    placeholder="Phone*"
                                    variant="outlined"
                                    required
                                    className={clsx(classes.inputBorder, classes.rb0)}
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            {
                                !matches &&
                                <Grid item>
                                    <Divider orientation="vertical" />
                                </Grid>
                            }
                            <Grid item className={classes.inputChild}>
                                <TextField
                                    id="email"
                                    placeholder="Email"
                                    variant="outlined"
                                    className={clsx(classes.inputBorder, classes.bl0)}
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                className={matches ? clsx(classes.confirmBtnSm, classes.confirmBtn) : classes.confirmBtn}
                                startIcon={<CalendarTodayOutlinedIcon />}
                                type="submit"
                            >
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}