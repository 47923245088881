import React from 'react';
import { Checkbox, Link, Dialog, Button, Card, Typography } from '@material-ui/core';
import TermsAndConditions from "../blocks/TermsAndConditions";
import ConsentForm from "../blocks/ConsentForm";
import PrivacyPolicy from "../blocks/PrivacyPolicy";

class PatientConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTOS: false,
      showConsentForm: false,
      showPrivacyPolicy: false,
      openDialog: false,
    }
  }
  onClickTOS() {
    this.setState({
      openDialog: true,
      showTOS: true,
    });
  }
  onClickPrivacyPolicy() {
    this.setState({
      openDialog: true,
      showPrivacyPolicy: true,
    });
  }
  onClickInformedConsent() {
    this.setState({
      openDialog: true,
      showConsentForm: true,
    });
  }
  onCloseDialog() {
    this.setState({
      openDialog: false,
      showTOS: false,
      showConsentForm: false,
      showPrivacyPolicy: false,
    })
  }
  render() {
    const { patientConsentGiven, onChangeConsentCheck, showConsentError, smallFont=true, } = this.props;
    const { showTOS, showPrivacyPolicy, showConsentForm, openDialog, } = this.state;
    const TextLink = (props) => {
      const { text, onLinkClick, fontSize = "0.95em" } = props;
      return (
        <Link style={{ verticalAlign: 'baseline', fontSize, }} component='button' variant='caption' onClick={onLinkClick}>
          {text}
        </Link>
      );
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex', margin: '10px auto', maxWidth: smallFont?'360px':'fit-content', marginLeft: '0px' }}>
          <Checkbox
            style={{ width: "20px", height: "20px", margin: "0 5px 0 0" }}
            id='consent'
            checked={patientConsentGiven}
            value={patientConsentGiven}
            onChange={onChangeConsentCheck}
            color="primary"
          />
          <div style={{ fontSize: smallFont?'0.85em':'1em', verticalAlign: 'bottom' }}>
            <span >I agree to </span>
            <TextLink text='terms' onLinkClick={this.onClickTOS.bind(this)} />
            <span >, </span>
            <TextLink text='privacy policy' onLinkClick={this.onClickPrivacyPolicy.bind(this)} />
            <span >, </span>
            <span> and </span>
            <TextLink text='informed consent.' onLinkClick={this.onClickInformedConsent.bind(this)} />
          </div>
        </div>
        <div style={{ maxWidth: '360px', margin: '5px auto' }}>
          {showConsentError &&
            <div style={{ textAlign: 'center' }}>
              <Typography variant='caption' color="secondary">
                Please agree to the above terms before proceeding.
              </Typography>
            </div>
          }
        </div>
        <Dialog open={openDialog} onClose={this.onCloseDialog.bind(this)}>
          <div style={{ margin: '10px', minWidth: '100px', minHeight: '100px' }}>
            {showTOS &&
              <TermsAndConditions showAccept={false} />
            }
            {showConsentForm &&
              <ConsentForm showAccept={false} />
            }
            {showPrivacyPolicy &&
              <PrivacyPolicy showAccept={false} />
            }
            <Button size='small' color='primary' variant='outlined'
              style={{ float: 'right', marginBottom: '10px' }}
              onClick={this.onCloseDialog.bind(this)}
            >
              Close
            </Button>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default PatientConsent;