
let url = "https://auth.amktechnologies.net/auth";
let realm = "amk_master";
let keycloak_config = {
    "realm": realm,
    "url": url,
    "verification_url": `${url}/realms/${realm}/protocol/openid-connect/userinfo`, // used to verify token on remote server
    'token_url': `${url}/realms/${realm}/protocol/openid-connect/token`,
    "ssl-required": "none",
    "clientId": "amk_direct",
    "directClientId": "amk_direct",
    "public-client": true,
    "enable-cors": false,
    "no-cors": true
  }

module.exports = keycloak_config;
