const { v4: uuidv4 } = require('uuid');
const _get = require("lodash/get");
const _isEmpty = require("lodash/isEmpty");

const CLIENT_UUID_IDENTIFIER = "amk_client_uuid";

const setClientUUIDinLocalStorage = () => {
  const client_uuid = uuidv4();
  window.localStorage.setItem(CLIENT_UUID_IDENTIFIER, client_uuid);
  return client_uuid;
}

const getClientUUID = () => {
  let client_uuid = window.localStorage.getItem(CLIENT_UUID_IDENTIFIER);
  if(_isEmpty(client_uuid)){
    client_uuid = setClientUUIDinLocalStorage();
  }
 return client_uuid;
}

export default getClientUUID;