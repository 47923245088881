import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _isEmpty from "lodash/isEmpty";
import _isFunction from "lodash/isFunction";

import '@fontsource/fjalla-one';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#48A3FC',
        boxShadow: 'none',
        position: 'sticky',
        top: '0',
    },
    title: {
        fontFamily: "'Fjalla One', Roboto",
        maxWidth: 'fit-content',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar, // necessary for content to be below app bar
        justifyContent: 'flex-start',
    },
    backBtn: {
        // backgroundColor: '#49B8FF',
        // color: "white",
        textTransform: 'none',
        fontFamily: "'Noto Sans HK'",
        marginLeft: '1em',
        marginTop: '0.25em'
    },
}));

export default function Header(props) {
    const classes = useStyles();
    const { 
        menuItems=[], 
        onClickTitle,
        onClickBack,
        showBackBtn=true,
        backgroundColorOfHeader=""
    } = props;
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Container maxWidth={false} disableGutters>
            <CssBaseline />
            <AppBar
                position="static"
                className={classes.header}
                style={backgroundColorOfHeader?{
                    backgroundColor: backgroundColorOfHeader
                }:{}}
            >
                <Toolbar>
                    <div style={{flexGrow: 1, style: 'inline-block'}}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.title} style={{cursor: _isFunction(onClickTitle)?'pointer':'auto'}} onClick={onClickTitle}>
                        OneClinic
                    </Typography>
                    </div>
                    {/* Drawer Icon*/}
                    { !_isEmpty(menuItems) &&
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={clsx(open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>

            {/* Drawer Menu */}
            {!_isEmpty(menuItems) &&

                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {menuItems.map((menu, index) => (
                            <ListItem button key={menu.label} onClick={menu.action}>
                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                <ListItemText primary={menu.label} />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            }
            { showBackBtn &&
                <Button
                    startIcon={<ArrowBackIcon/>}
                    className={classes.backBtn}
                    size="small"
                    onClick={()=>{
                        if(_isFunction(onClickBack)){
                            onClickBack()
                        } else {
                            window.history && window.history.back();
                        }
                    }}
                    // variant="outlined"
                >
                    Back
                </Button>
            }


        </Container >
    );
}
