import fetch from "node-fetch"
// Lodash
import _get from "lodash/get";
import { API_SERVER_URL } from ".././../Constants"
// Project
const EXTERNAL_PATIENT_ENDPOINT = `${API_SERVER_URL}/external-patient`;

export const createChatEncounter = async (encounter) => { 
    const response = await fetch(
        `${EXTERNAL_PATIENT_ENDPOINT}/create-chat-encounter`,
        {
             method: "POST",
             headers: {
                 "Content-Type": "application/json",
             },
             body: JSON.stringify(encounter)
        }
    );

    const body = await response.json();
    console.log(body)

   if (_get(body, "status") != 200) {
       console.log(`Could not create chat encounter`);
       console.log(_get(body, "message"));
   }

   const result = _get(body, "data");

   return result;
}