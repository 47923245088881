import React from 'react';
import { Typography, Link } from "@material-ui/core";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isFunction from "lodash/isFunction";

import CodeVerification from './CodeVerification';
import VerificationAPI from "../../services/verification/VerificationAPI";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


class EmailVerification extends React.Component {
    constructor(props){
        super(props);
        this.isComponentMounted = false;
        this.state = {
            inputText: '',
            failed: false,
            disableVerifyButton: false,
            loading: true,
            resending: false,
            error: null,
        };
    }

    async componentDidMount(){
        this.isComponentMounted = true;
        const token = (sessionStorage.getItem('visitor_token') || "").split(" ")[1];
        const email = _get(this.props, 'email')
        const phone_number = _get(this.props, 'phone_number');
        const skipSend = _get(this.props, 'skipSend');
        const skipTokenCheck = _get(this.props, 'skipTokenCheck');
        if(!_isEmpty(token) && !skipTokenCheck){
            const isVerified = await VerificationAPI.verifyKeycloakToken(token);
            if(isVerified){
                console.log("Valid token exists in session storage so calling on success");
                _isFunction(this.props.onSuccess) && this.props.onSuccess();
            } else {
                const res = await VerificationAPI.resendVerificationCode(email, phone_number);
                const { success, message } = res;
                if(!success){
                    this.setState({
                        error: message,
                    })
                    _isFunction(this.props.onFailure) && this.props.onFailure(message);
                }
                
            }

        } else if(!skipSend){
            await VerificationAPI.resendVerificationCode(email, phone_number)
        } else {
            const res = await VerificationAPI.resendVerificationCode(email, phone_number);
            const { success, message } = res;
            if(!success){
                this.setState({
                    error: message,
                })
                _isFunction(this.props.onFailure) && this.props.onFailure(message);
            }
        }
        if(this.isComponentMounted){
            this.setState({
                loading: false,
            })
        }
    }
    componentWillUnmount(){
        this.isComponentMounted = false;
    }
    componentDidUpdate(prevProps, prevState){
        if(!this.state.loading && (this.state.loading != prevState.loading)){
            _isFunction(this.props.onLoad) && this.props.onLoad();
        }
    }
    handleChange(e){
        this.setState({
          inputText: e.target.value,
        })
    }
    async resendCode(email, phone_number){
        this.setState({
            resending: true,
        })
        await VerificationAPI.resendVerificationCode(email, phone_number);
        this.setState({
            resending: false,
        })
    }
    async onClickVerify(){
        this.setState({
            disableVerifyButton: true,
        });
        const email = _get(this.props, 'email');
        const token = await VerificationAPI.verifyEmail(email, this.state.inputText);
        if(!_isEmpty(token)){
            console.log("Email verification successfull");
            window.sessionStorage.setItem("visitor_token", token);
            _isFunction(this.props.onSuccess) && await this.props.onSuccess();
            this.setState({
                failed: false,
                disableVerifyButton: false,
            })
        } else {
            _isFunction(this.props.onFailure) && this.props.onFailure();
            console.error("Email verification failed");
            this.setState({
                failed: true,
                disableVerifyButton: false,
            })
        }
        

    }
    render(){
        const {
            email="",
            phone_number="",
            onSuccess = ()=>console.log("onSuccess not defined"),
            onFailure = ()=>console.log("onFailure not defined"),
            spaceOnTop=true,
            showLoading=true,
        } = this.props;

        const { inputText, failed, disableVerifyButton, error, loading } = this.state;
        if (this.state.loading && showLoading) return (
            <div>
                <Backdrop style={{ color: "#fff", zIndex: 10000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
        if(this.state.resending) return <div>Resending...</div>
        if(error) return <div>{error}</div>
        return (
            <React.Fragment>
                <div style={{ marginTop: spaceOnTop?'120px':'0px', padding: spaceOnTop?'0':'1.5em 1.5em 0 1.5em'}}>
                    <Typography style={{ textAlign: 'center' }}>
                        A verification code has been sent to you at {email}.
                    </Typography>
                    <div style={{ margin: '1em 0 0 0' }}>
                        <CodeVerification
                            inputText={inputText}
                            failed={failed}
                            handleChange={this.handleChange.bind(this)}
                            disableVerifyButton={disableVerifyButton}
                            handleSubmit={this.onClickVerify.bind(this)}
                        />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1em'}}>
                        <Link component="button" onClick={()=>{this.resendCode(email, phone_number)}}>
                            Resend Code
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default EmailVerification;